import { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../formatted/UseWindowsDimensions";

export const MaterialCountingPieChart = ({
  dataChart,
  labelChart,
  materialTotal,
  colorChart,
  statistic,
  showMaterialTotal,
}) => {
  const { t, i18n } = useTranslation();
  const materialLengt = materialTotal.toString().length;
  const { widthWindow } = useWindowDimensions();
  const chartRef = useRef(null);

  useEffect(
    () => {
      if (!chartRef.current) return;
      const materialChart = echarts.init(chartRef.current);

      let fontSize;
      let space;
      if (statistic) {
        space = 15;
        if (materialLengt > 4) {
          fontSize = "1.5rem";
        } else {
          fontSize = "2rem";
        }
      } else if (materialLengt < 4) {
        fontSize = "2.5rem";
        space = 35;
      } else if (materialLengt === 4) {
        fontSize = "1rem";
        space = 30;
      } else if (materialLengt > 4) {
        fontSize = "1.8rem";
        space = 25;
      }

      const data = dataChart.map((value, index) => ({
        value: ((value * 100) / materialTotal).toFixed(1),
        name: labelChart[index],
        itemStyle: {
          color: colorChart[index],
        },
      }));

      const option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${params.name} (${params.value})%`;
          },
        },
        graphic: showMaterialTotal
          ? [
              {
                type: "group",
                left: "center",
                top: "center",
                children: [
                  {
                    type: "text",
                    left: "center",
                    top: "center",
                    style: {
                      text: materialTotal,
                      fontSize: fontSize,
                      fill: "#E95280",
                      fontWeight: "bold",
                      textAlign: "center",
                    },
                  },
                  {
                    type: "text",
                    left: "center",
                    top: space,
                    style: {
                      text:
                        materialTotal > 1
                          ? t("Detected materials")
                          : t("Detected material"),
                      fontSize: i18n.language === "en" ? "0.5rem" : "0.6rem",
                      fill: "#E95280",
                      fontWeight: "bold",
                      textAlign: "center",
                    },
                  },
                ],
              },
            ]
          : [],
        series: [
          {
            type: "pie",
            radius: [statistic ? "27%" : "20%", statistic ? "70%" : "55%"],
            label: {
              formatter: "{b} ({c}%)",
              position: "outside",
              fontSize: "0.8rem",
              fontWeight: 400,
            },
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      materialChart.setOption(option);
      window.addEventListener("resize", () => materialChart.resize());

      return () => {
        materialChart.dispose();
        window.removeEventListener("resize", () => materialChart.resize());
      };
    },
    // eslint-disable-next-line
    [
      widthWindow,
      colorChart,
      dataChart,
      i18n.language,
      labelChart,
      materialLengt,
      materialTotal,
      t,
    ],
  );

  return (
    <div
      ref={chartRef}
      style={{
        height: "100%",
        width: statistic ? widthWindow / 2.3 : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    ></div>
  );
};
