import { useEffect, useState } from "react";
import Loading from "../../../components/utils/Loading";
import { formattedWasteColor } from "../../../components/formatted/FormattedText";
import MultipleLineAndBarChart from "../../../components/charts/MultipleLineAndBarChart";
import { useTranslation } from "react-i18next";

const StatisticsChartComponent = ({
  statisticsCollectionCurrent,
  statisticsCollectionPrevious,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState({});

  //Filling chart display
  const [displayFillingByFlux, setDisplayFillingByFlux] = useState(true);
  const [displayFillingByTime, setDisplayFillingByTime] = useState(false);
  const [trendByWasteCategoryCurrent, setTrendByWasteCategoryCurrent] =
    useState([]);
  const [trendByWasteCategoryPrevious, setTrendByWasteCategoryPrevious] =
    useState([]);
  const [helpTrend, setHelpTrend] = useState(false);
  const [helpRankingIndex, setHelpRankingIndex] = useState(null);

  //Chart variable construction
  let dataSetsCollectionByFluxChart = [];
  let dataSetsFillingLevelByFluxChart = [];
  let dataSetsFillingLevelByTime = [];
  let labelsByTimeChart = [];
  let labelsByFluxChart = [];

  useEffect(() => {
    if (Object.keys(statisticsCollectionCurrent).length > 0) {
      setData(statisticsCollectionCurrent.dataResult);
      const dataByWasteCategory = Object.keys(
        statisticsCollectionCurrent.dataResult,
      )
        .map((item) => {
          const { sumFillingLevel, countFillingLevel } =
            statisticsCollectionCurrent.dataResult[item];
          return {
            wasteCategory: item,
            rotations: countFillingLevel,
            averageFillRate:
              countFillingLevel !== 0
                ? (sumFillingLevel / countFillingLevel).toFixed(2)
                : 0,
          };
        })
        .sort((a, b) => b.rotations - a.rotations);
      setTrendByWasteCategoryCurrent(dataByWasteCategory);
    }
  }, [statisticsCollectionCurrent]);

  useEffect(() => {
    if (Object.keys(statisticsCollectionPrevious).length > 0) {
      const dataByWasteCategory = Object.keys(
        statisticsCollectionPrevious.dataResult,
      )
        .map((item) => {
          const { sumFillingLevel, countFillingLevel } =
            statisticsCollectionPrevious.dataResult[item];
          return {
            wasteCategory: item,
            rotations: countFillingLevel,
            averageFillRate:
              countFillingLevel !== 0
                ? (sumFillingLevel / countFillingLevel).toFixed(2)
                : 0,
          };
        })
        .sort((a, b) => b.rotations - a.rotations);
      setTrendByWasteCategoryPrevious(dataByWasteCategory);
    }
  }, [statisticsCollectionPrevious]);

  //Collection By Flux and Filling Level By Time
  Object.keys(data).forEach((flux) => {
    dataSetsCollectionByFluxChart.push({
      label: flux,
      data: data[flux].monthlyDistribution.map((item) => ({
        x: item.time,
        y: item.countCollection,
        z: flux,
      })),
      backgroundColor: formattedWasteColor(flux),
      type: "bar",
    });

    dataSetsFillingLevelByTime.push({
      label: flux,
      data: data[flux].monthlyDistribution.map((item) => ({
        x: item.time,
        y: item.sumFillingLevel / item.countFillingLevel,
        z: flux,
      })),
      backgroundColor: formattedWasteColor(flux),
      type: "line",
      borderColor: formattedWasteColor(flux),
      tension: 0.1,
    });

    for (let i = 0; i < data[flux].monthlyDistribution.length; i++) {
      if (!labelsByTimeChart.includes(data[flux].monthlyDistribution[i].time)) {
        labelsByTimeChart.push(data[flux].monthlyDistribution[i].time);
      }
    }
    return null;
  });

  //Min
  dataSetsFillingLevelByFluxChart.push({
    label: "Min",
    data: Object.keys(data).map((flux) => ({
      x: flux,
      y: data[flux].min,
      z: "Min",
    })),
    backgroundColor: "blue",
    type: "line",
    borderColor: "blue",
    tension: 0.1,
  });
  //Average
  dataSetsFillingLevelByFluxChart.push({
    label: t("Average"),
    data: Object.keys(data).map((flux) => ({
      x: flux,
      y: data[flux].sumFillingLevel / data[flux].countFillingLevel,
      z: t("Average"),
    })),
    backgroundColor: "green",
    type: "line",
    borderColor: "green",
    tension: 0.1,
  });
  //Max
  dataSetsFillingLevelByFluxChart.push({
    label: "Max",
    data: Object.keys(data).map((flux) => ({
      x: flux,
      y: data[flux].max,
      z: "Max",
    })),
    backgroundColor: "red",
    type: "line",
    borderColor: "red",
    tension: 0.1,
  });
  //Label
  labelsByFluxChart = Object.keys(data);

  const findRanking = (wasteCategory, rotations) => {
    const result = trendByWasteCategoryPrevious.filter(
      (item) => item.wasteCategory === wasteCategory,
    )[0];
    if (result === undefined) {
      return "up";
    } else if (rotations > result.rotations) {
      return "up";
    } else if (rotations < result.rotations) {
      return "down";
    } else {
      return "stable";
    }
  };

  return (
    <div>
      {!isLoading ? (
        Object.keys(data).length > 0 ? (
          <div className="flex flex-col">
            <section className="flex flex-col min-lg:flex-row ">
              <article className="min-lg:w-1/2  bg-white p-3 rounded-lg min-lg:mr-2 my-2">
                <h5 className="font-semibold">
                  {t("Rotations by waste category")}
                </h5>
                <div
                  className="modal-item-chart grid justify-items-center"
                  style={{ marginTop: "40px" }}
                >
                  <MultipleLineAndBarChart
                    stackedChart={true}
                    type="bar"
                    borderWidthChart={1}
                    labelsChart={labelsByTimeChart}
                    aspectRatioChart={1}
                    datasetsChart={dataSetsCollectionByFluxChart}
                    indexAxisChart="x"
                    maintainAspectRatioChart={false}
                    displayGrid={true}
                    displayTicks={true}
                    xAxisType={"time"}
                    reverseChart={false}
                    displayLegend={true}
                    displayTooltip={true}
                    messageLabelTooltip={t("Collections")}
                    unitValueChart=""
                    maxYAxis={null}
                  />
                </div>
              </article>

              <article className="min-lg:w-1/2 bg-white p-3 rounded-lg min-lg:ml-2 my-2">
                <div className="flex flex-row justify-between">
                  <h5 className="font-semibold">{t("Filling rate")}</h5>
                  <div className="">
                    <span
                      onClick={() => {
                        setDisplayFillingByFlux(true);
                        setDisplayFillingByTime(false);
                      }}
                      className={`${displayFillingByFlux ? "px-4 py-1 cursor-pointer border-b-2 border-cyan text-cyan mx-2" : "px-4 py-1 cursor-pointer text-cyan bg-gainsboro rounded mx-2"}`}
                    >
                      {t("Waste category")}
                    </span>
                    <span
                      onClick={() => {
                        setDisplayFillingByFlux(false);
                        setDisplayFillingByTime(true);
                      }}
                      className={`${displayFillingByTime ? "px-4 py-1 cursor-pointer border-b-2 border-cyan text-cyan mx-2" : "px-4 py-1 cursor-pointer text-cyan bg-gainsboro rounded mx-2"}`}
                    >
                      {t("Time")}
                    </span>
                  </div>
                </div>

                {displayFillingByFlux ? (
                  <div
                    className="modal-item-chart grid justify-items-center"
                    style={{ marginTop: "30px" }}
                  >
                    <MultipleLineAndBarChart
                      stackedChart={false}
                      type="line"
                      borderWidthChart={3}
                      labelsChart={labelsByFluxChart}
                      aspectRatioChart={1}
                      datasetsChart={dataSetsFillingLevelByFluxChart}
                      indexAxisChart="x"
                      maintainAspectRatioChart={false}
                      displayGrid={true}
                      displayTicks={true}
                      xAxisType={"category"}
                      reverseChart={false}
                      displayLegend={true}
                      displayTooltip={true}
                      messageLabelTooltip={t("of filling level")}
                      unitValueChart="%"
                      maxYAxis={100}
                    />
                  </div>
                ) : null}

                {displayFillingByTime ? (
                  <div
                    className="modal-item-chart grid justify-items-center"
                    style={{ marginTop: "30px" }}
                  >
                    <MultipleLineAndBarChart
                      stackedChart={false}
                      type="line"
                      borderWidthChart={3}
                      labelsChart={labelsByTimeChart}
                      aspectRatioChart={1}
                      datasetsChart={dataSetsFillingLevelByTime}
                      indexAxisChart="x"
                      maintainAspectRatioChart={false}
                      displayGrid={true}
                      displayTicks={true}
                      xAxisType={"time"}
                      reverseChart={false}
                      displayLegend={true}
                      displayTooltip={true}
                      messageLabelTooltip={t("of filling level")}
                      unitValueChart="%"
                      maxYAxis={100}
                    />
                  </div>
                ) : null}
              </article>
            </section>

            <section className="flex flex-col min-lg:flex-row">
              <article className="min-lg:w-1/2  bg-white p-3 rounded-lg min-lg:mr-2 my-2">
                <div className="flex flex-col">
                  <div className="w-full flex justify-between">
                    <h5 className="font-semibold">{t("Trend by flux")}</h5>
                    <i
                      className="fas fa-info-circle text-gainsboro"
                      onMouseOver={() => setHelpTrend(true)}
                      onMouseOut={() => setHelpTrend(false)}
                    ></i>
                  </div>
                  <div className="flex flex-col px-3 mt-2">
                    <div className="flex justify-between border-b-2 border-[#D0D0D0] mb-2">
                      <span className="w-1/3">Flux</span>
                      <span className="w-1/3 text-center">Rotations</span>
                      <span className="w-1/3 text-right truncate">
                        {t("Average filling rate")}
                      </span>
                    </div>
                    {trendByWasteCategoryCurrent.map((item, index) => (
                      <div
                        key={index}
                        className="flex justify-between my-1.5 text-gray-600 relative"
                      >
                        <span className="w-1/3 font-semibold">
                          {item.wasteCategory}
                        </span>

                        <div className="w-1/3 flex justify-center">
                          <div
                            className="flex flex-row gap-3 p-1 items-center cursor-pointer"
                            onMouseEnter={() => setHelpRankingIndex(index)}
                            onMouseLeave={() => setHelpRankingIndex(null)}
                          >
                            <i
                              className={`fas ${
                                findRanking(
                                  item.wasteCategory,
                                  item.rotations,
                                ) === "up"
                                  ? "fa-arrow-up text-[#3CD856]"
                                  : findRanking(
                                        item.wasteCategory,
                                        item.rotations,
                                      ) === "down"
                                    ? "fa-arrow-down text-red"
                                    : "fa-arrow-right text-[#82cbe8]"
                              } text-sm`}
                            ></i>
                            <span className="font-semibold">
                              {item.rotations}
                            </span>
                          </div>
                        </div>

                        <span className="w-1/3 text-right">
                          {item.averageFillRate}%
                        </span>

                        {helpRankingIndex === index && (
                          <div className="w-96 bg-[#f8aa00] p-1 absolute rounded-md left-[50%] mt-4 z-10">
                            <span className="text-white text-sm">
                              {t("rotation_comparison", {
                                rotations:
                                  trendByWasteCategoryPrevious.find(
                                    (waste) =>
                                      waste.wasteCategory ===
                                      item.wasteCategory,
                                  )?.rotations || 0,
                              })}
                            </span>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {helpTrend ? (
                    <div className="w-80 bg-[#f8aa00] p-3 h-32 absolute rounded-md ml-72">
                      <span className="text-white text-sm">
                        {t(
                          "Sort the flux by the number of rotations, then compare value from the previous period to see if there is a decrease or an increase",
                        )}
                        .
                      </span>
                    </div>
                  ) : null}
                </div>
              </article>
              <article className="min-lg:w-1/2 bg-white p-3 rounded-lg min-lg:ml-2 my-2"></article>
            </section>
          </div>
        ) : (
          <section className="flex flex-col bg-white rounded-lg my-2 py-48 text-center text-cyan">
            <span class="fas fa-exclamation-triangle text-xl"></span>
            <span>{t("No collection for the current period")}</span>
          </section>
        )
      ) : (
        <div className="my-2">
          <Loading height={"55vh"} />
        </div>
      )}
    </div>
  );
};

export default StatisticsChartComponent;
