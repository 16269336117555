import StatisticsKeyValuesComponent from "./StatisticsKeyValuesComponent";

const StatisticsCO2 = ({
  periodHistoryStart,
  periodHistoryStop,
  periodHistoryStartPrevious,
  periodHistoryStopPrevious,
  keywordFilter,
  numberOfSite,
  numberOfContainers,
  handleValidate,
  setHandleValidate,
  periodName,
}) => {
  return (
    <div className="text-blue w-full">
      <StatisticsKeyValuesComponent
        periodHistoryStart={periodHistoryStart}
        periodHistoryStop={periodHistoryStop}
        periodHistoryStartPrevious={periodHistoryStartPrevious}
        periodHistoryStopPrevious={periodHistoryStopPrevious}
        keywordFilter={keywordFilter}
        numberOfContainers={numberOfContainers}
        numberOfSite={numberOfSite}
        handleValidate={handleValidate}
        setHandleValidate={setHandleValidate}
        periodName={periodName}
      />
    </div>
  );
};

export default StatisticsCO2;
