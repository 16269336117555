import { useState, useRef } from "react";
import {
  formattedContainerId,
  formattedTypeContainer,
} from "../../components/formatted/FormattedText";
import {
  deltaMilliseconds,
  englishMonth,
  formattedDate,
  frenchMonth,
  getEndOfMonth,
  getMonthsAgo,
  getPreviousPeriod,
  getStartOfMonth,
  spanishMonth,
} from "../../components/formatted/FormattedDateAndTime";
import StatisticsCollection from "./collection/StatisticsCollection";
import StatisticsQuality from "./quality/StatisticsQuality";
import { useEffect } from "react";
import {
  countContainers,
  getKeywordFilterOptions,
} from "../../services/container";
import { useTranslation } from "react-i18next";
import { addressCountSiteUsed } from "../../services/address";
import axios from "axios";
import StatisticsCO2 from "./Co2/StatisticsCo2";
import { useLocation } from "react-router-dom";
import StatisticsFilter from "./StatisticsFilter";
import { startWithLowercase } from "../../components/utils/Date";
import { FaLock } from "react-icons/fa";
import Cookies from "js-cookie";

const StatisticsNew = ({ API, token }) => {
  const location = useLocation();
  const viewMode = Cookies.get("viewMode") || "client";

  const {
    activateCollectionField = true,
    activateQualityField = false,
    activateCostField = false,
    activateCarbonField = false,
  } = location.state || {};

  const { t, i18n } = useTranslation();
  const [keywordFilterOptions, setKeywordFilterOptions] = useState([]);
  const [keywordFilter, setKeywordFilter] = useState(
    location?.state?.keywordFilter || [],
  );
  const [showCollection, setShowCollection] = useState(activateCollectionField);
  const [showQuality, setShowQuality] = useState(activateQualityField);
  const [showCost, setShowCost] = useState(activateCostField);
  const [showCarbon, setShowCarbon] = useState(activateCarbonField);
  const multiselectKeywordRef = useRef(null);
  const filterRef = useRef(null);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [numberOfContainers, setNumberOfContainers] = useState(0);
  const [numberOfSite, setNumberOfSite] = useState(0);

  const now = new Date();
  const [periodHistoryStop, setPeriodHistoryStop] = useState(now.toISOString());
  const [periodHistoryStart, setPeriodHistoryStart] = useState(
    getMonthsAgo(now, 6).toISOString(),
  );

  const [periodHistoryStopPrevious, setPeriodHistoryStopPrevious] = useState(
    getMonthsAgo(now, 7, true).toISOString(),
  );
  const [periodHistoryStartPrevious, setPeriodHistoryStartPrevious] = useState(
    getMonthsAgo(now, 12).toISOString(),
  );
  const [activatedSortingErrorOption, setActivatedSortingErrorOption] =
    useState(false);

  let periodString = `6 ${startWithLowercase(t("Months"))}`;

  const [formatedPeriod, setFormatedPeriod] = useState(periodString);
  const [periodName, setPeriodName] = useState(periodString);
  const [handleValidate, setHandleValidate] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("-6mo");

  const [isAkanthasAccount, setIsAkanthasAccount] = useState(true);

  useEffect(() => {
    const email = Cookies.get("userEmail") || "";
    setIsAkanthasAccount(email.includes("@akanthas.com"));
  }, [isAkanthasAccount]);

  useEffect(
    () => {
      getKeywordFilterOptions({
        keywordFilter: JSON.stringify(keywordFilter.value),
      }).then((res) => {
        if (res.status === 200) {
          setKeywordFilterOptions(
            res.data.keywordFilterOptions.filter(
              (keyword) => keyword.key !== null,
            ),
          );
        }
      });
    },
    // eslint-disable-next-line
    [],
  );

  const labeledkeywordFilterOptions = keywordFilterOptions.map((keyword) => {
    if (keyword.type === "type") {
      keyword = { ...keyword, key: formattedTypeContainer(keyword.key) };
    } else if (keyword.type === "id") {
      keyword = {
        ...keyword,
        key: formattedContainerId(keyword.key) + keyword.key,
      };
    }
    return keyword;
  });

  const selectPeriodFilter = (value) => {
    setSelectedPeriod(value);
    let periodString = null;
    const now = new Date();

    switch (value) {
      case "0": {
        const startOfMonth = getStartOfMonth(now);
        const previousPeriod = getPreviousPeriod(now, 1);

        setPeriodHistoryStart(startOfMonth.toISOString());
        setPeriodHistoryStop(now.toISOString());
        setPeriodHistoryStartPrevious(previousPeriod.start.toISOString());
        setPeriodHistoryStopPrevious(previousPeriod.end.toISOString());

        if (i18n.language === "fr") {
          periodString = `Depuis le 1er ${frenchMonth[now.getMonth()]}`;
        } else if (i18n.language === "en") {
          periodString = `Since 1st ${englishMonth[now.getMonth()]}`;
        } else {
          periodString = `Desde el 1º ${spanishMonth[now.getMonth()]}`;
        }
        break;
      }
      case "1": {
        const startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        const endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        const previousPeriod = getPreviousPeriod(now, 2);

        if (i18n.language === "fr") {
          periodString = `Du 1er ${frenchMonth[startOfLastMonth.getMonth()]} au ${endOfLastMonth.getDate()} ${frenchMonth[endOfLastMonth.getMonth()]}`;
        } else if (i18n.language === "en") {
          periodString = `1st ${englishMonth[startOfLastMonth.getMonth()]} to ${endOfLastMonth.getDate()} ${englishMonth[endOfLastMonth.getMonth()]}`;
        } else {
          periodString = `1º ${spanishMonth[startOfLastMonth.getMonth()]} a ${endOfLastMonth.getDate()} ${spanishMonth[endOfLastMonth.getMonth()]}`;
        }

        setPeriodHistoryStart(startOfLastMonth.toISOString());
        setPeriodHistoryStop(endOfLastMonth.toISOString());
        setPeriodHistoryStartPrevious(previousPeriod.start.toISOString());
        setPeriodHistoryStopPrevious(previousPeriod.end.toISOString());
        break;
      }
      case "-3mo":
        setPeriodHistoryStart(getMonthsAgo(now, 3).toISOString());
        setPeriodHistoryStop(now.toISOString());
        setPeriodHistoryStartPrevious(getMonthsAgo(now, 6).toISOString());
        setPeriodHistoryStopPrevious(getMonthsAgo(now, 4, true).toISOString());
        periodString = `3 ${startWithLowercase(t("Months"))}`;
        break;

      case "-6mo":
        setPeriodHistoryStart(getMonthsAgo(now, 6).toISOString());
        setPeriodHistoryStop(now.toISOString());
        setPeriodHistoryStartPrevious(getMonthsAgo(now, 12).toISOString());
        setPeriodHistoryStopPrevious(getMonthsAgo(now, 7, true).toISOString());
        periodString = `6 ${startWithLowercase(t("Months"))}`;
        break;

      case "-12mo":
        setPeriodHistoryStart(getMonthsAgo(now, 12).toISOString());
        setPeriodHistoryStop(now.toISOString());
        setPeriodHistoryStartPrevious(getMonthsAgo(now, 24).toISOString());
        setPeriodHistoryStopPrevious(getMonthsAgo(now, 13, true).toISOString());
        periodString = `12 ${startWithLowercase(t("Months"))}`;
        break;

      default:
        break;
    }
    if (value !== "calendar") {
      setPeriodName(periodString);
    }
    setHandleValidate(true);
  };

  const onChangeCalendar = (event) => {
    let periodString = null;
    const delta = deltaMilliseconds(event[0], event[1]);
    const startMilliseconds = new Date(event[0])?.getTime();
    const startPrevious = startMilliseconds - delta;

    setPeriodHistoryStop(new Date(event[1]).toISOString());
    setPeriodHistoryStart(new Date(event[0]).toISOString());

    setPeriodHistoryStopPrevious(new Date(event[0]).toISOString());
    setPeriodHistoryStartPrevious(new Date(startPrevious).toISOString());
    periodString = `${i18n.language === "fr" ? "Du " : ""}${formattedDate(new Date(event[0]), i18n.language)} ${i18n.language === "fr" ? "au" : t("to")} ${formattedDate(new Date(event[1]), i18n.language)}`;
    setPeriodName(periodString);
    setFormatedPeriod(periodString);
    setDisplayCalendar(false);
    selectPeriodFilter("calendar");
  };

  useEffect(() => {
    setHandleValidate(true);
  }, [keywordFilter]);

  useEffect(() => {
    axios
      .all([
        countContainers({ keywordFilter: JSON.stringify(keywordFilter.value) }),
        addressCountSiteUsed({
          periodStart: periodHistoryStart,
          periodStop: periodHistoryStop,
          keywordFilter: JSON.stringify(keywordFilter.value),
        }),
      ])
      .then(
        axios.spread((responseContainerCount, responseCountSite) => {
          if (
            responseContainerCount.status === 200 &&
            responseCountSite.status === 200
          ) {
            setActivatedSortingErrorOption(
              responseContainerCount.data.activatedSortingErrorOption,
            );
            setNumberOfContainers(responseContainerCount.data.count);
            setNumberOfSite(responseCountSite.data.countSite);
          }
        }),
      );
  }, [keywordFilter, periodHistoryStart, periodHistoryStop]);

  if (token) {
    return (
      <div className="base">
        <h1 className="">
          {t("My")} {t("Summaries")} & {t("Metrics")}
        </h1>

        <nav className="container-modal-nav mb-6 mt-2">
          <div className="w-4/5">
            <span
              onClick={() => {
                setShowCollection(true);
                setShowQuality(false);
                setShowCost(false);
                setShowCarbon(false);
              }}
              className={`${showCollection ? "container-modal-select" : ""}`}
            >
              {t("Rotations")}
            </span>
            {activatedSortingErrorOption ||
            (isAkanthasAccount && viewMode === "admin") ? (
              <span
                onClick={() => {
                  setShowCollection(false);
                  setShowQuality(true);
                  setShowCost(false);
                  setShowCarbon(false);
                }}
                className={`${showQuality ? "container-modal-select" : ""}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                {activatedSortingErrorOption === false && (
                  <FaLock style={{ marginRight: "5px" }} />
                )}
                {t("sorting_errors")}
              </span>
            ) : null}
            <span
              onClick={() => {
                setShowCollection(false);
                setShowQuality(false);
                setShowCost(true);
                setShowCarbon(false);
              }}
              className={`${showCost ? "container-modal-select" : ""}`}
            >
              {t("Cost")}
            </span>
            <span
              onClick={() => {
                setShowCollection(false);
                setShowQuality(false);
                setShowCost(false);
                setShowCarbon(true);
              }}
              className={`${showCarbon ? "container-modal-select" : ""}`}
            >
              {t("Carbon Footprint")}
            </span>
          </div>
        </nav>

        <StatisticsFilter
          labeledkeywordFilterOptions={labeledkeywordFilterOptions}
          multiselectKeywordRef={multiselectKeywordRef}
          keywordFilter={keywordFilter}
          setKeywordFilter={setKeywordFilter}
          onChangeCalendar={onChangeCalendar}
          displayCalendar={displayCalendar}
          setDisplayCalendar={setDisplayCalendar}
          periodHistoryStart={periodHistoryStart}
          periodHistoryStop={periodHistoryStop}
          selectPeriodFilter={selectPeriodFilter}
          selectedPeriod={selectedPeriod}
          filterRef={filterRef}
        />

        <div className="flex mt-3">
          {showCollection ? (
            <StatisticsCollection
              API={API}
              token={token}
              periodHistoryStart={periodHistoryStart}
              periodHistoryStop={periodHistoryStop}
              periodHistoryStartPrevious={periodHistoryStartPrevious}
              periodHistoryStopPrevious={periodHistoryStopPrevious}
              keywordFilter={keywordFilter}
              formatedPeriod={formatedPeriod}
              numberOfContainers={numberOfContainers}
              numberOfSite={numberOfSite}
              handleValidate={handleValidate}
              setHandleValidate={setHandleValidate}
              periodName={periodName}
            />
          ) : null}

          {showQuality ? (
            <StatisticsQuality
              API={API}
              token={token}
              periodHistoryStart={periodHistoryStart}
              periodHistoryStop={periodHistoryStop}
              periodHistoryStartPrevious={periodHistoryStartPrevious}
              periodHistoryStopPrevious={periodHistoryStopPrevious}
              keywordFilter={keywordFilter}
              numberOfContainers={numberOfContainers}
              numberOfSite={numberOfSite}
              handleValidate={handleValidate}
              setHandleValidate={setHandleValidate}
              periodName={periodName}
            />
          ) : null}
          {showCarbon ? (
            <StatisticsCO2
              periodHistoryStart={periodHistoryStart}
              periodHistoryStop={periodHistoryStop}
              periodHistoryStartPrevious={periodHistoryStartPrevious}
              periodHistoryStopPrevious={periodHistoryStopPrevious}
              keywordFilter={keywordFilter}
              numberOfContainers={numberOfContainers}
              numberOfSite={numberOfSite}
              handleValidate={handleValidate}
              setHandleValidate={setHandleValidate}
              periodName={periodName}
            />
          ) : null}
          {!showQuality && !showCollection && !showCarbon && (
            <div className="w-full text-center mt-28">
              <h1 className="font-bold">Coming Soon!</h1>
              <h5>
                {t("Don't hesitate to send us your suggestions/needs to")}{" "}
                <span className="text-[#0F265C]">tech@akanthas.com</span>
              </h5>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default StatisticsNew;
