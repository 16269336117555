import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import "../styles/Font";
import CoverFillingCyclePDF from "./CoverFillingCyclePDF";
import { useTranslation } from "react-i18next";
import { ImageComponent } from "../wasteQuality/ImageComponent";

export const FillingCyclePdfDocument = ({
  data,
  timeLine,
  dropOff,
  corporationName,
  collectionFillingLevel,
  numberOfAnomaly,
  chartImageUrl,
  cycleChartImgUrl,
  numberOfStars,
  contaminants,
  numberOfPackmat,
  clientRegisteredWeight,
}) => {
  const { t, i18n } = useTranslation();
  let clientLogo = null;
  try {
    clientLogo = require(`../../../static/assets/${timeLine.corporation}.png`);
  } catch (error) {
    console.warn("Logo not found, using default logo.");
  }
  const groupedData = [];
  for (let i = 0; i < data.length; i += 2) {
    groupedData.push(data.slice(i, i + 2));
  }

  return (
    <Document
      creator="Akanthas"
      producer="Akanthas"
      style={{ fontFamily: "Open Sans" }}
    >
      <CoverFillingCyclePDF
        data={data}
        timeLine={timeLine}
        dropOff={dropOff}
        language={i18n.language}
        clientLogo={clientLogo}
        corporationName={corporationName}
        collectionFillingLevel={collectionFillingLevel}
        numberOfAnomaly={numberOfAnomaly}
      />

      <Page size="A4">
        <View
          fixed
          style={{ height: 20, backgroundColor: "#0F265C", width: "100%" }}
        />
        <Image
          source={require("../../../static/assets/Akanthas-logo-RVB.png")}
          style={{ width: 111, height: 40, marginVertical: 10 }}
        />
        <View style={{ flexDirection: "column" }}>
          {timeLine.activatedSortingErrorOption && (
            <View
              style={{
                flexDirection: "row",
                padding: 20,
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "30%" }}>
                <View>
                  <Text
                    style={{
                      fontSize: 18,
                      color: "#00A7A6",
                      fontFamily: "Mulish",
                      fontWeight: 1000,
                      letterSpacing: "1px",
                    }}
                  >
                    {t("Distribution of anomalies")}
                  </Text>
                </View>
                <View style={{ marginTop: 35 }}>
                  <Text
                    style={{
                      fontSize: 18,
                      fontFamily: "Mulish",
                      color: "#e81d7a",
                      fontWeight: 1000,
                    }}
                  >
                    {numberOfAnomaly}
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Mulish",
                    }}
                  >
                    {numberOfAnomaly > 1
                      ? t("sorting_errors")
                      : t("sorting_error")}
                  </Text>
                </View>
                <View style={{ marginTop: 35 }}>
                  <Text
                    style={{
                      fontSize: 18,
                      fontFamily: "Mulish",
                      color: "#e81d7a",
                      fontWeight: 1000,
                    }}
                  >
                    {contaminants}%
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Mulish",
                    }}
                  >
                    {t("of contaminants")}
                  </Text>
                </View>
                <View style={{ marginTop: 35 }}>
                  <Image
                    source={require(
                      `../../../static/assets/${numberOfStars}-stars.png`,
                    )}
                    style={{ width: "70px", marginLeft: -5 }}
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Mulish",
                    }}
                  >
                    {t("Waste category quality")}
                  </Text>
                  <Text style={{ fontSize: 7, marginTop: 4 }}>
                    (
                    {numberOfStars === 0
                      ? t("More than 30% of contaminants")
                      : numberOfStars === 1
                        ? t("Between 15% and 30% of contaminants")
                        : numberOfStars === 2
                          ? t("Between 5% and 15% of contaminants")
                          : t("Less than 5% of contaminants")}
                    )
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "70%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {chartImageUrl && (
                  <Image
                    style={{ width: "350px", height: "auto" }}
                    src={chartImageUrl}
                  />
                )}
              </View>
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              padding: 20,
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "30%" }}>
              <View>
                {i18n.language === "en" ? (
                  <Text
                    style={{
                      fontSize: 18,
                      color: "#00A7A6",
                      fontFamily: "Mulish",
                      fontWeight: 1000,
                      letterSpacing: "1px",
                    }}
                  >
                    Filling cycle
                  </Text>
                ) : i18n.language === "fr" ? (
                  <>
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#00A7A6",
                        fontFamily: "Mulish",
                        fontWeight: 1000,
                        letterSpacing: "1px",
                      }}
                    >
                      Cycle
                    </Text>
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#00A7A6",
                        fontFamily: "Mulish",
                        fontWeight: 1000,
                        letterSpacing: "1px",
                      }}
                    >
                      de remplissage
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#00A7A6",
                        fontFamily: "Mulish",
                        fontWeight: 1000,
                        letterSpacing: "1px",
                      }}
                    >
                      Ciclo
                    </Text>
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#00A7A6",
                        fontFamily: "Mulish",
                        fontWeight: 1000,
                        letterSpacing: "1px",
                      }}
                    >
                      de llenado
                    </Text>
                  </>
                )}
              </View>
              <View style={{ marginTop: 35 }}>
                <Text
                  style={{
                    fontSize: 18,
                    fontFamily: "Mulish",
                    color: "#e81d7a",
                    fontWeight: 1000,
                  }}
                >
                  {collectionFillingLevel}%
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: "Mulish",
                  }}
                >
                  {t("Level at collection")}
                </Text>
              </View>
              {clientRegisteredWeight && (
                <View style={{ marginTop: 35 }}>
                  <Text
                    style={{
                      fontSize: 18,
                      fontFamily: "Mulish",
                      color: "#e81d7a",
                      fontWeight: 1000,
                    }}
                  >
                    {`${clientRegisteredWeight} ${t("ton")}${clientRegisteredWeight > 1 ? "s" : ""}`}
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Mulish",
                    }}
                  >
                    {t("Registered weight")}
                  </Text>
                </View>
              )}
              <View style={{ marginTop: 35 }}>
                <Text
                  style={{
                    fontSize: 18,
                    fontFamily: "Mulish",
                    color: "#e81d7a",
                    fontWeight: 1000,
                  }}
                >
                  {numberOfPackmat}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: "Mulish",
                  }}
                >
                  {t("Packmat")}
                  {numberOfPackmat > 1 ? "s" : ""}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "70%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cycleChartImgUrl && (
                <Image
                  style={{ width: "350px", height: "auto" }}
                  src={cycleChartImgUrl}
                />
              )}
            </View>
          </View>
        </View>
      </Page>

      {groupedData
        .flat()
        .reduce((acc, item, index) => {
          if (index % 6 === 0) acc.push([]);
          acc[acc.length - 1].push(item);
          return acc;
        }, [])
        .map((group, pageIndex) => (
          <Page key={pageIndex} size="A4">
            {/* Bande bleue en haut */}
            <View
              fixed
              style={{ height: 20, backgroundColor: "#0F265C", width: "100%" }}
            />
            <Image
              source={require("../../../static/assets/Akanthas-logo-RVB.png")}
              style={{ width: 111, height: 40, marginLeft: 10 }}
            />

            {/* Contenu avec padding */}
            <View style={{ padding: 20 }}>
              {Array.from({ length: 3 }).map((_, rowIndex) => (
                <View
                  key={rowIndex}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  {/* Deux images côte à côte */}
                  {group
                    .slice(rowIndex * 2, rowIndex * 2 + 2)
                    .map((item, subIndex) => (
                      <View
                        key={subIndex}
                        style={{
                          width: "48%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ImageComponent
                          data={item}
                          drawMaterial={false}
                          height={190}
                        />
                        <Text
                          style={{
                            fontSize: 10,
                            marginTop: 10,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {`${t("Level")} ${item.filling_level}%`}
                        </Text>
                      </View>
                    ))}
                </View>
              ))}
            </View>
          </Page>
        ))}
    </Document>
  );
};
