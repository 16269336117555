import { useEffect, useRef, useState } from "react";
import Loading from "../../components/utils/Loading";
import { formatDateToString } from "../../components/formatted/FormattedDateAndTime";
import ActivityCollectionModal from "./ActivityCollectionModal";
import moment from "moment-timezone";
import { LoadingScreen } from "../../components/loader/LoadingScreen";
import ActivityCollectionRow from "./ActivityCollectionRow";
import {
  getContainerClientRegisteredInfo,
  getHistoryCollection,
  getHistoryCycleContainer,
} from "../../services/container";
import { getWastecategoryList } from "../../services/waste";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../../components/dropdownMenu/Dropdown";
import useScrollToEnd from "../../hooks/useScrollToEnd";
import ContainerCompact from "../container/ContainerCompact";
import { MaterialCountingPieChart } from "../../components/charts/MaterialCountingPieChart";

const ActivityCollection = ({
  keywordFilter,
  periodHistoryStart,
  periodHistoryStop,
  superContainers,
  handleValidate,
  setOpenFilter,
}) => {
  const { t } = useTranslation();
  const materials = JSON.parse(localStorage.getItem("materialsDescription"));
  const [isLoading, setIsLoading] = useState(true);
  const [modalHistoryCycle, setModalHistoryCycle] = useState(false);
  const [dataRow, setDataRow] = useState(false);
  const [countDataCollection, setCountDataCollection] = useState(0);
  const [dataContainerCollection, setDataContainerCollection] = useState([]);
  const [disabledDownload, setDisabledDownload] = useState(false);

  const [loadScreen, setLoadScreen] = useState(false);
  const [wasteCategory, setWasteCategory] = useState([]);
  const [activeWasteCategory, setActiveWasteCategory] = useState("");

  const [chartData, setChartData] = useState({});
  const [displayChartData, setDisplayChartData] = useState(false);

  const [progress, setProgress] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isProgress, setIsProgress] = useState(false);
  const itemsPerPage = 5;

  const chartRef = useRef();
  const cycleChartRef = useRef();

  useScrollToEnd(() => {
    setCurrentPage((prevPage) => prevPage + 1);
  });

  const materialLabel = (materials || [])
    .sort((a, b) => a?.referenceName?.localeCompare(b?.referenceName))
    .map((item) => t(item.referenceName));

  const weightToTonnes = (weightKg, weightUnit) => {
    if (weightUnit === "t") {
      return weightKg / 1000;
    } else {
      return weightKg;
    }
  };

  const handleCsvExport = async (
    historyCurrent,
    timeLine,
    client,
    reference,
    weight,
  ) => {
    setIsProgress(true);
    setLoadScreen(true);
    const label = ["Date", "Client", "Référence", "Niveau", `Poids (tonnes)`];
    const lastData = historyCurrent.map((item, index) => [
      `${moment(item._time).tz("Europe/Paris").format("DD MM YYYY HH:mm")}`,
      client,
      reference,
      `${item.filling_level}%`,
      index === 0 && weight && weight !== 0
        ? weight
        : (item.mass / 1000).toFixed(2),
    ]);
    const csvContent = [label, ...lastData];
    exportDataToCSV(csvContent, timeLine);
  };

  const exportDataToCSV = (csvContent, container) => {
    const currentDate = new Date();
    const content = csvContent.map((row) => row.join(";")).join("\n");
    const blob = new Blob([content], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Collecte_${formatDateToString(currentDate)}_${container?.addressJoin?.city}_${activeWasteCategory !== "" ? activeWasteCategory : container.wasteName}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setLoadScreen(false);
  };

  const fetchCycleData = async (item, index) => {
    let cycleData = {};
    let id = item._measurement;
    if (Object.keys(superContainers).length > 0) {
      if (superContainers.containerObjectIdList.includes(id)) {
        id = superContainers._id;
      }
    }
    const responseHistoryCycle = await getHistoryCycleContainer(
      {
        currentCycle: false,
        getLevelLastRotation: false,
        containerCollectionDate: item._time,
      },
      item._measurement,
    );

    const dropOff =
      responseHistoryCycle?.data?.historyCycle[
        responseHistoryCycle.data.historyCycle.length - 1
      ]?._time;

    const clientRegisteredInfo = await getContainerClientRegisteredInfo(
      {
        start: new Date(new Date(dropOff).getTime() - 1).toISOString(),
        stop: new Date(new Date(item._time).getTime() + 1).toISOString(),
      },
      id,
    );

    if (responseHistoryCycle.status === 200) {
      cycleData = responseHistoryCycle.data;
    }

    const clientName = clientRegisteredInfo?.data?.client
      ? JSON.parse(clientRegisteredInfo?.data?.client).name
      : "";

    const clientCode = clientRegisteredInfo?.data?.client
      ? JSON.parse(clientRegisteredInfo?.data?.client).code
      : "";

    const clientRegisteredWeight = clientRegisteredInfo?.data?.weight
      ? clientRegisteredInfo?.data?.weight / 1000
      : (cycleData.historyCycle[0].mass / 1000).toFixed(2);

    const newProgress = ((index + 1) / dataContainerCollection.length) * 100;
    setProgress(newProgress);

    const formattedAnomalies = Object.entries(cycleData.anomalyDelivery).reduce(
      (acc, [key, value]) => {
        acc[t(key)] = value;
        return acc;
      },
      {},
    );

    const anomaliesData = materialLabel.map((element) => {
      const key = element.replace(/Anomalie |Anomaly /, "");
      return formattedAnomalies[key] !== undefined
        ? formattedAnomalies[key]
        : 0;
    });

    return [
      `${moment(dropOff).tz("Europe/Paris").format("DD-MM-YYYY HH:mm")}`,
      `${moment(item._time).tz("Europe/Paris").format("DD-MM-YYYY HH:mm")}`,
      clientName,
      clientCode,
      `${item.addressName}${item?.positioning ? " | " + item.positioning : ""}`,
      `${item.wasteName}`,
      `${cycleData?.historyCycle[0]?.filling_level || 0}%`,
      clientRegisteredWeight,
      cycleData.numberOfPackmat,
      ...anomaliesData,
    ];
  };

  const exportCollectionToCSV = async () => {
    setLoadScreen(true);
    const label = [
      "Date de dépôt",
      "Date de Retrait",
      "Client",
      "Référence",
      "Adresse",
      "Flux",
      "Niveau",
      "Poids (tonnes)",
      "Compactage",
      ...materialLabel,
    ];
    const lastData = [];
    for (let i = 0; i < dataContainerCollection.length; i++) {
      const item = dataContainerCollection[i];
      const data = await fetchCycleData(item, i);
      lastData.push(data);
    }
    const csvContent = [label, ...lastData];

    const content = csvContent.map((row) => row.join(";")).join("\n");
    const blob = new Blob([content], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Collecte_du_${moment(periodHistoryStart).tz("Europe/Paris").format("DD-MM-YYYY_HH-mm")}_au_${moment(periodHistoryStop).tz("Europe/Paris").format("DD-MM-YYYY_HH-mm")}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setProgress(0);
    setLoadScreen(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    setIsLoading(true);
    setOpenFilter(false);

    getHistoryCollection(
      {
        periodStart: periodHistoryStart,
        periodStop: periodHistoryStop,
        keywordFilter: JSON.stringify(keywordFilter.value),
        keepFirst: true,
      },
      signal,
    )
      .then((response) => {
        if (response.status === 200) {
          setDataContainerCollection(response.data.historyCollection);
          if (response.data.historyCollection.length > 0) {
            setCountDataCollection(response.data.historyCollection.length);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error?.name !== "AbortError") {
          console.error(error);
        }
      });

    // Cleanup function to abort the request
    return () => setDataContainerCollection([]);

    // eslint-disable-next-line
  }, [handleValidate]);

  useEffect(() => {
    getWastecategoryList({
      keywordFilter: "",
      listUnCodeFullFilter: {},
      listUnCodeEmptyFilter: {},
      listWasteCodeFullFilter: {},
      listWasteCodeEmptyFilter: {},
      sortByField: "label",
      orderSortByField: "1",
    }).then((response) => {
      setWasteCategory(response.data.data);
    });
  }, []);

  useEffect(
    () => {
      setDisabledDownload(false);
    },
    //eslint-disable-next-line
    [periodHistoryStart, periodHistoryStop],
  );

  return (
    <div className="card-block-activity-item">
      <section className="flex flex-end w-full mt-4">
        <Dropdown
          text={t("Export")}
          disabled={disabledDownload || isLoading}
          children={
            <>
              <span
                onClick={() => exportCollectionToCSV()}
                className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
              >
                CSV (.csv)
              </span>
            </>
          }
        />
      </section>

      {isLoading ? (
        <Loading color="#F5F5F5" />
      ) : (
        <div>
          <section className="time-line">
            {countDataCollection === 0 ? (
              <div className="no-data">Aucune collecte pour cette période.</div>
            ) : (
              <div className="time-line-list">
                {dataContainerCollection
                  .slice(0, currentPage * itemsPerPage)
                  .map((timeLine, index) => {
                    return (
                      <ActivityCollectionRow
                        timeLine={timeLine}
                        index={index}
                        setModalHistoryCycle={setModalHistoryCycle}
                        setDataRow={setDataRow}
                        loadScreen={loadScreen}
                        setLoadScreen={setLoadScreen}
                        handleCsvExport={handleCsvExport}
                        wasteCategory={wasteCategory}
                        superContainers={superContainers}
                        setActiveWasteCategory={setActiveWasteCategory}
                        activeWasteCategory={activeWasteCategory}
                        weightToTonnes={weightToTonnes}
                        setIsProgress={setIsProgress}
                        chartData={chartData}
                        setChartData={setChartData}
                        chartRef={chartRef}
                        cycleChartRef={cycleChartRef}
                        setDisplayChartData={setDisplayChartData}
                      />
                    );
                  })}
              </div>
            )}
          </section>

          {modalHistoryCycle && (
            <ActivityCollectionModal
              data={dataRow}
              modalHistoryCycle={modalHistoryCycle}
              setModalHistoryCycle={setModalHistoryCycle}
              exportDataToCSV={exportDataToCSV}
              activeWasteCategory={activeWasteCategory}
            />
          )}
          {Object.keys(chartData).length > 0 && displayChartData && (
            <div className="absolute w-full flex flex-col items-center justify-cente top-0 -z-50">
              <div
                ref={chartRef}
                className="absolute top-20"
                style={{ height: "45vw", width: "45vw" }}
              >
                <MaterialCountingPieChart
                  dataChart={chartData.dataAnomalyDelivery}
                  labelChart={chartData.labelsAnomalyDelivery}
                  materialTotal={chartData.numberOfAnomaly}
                  colorChart={chartData.colorAnomalyDelivery}
                  statistic={true}
                  showMaterialTotal={false}
                />
              </div>
              <article
                ref={cycleChartRef}
                className="absolute bg-white rounded-xl top-20"
                style={{ width: "45vw" }}
              >
                <ContainerCompact
                  container={chartData.container}
                  history={true}
                  dataForCompactOverlay={chartData.historyCycle}
                  collection={true}
                  findHistoryId={null}
                  showPackmat={false}
                  showLimitFillingLevel={false}
                  showToolbox={false}
                />
              </article>
            </div>
          )}
          {loadScreen && (
            <LoadingScreen isProgress={isProgress} progress={progress} />
          )}
        </div>
      )}
    </div>
  );
};

export default ActivityCollection;
