import { useEffect, useState } from "react";
import axios from "axios";
import StatisticsChartComponent from "./StatisticsChartComponent";
import StatisticsKeyValuesComponent from "./StatisticsKeysValuesComponent";
import { getStatisticsCollections } from "../../../services/container";

const StatisticsCollection = ({
  periodHistoryStart,
  periodHistoryStop,
  periodHistoryStartPrevious,
  periodHistoryStopPrevious,
  keywordFilter,
  numberOfContainers,
  numberOfSite,
  handleValidate,
  setHandleValidate,
  periodName,
}) => {
  const [statisticsCollectionCurrent, setStatisticsCollectionCurrent] =
    useState({});
  const [statisticsCollectionPrevious, setStatisticsCollectionPrevious] =
    useState({});
  const [collectionWasteGenerated, setCollectionWasteGenerated] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      setHandleValidate(true);
    },
    // eslint-disable-next-line
    [],
  );

  const fetchData = () => {
    setIsLoading(true);
    axios
      .all([
        getStatisticsCollections({
          periodStart: periodHistoryStart,
          periodStop: periodHistoryStop,
          keywordFilter: JSON.stringify(keywordFilter.value),
        }),
        getStatisticsCollections({
          periodStart: periodHistoryStartPrevious,
          periodStop: periodHistoryStopPrevious,
          keywordFilter: JSON.stringify(keywordFilter.value),
        }),
      ])
      .then(
        axios.spread(
          (
            responseStatisticsCollections,
            responseStatisticsCollectionsPrevious,
          ) => {
            if (
              responseStatisticsCollections.status === 200 &&
              responseStatisticsCollectionsPrevious.status
            ) {
              setStatisticsCollectionCurrent(
                responseStatisticsCollections.data,
              );
              setStatisticsCollectionPrevious(
                responseStatisticsCollectionsPrevious.data,
              );
              setCollectionWasteGenerated(responseStatisticsCollections.data);

              setIsLoading(false);
              setHandleValidate(false);
            }
          },
        ),
      );
  };

  useEffect(
    () => {
      if (handleValidate) {
        fetchData();
      }
    },
    // eslint-disable-next-line
    [handleValidate],
  );

  return (
    <div className="text-blue w-full">
      <StatisticsKeyValuesComponent
        periodHistoryStart={periodHistoryStart}
        periodHistoryStop={periodHistoryStop}
        periodHistoryStartPrevious={periodHistoryStartPrevious}
        periodHistoryStopPrevious={periodHistoryStopPrevious}
        numberOfContainers={numberOfContainers}
        numberOfSite={numberOfSite}
        periodName={periodName}
        statisticsCollectionCurrent={statisticsCollectionCurrent}
        statisticsCollectionPrevious={statisticsCollectionPrevious}
        collectionWasteGenerated={collectionWasteGenerated}
        isLoading={isLoading}
      />

      <StatisticsChartComponent
        statisticsCollectionCurrent={statisticsCollectionCurrent}
        statisticsCollectionPrevious={statisticsCollectionPrevious}
        isLoading={isLoading}
      />
    </div>
  );
};

export default StatisticsCollection;
