import { useEffect, useState } from "react";
import { ImageSrc } from "../components/utils/ImageSrc";
import { Group, Layer, Line, Stage } from "react-konva";
import { azureSASTokenToUrl } from "../components/formatted/FormattedAzureSasToken";
import { getDimensions } from "../components/formatted/CalculateImageDimensions";
import { formattedColorMaterial } from "../components/formatted/FormattedText";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../components/formatted/UseWindowsDimensions";

export const AppImageTreatment = ({
  imageUrl,
  xBox,
  yBox,
  wBox,
  hBox,
  detectedObjects,
  materialToDisplay,
  setMaterialToDisplay,
}) => {
  const { t } = useTranslation();
  const [imgWidth, setImgWidth] = useState();
  const [imgHeight, setImgHeight] = useState();
  const [wasteObjectsList, setWasteObjectsList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const { widthWindow } = useWindowDimensions();
  const materialsDropdownToDisplay = [];
  const imgDivWidth = widthWindow - 20;
  const imgDivHeight = 380;

  useEffect(
    () => {
      const fetchData = async () => {
        setWasteObjectsList(detectedObjects);
        const materialsTemporalData = [];
        detectedObjects.forEach((annotation) => {
          const annotationToLowerCase = annotation.cls.toLowerCase();
          const index = materialsTemporalData.findIndex(
            (material) => material.name === t(annotationToLowerCase),
          );

          if (index === -1) {
            materialsTemporalData.push({
              name: t(annotationToLowerCase),
              value: annotationToLowerCase,
              color: formattedColorMaterial(annotationToLowerCase),
              count: 1,
              nameAndCount: `${t(annotationToLowerCase)} (${1})`,
            });
          } else {
            materialsTemporalData[index].count += 1;
            materialsTemporalData[index].nameAndCount =
              `${t(annotationToLowerCase)} (${materialsTemporalData[index].count})`;
          }
        });

        setMaterialList((prevState) => {
          if (prevState.length > 0) {
            const mergedData = prevState.map((prevItem) => {
              const matchingItemIndex = materialsTemporalData.findIndex(
                (newItem) => newItem.name === prevItem.name,
              );
              if (matchingItemIndex !== -1) {
                const count =
                  prevItem.count +
                  materialsTemporalData[matchingItemIndex].count;
                return {
                  ...prevItem,
                  count: count,
                  nameAndCount: `${t(materialsTemporalData[matchingItemIndex].name)} (${count})`,
                };
              } else {
                return prevItem;
              }
            });
            materialsTemporalData.forEach((newItem) => {
              const matchingPrevItemIndex = mergedData.findIndex(
                (prevItem) => prevItem.name === newItem.name,
              );
              if (matchingPrevItemIndex === -1) {
                mergedData.push(newItem);
              }
            });
            return mergedData;
          } else {
            return materialsTemporalData;
          }
        });
      };
      fetchData();
    },
    // eslint-disable-next-line
    [detectedObjects],
  );

  if (imageUrl) {
    getDimensions(azureSASTokenToUrl(imageUrl), setImgWidth, setImgHeight);
  }

  let local_w_box = wBox + 80;
  let local_h_box = hBox + 80;
  let local_x_box = xBox - 40;
  let local_y_box = yBox - 40;

  // if container keypoints are outside the image, adapt the dimensions to fit the image
  if (local_x_box < 0) {
    local_w_box -= Math.abs(local_x_box);
    local_x_box = 0;
  }
  if (local_y_box < 0) {
    local_h_box -= Math.abs(local_y_box);
    local_y_box = 0;
  }
  // if after that the width and height of the box is still larger than the image, crop as well
  local_w_box = Math.min(local_w_box, imgWidth - local_x_box);
  local_h_box = Math.min(local_h_box, imgHeight - local_y_box);

  const scaleFactor = Math.min(
    imgDivWidth / local_w_box,
    imgDivHeight / local_h_box,
  );

  return (
    <section className="bg-white rounded-md">
      <div className="w-full p-2">
        <div className="dropdown-materials">
          <select
            id="material"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mr-2"
            onChange={(event) => {
              setMaterialToDisplay(
                event.target.value ? [JSON.parse(event.target.value)] : [],
              );
            }}
          >
            <option value={null} className="text-gray-300">
              {t("Select the materials to display")}
            </option>
            {materialList.map((item, index) =>
              item.value ===
              (materialToDisplay.length > 0
                ? materialToDisplay[0]?.value
                : false) ? (
                <option selected key={index} value={JSON.stringify(item)}>
                  {item.nameAndCount}
                </option>
              ) : (
                <option key={index} value={JSON.stringify(item)}>
                  {item.nameAndCount}
                </option>
              ),
            )}
          </select>
        </div>
      </div>
      <Stage width={imgDivWidth} height={imgDivHeight}>
        <Layer>
          <Group
            clip={{
              x: local_x_box,
              y: local_y_box,
              width: local_w_box,
              height: local_h_box,
            }}
            x={
              (imgDivWidth - local_w_box * scaleFactor) / 2 -
              local_x_box * scaleFactor
            }
            y={
              (imgDivHeight - local_h_box * scaleFactor) / 2 -
              local_y_box * scaleFactor
            }
            scaleX={scaleFactor}
            scaleY={scaleFactor}
          >
            {/* Get Image*/}
            <ImageSrc src={azureSASTokenToUrl(imageUrl)} />

            {/* Draw Object */}
            {Array.isArray(wasteObjectsList) &&
              wasteObjectsList.length > 0 &&
              wasteObjectsList.map((anomalie, index) => {
                if (
                  "cls" in anomalie === false ||
                  "points_konva" in anomalie === false
                ) {
                  return null;
                }
                let type = anomalie.cls.toLowerCase();

                //If index ==! -1 then the material is in the list of material to display
                const findAnomalyIndex = materialToDisplay.findIndex(
                  (item) => item.value === type,
                );
                const findMaterialDropdownIndex =
                  materialsDropdownToDisplay.findIndex((item) => item === type);

                return (
                  <Group key={anomalie._id}>
                    {(findAnomalyIndex !== -1 ||
                      findMaterialDropdownIndex !== -1) && (
                      <Group key={`anomaly-${anomalie._id}`}>
                        <Line
                          points={anomalie.points_konva}
                          opacity={0.3}
                          strokeWidth={0}
                          closed={true}
                          fill={formattedColorMaterial(anomalie.cls)}
                        />
                        <Line
                          points={anomalie.points_konva}
                          opacity={1}
                          strokeWidth={5}
                          closed={true}
                          stroke={formattedColorMaterial(anomalie.cls)}
                        />
                      </Group>
                    )}
                  </Group>
                );
              })}
          </Group>
        </Layer>
      </Stage>
    </section>
  );
};
