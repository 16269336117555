import { useEffect, useRef } from "react";
import * as echarts from "echarts";
import Cookies from "js-cookie";
import useWindowDimensions from "../../components/formatted/UseWindowsDimensions";
import { useTranslation } from "react-i18next";
import {
  formattedDate,
  formattedTime,
} from "../formatted/FormattedDateAndTime";

export const LineChart = ({
  chartName,
  dataChart,
  dataChart2,
  dataChart3,
  dataCompact,
  unitValueChart,
  maxYAxis,
  messageLabelTooltip,
  stepYAxis,
  dataForCompactOverlay,
  limitFillingLevel,
  container,
  historyCurrent,
  collection,
  findHistoryId,
  showPackmat,
  showLimitFillingLevel,
  showToolbox,
}) => {
  const { t, i18n } = useTranslation();

  const { heightWindow, widthWindow } = useWindowDimensions();
  const debug = process.env.REACT_APP_DEBUG;
  const chartRef = useRef(null);
  const currentFillingLevel = dataChart[0][1];

  const cookieSideNavExpandedValue = Cookies.get("sideNavExpanded");
  const parsedSideNavExpandedValue = cookieSideNavExpandedValue
    ? JSON.parse(cookieSideNavExpandedValue)
    : true;

  useEffect(
    () => {
      if (dataForCompactOverlay?.length > 0) {
        const chart = echarts.init(
          document.getElementById(`${chartName}-chart`),
        );
        chartRef.current = chart;

        const generateOptions = () => {
          return {
            title: {
              text: messageLabelTooltip,
              left: "1%",
              textStyle: {
                color: "#17a2b8",
              },
            },
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                if (unitValueChart === "ton") {
                  return `${params[0].data[1].toFixed(2)} ${unitValueChart}`;
                }

                const dataPoint = params[0].data;
                const value = dataPoint[1];
                const dateStr = dataPoint[0];
                const date = new Date(dateStr);

                return `${value}${unitValueChart} - ${formattedDate(date, i18n.language)}, ${formattedTime(date)}`;
              },
            },
            toolbox: {
              feature: {
                saveAsImage: {
                  show: showToolbox,
                  title: "Sauvegarder en image",
                  type: "png",
                  lang: ["French"],
                },
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "time",
              boundaryGap: false,
              axisLabel: {
                interval: "auto",
                formatter: function (value) {
                  const date = new Date(value);
                  const hours = date.getHours().toString().padStart(2, "0");
                  const minutes = date.getMinutes().toString().padStart(2, "0");
                  const day = date.getDate();
                  const month = date?.toLocaleString(
                    i18n.language === "en" ? "en-US" : "default",
                    {
                      month: "short",
                    },
                  );
                  if (hours === "00" && minutes === "00") {
                    return `{a|${day} ${month}}`;
                  } else {
                    return `${hours}:${minutes}`;
                  }
                },
                rich: {
                  a: {
                    fontWeight: "bold",
                  },
                },
              },
              splitNumber: 3,
            },
            yAxis: {
              type: "value",
              beginAtZero: true,
              max: maxYAxis,
              min: 0,
              axisLabel: {
                formatter: function (value) {
                  return value + " " + unitValueChart;
                },
              },
              ticks: {
                display: true,
                stepSize: stepYAxis,
              },
            },
            series: generateSeries(),
          };
        };

        const generateSeries = () => {
          const series = [
            {
              name: "Cycle",
              type: "line",
              color: "rgba(75,192,192,1)",
              areaStyle: {
                color: "rgba(75,192,192,0.1)",
              },
              emphasis: {
                focus: "series",
                itemStyle: {
                  borderWidth: 15,
                },
                symbolSize: 15,
              },
              data: dataChart.reverse(),
              markPoint: {
                data: showPackmat && dataCompact,
                label: {
                  color: "#FFF",
                },
              },
              markLine: {
                silent: true,
                symbol: ["none", "none"],
                lineStyle: {
                  color: "#ACACAC",
                  width: 1,
                },
                label: {
                  normal: {
                    show: true,
                    offset: [-130, 0],
                    top: "middle",
                    formatter: `${t("Limit filling level")} {a|(${limitFillingLevel}%)}`,
                    rich: {
                      a: {
                        fontWeight: "bold",
                      },
                    },
                    backgroundColor:
                      currentFillingLevel > limitFillingLevel
                        ? "#E5193C"
                        : "#E8E8E8",
                    color: currentFillingLevel > limitFillingLevel && "#FFFFFF",
                    padding: [4, 8],
                    borderRadius: 20,
                    borderColor:
                      currentFillingLevel > limitFillingLevel
                        ? "#E5193C"
                        : "#E8E8E8",
                    borderWidth: 1,
                  },
                },
                data: showLimitFillingLevel &&
                  limitFillingLevel && [
                    {
                      yAxis: limitFillingLevel,
                    },
                  ],
              },
            },
            debug === "true" && {
              name: "Cycle",
              type: "line",
              color: "rgba(244,67,54,1)",
              areaStyle: {
                color: "rgba(244,67,54,0.1)",
              },
              emphasis: {
                focus: "series",
              },
              data: dataChart2?.reverse(),
            },
            debug === "true" && {
              name: "Cycle",
              type: "line",
              color: "rgba(255,217,102,1)",
              areaStyle: {
                color: "rgba(255,217,102,0.1)",
              },
              emphasis: {
                focus: "series",
              },
              data: dataChart3?.reverse(),
            },
          ];

          if (showPackmat && dataCompact) {
            const packmatLineData = dataCompact?.map((item) => {
              return {
                type: "line",
                data: [[item.xAxis, 100]],
                showSymbol: false,
                markLine: {
                  silent: true,
                  symbol: ["none", "none"],
                  label: {
                    normal: {
                      show: false,
                    },
                  },
                  data: [
                    {
                      type: "average",
                      valueIndex: 0,
                      itemStyle: {
                        normal: {
                          color: "#ACACAC",
                        },
                      },
                    },
                  ],
                },
              };
            });
            series.push(...packmatLineData);
          }

          return series;
        };

        const updateChart = () => {
          const newOption = generateOptions();
          chart.setOption(newOption);
        };

        updateChart();

        chart.on("click", function (params) {
          if (params.seriesType === "line") {
            const dataPoint = params.data;
            findHistoryId(dataPoint[0]);
          }
        });

        return () => {
          if (chartRef.current) {
            chartRef.current.dispose();
          }
        };
      }
    },
    // eslint-disable-next-line
    [
      widthWindow,
      parsedSideNavExpandedValue,
      container,
      historyCurrent,
      i18n.language,
    ],
  );

  return (
    <div
      id={`${chartName}-chart`}
      style={{
        height: heightWindow / 3.5,
        width: collection ? widthWindow / 2.3 : "100%",
      }}
    ></div>
  );
};
