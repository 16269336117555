import { useState } from "react";
import { InfosComponent } from "../../../components/InfosComponent";
import { useTranslation } from "react-i18next";

export const WasteSortingErrors = ({
  formData,
  setFormData,
  items,
  setItems,
}) => {
  //Get all categories and all french name of parent category in array object and move category == 'other' on last element of array object
  // exemple : categories = [{name: 'other', frenchName: 'Autres'}, {name: 'plastic', frenchName: 'Plastique'}, {name: 'other', frenchName: 'Autres'}]
  const categories = [...new Set(items.map((value) => value.parentCategory))]
    .sort((a, b) => (a === "other" ? 1 : -1))
    .map((category) => {
      return {
        name: category,
        frenchName: items.find((item) => item.parentCategory === category)
          .frenchParentCategory,
      };
    });
  const { t } = useTranslation();
  const [allChecked, setAllChecked] = useState(isAllChecked(items));
  const [allCheckedOnCategory, setAllCheckedOnCategory] = useState(
    calcAllCheckedOnCategory(categories, items),
  );

  const handleCheckToggleAll = (checked) => {
    // Check all items
    let updatedItems = items;

    for (let item of updatedItems) {
      item.isChecked = !checked;
    }
    setItems(updatedItems);

    const newFormData = { ...formData };
    newFormData["authorizedMaterial"] = updatedItems
      .map((item) =>
        item.isChecked === false
          ? {
              name: t(item.referenceName),
              value: item.referenceName,
              frenchParentCategory: item.frenchParentCategory,
            }
          : null,
      )
      .filter(function (el) {
        return el != null;
      });
    setFormData(newFormData);

    setAllCheckedOnCategory(calcAllCheckedOnCategory(categories, updatedItems));
    setAllChecked(!checked);
  };

  const handleCheckToggleAllOnCategory = (category, checked) => {
    // Check all items on category
    let updatedItems = items;

    for (let item of updatedItems) {
      // Check if all items on category are checked
      if (item.parentCategory === category) {
        item.isChecked = !checked;
      }
    }
    setItems(updatedItems);

    const newFormData = { ...formData };
    newFormData["authorizedMaterial"] = updatedItems
      .map((item) =>
        item.isChecked === false
          ? {
              name: t(item.referenceName),
              value: item.referenceName,
              frenchParentCategory: item.frenchParentCategory,
            }
          : null,
      )
      .filter(function (el) {
        return el != null;
      });
    setFormData(newFormData);

    setAllCheckedOnCategory(calcAllCheckedOnCategory(categories, updatedItems));

    setAllChecked(isAllChecked(updatedItems));
  };

  const handleCheck = (_id) => {
    // Check item
    const updatedItems = items.map((item) =>
      item._id === _id ? { ...item, isChecked: !item.isChecked } : item,
    );
    setItems(updatedItems);

    const newFormData = { ...formData };
    newFormData["authorizedMaterial"] = updatedItems
      .map((item) =>
        item.isChecked === false
          ? {
              name: t(item.referenceName),
              value: item.referenceName,
              frenchParentCategory: item.frenchParentCategory,
            }
          : null,
      )
      .filter(function (el) {
        return el != null;
      });
    setFormData(newFormData);

    setAllChecked(isAllChecked(updatedItems));
    setAllCheckedOnCategory(calcAllCheckedOnCategory(categories, updatedItems));
  };

  function calcAllCheckedOnCategory(categories, items) {
    // Check if all items on category are checked
    return categories.map(
      (category) =>
        !items
          .map((item) =>
            item.parentCategory === category.name ? item.isChecked : null,
          )
          .includes(false),
    );
  }

  function isAllChecked(items) {
    // Check if all items are checked
    return !items.map((item) => item.isChecked).includes(false);
  }

  return (
    <section className="">
      <InfosComponent
        message={t(
          "Select unauthorized material categories in your flow: sorting errors, contaminants, undesirables, prohibited materials",
        )}
      />

      {/* Select all materials */}
      <div className="flex mb-2">
        <div>
          <input
            className="relative h-5 p-0 w-auto"
            type="checkbox"
            checked={Boolean(allChecked)}
            onChange={() => handleCheckToggleAll(allChecked)}
          />
          <span className="my-auto font-bold">{t("All")}</span>
        </div>
      </div>

      {/* Select materials by category */}
      <section className="flex justify-around flex-wrap">
        {categories.map((category, index) => (
          <div
            className={`min-xl:w-1/6 lg:w-1/4 md:w-1/2 sm:w-full my-2 mx-1 py-2 border border-solid border-grey rounded`}
            key={`caterogy-${category._id}`}
          >
            <input
              className="relative  h-5 p-0 w-auto"
              type="checkbox"
              checked={Boolean(allCheckedOnCategory[index])}
              onChange={() =>
                handleCheckToggleAllOnCategory(
                  category.name,
                  allCheckedOnCategory[index],
                )
              }
            />
            <span style={{ fontWeight: "bold" }}>
              {category.frenchName.charAt(0).toUpperCase() +
                category.frenchName.slice(1)}
            </span>

            <div className="flex flex-col ml-2 py-2">
              {items.map((item, ind) =>
                item.parentCategory === category.name &&
                item.isActiveSortingError ? (
                  <div key={`item-${item._id}`} className="flex">
                    <input
                      className="relative  h-5 p-0 w-auto"
                      type="checkbox"
                      checked={Boolean(item.isChecked)}
                      onChange={() => handleCheck(item._id)}
                    />

                    {t(item.referenceName)}
                  </div>
                ) : null,
              )}
            </div>
          </div>
        ))}

        <div
          className={`min-xl:w-1/6 lg:w-1/4 md:w-1/2 sm:w-full my-2 mx-1 py-2`}
        ></div>
        <div
          className={`min-xl:w-1/6 lg:w-1/4 md:w-1/2 sm:w-full my-2 mx-1 py-2`}
        ></div>
      </section>
    </section>
  );
};
