import { useCallback, useState, useEffect, useRef } from "react";
import { Group, Layer, Line, Stage, Rect } from "react-konva";
import { getDimensions } from "../formatted/CalculateImageDimensions";
import {
  formattedColorMaterial,
  formattedLevelColor,
} from "../formatted/FormattedText";
import {
  azureSASTokenToUrl,
  formatAzureBlob,
} from "../formatted/FormattedAzureSasToken";
import { handleSelectOne } from "../../containers/container/utilsTable";
import { ImageSrc } from "./ImageSrc";
import ContainerZoomButton from "../../containers/container/ContainerZoomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWeightHanging } from "@fortawesome/free-solid-svg-icons";
import myPackmatIcon from "../../static/assets/CompactingIcon.jpg";
import { useTranslation } from "react-i18next";
import { getAzureBlob } from "../../services/container";

export const CropImage = ({
  container,
  containersSelected,
  setContainersSelected,
  data,
  displayLegend,
  imgDivWidth,
  imgDivHeight,
  isTooltip,
  isMap,
  isCharacterization,
  materialsDropdownToDisplay,
  showCheckbox,
  displayAnomalyLegend,
  anomalyList,
  isActivity,
  onClickZoomButton,
  history,
  displayToBlock,
  setContainerActive,
  containerSortingError,
  expendImage,
  tooglePicture,
  anomaliesObjects,
}) => {
  const { t } = useTranslation();
  const [imgWidth, setImgWidth] = useState();
  const [imgHeight, setImgHeight] = useState();
  const [materialToDisplay, setMaterialToDisplay] = useState([]);
  const [wasteObjectsList, setWasteObjectsList] = useState([]);
  const isLandscape = data.w_box > data.h_box ? true : false;
  const showConstructionLinesForDebug = false;

  const zoomButtonRef = useRef(null);
  const zoomTextRef = useRef(null);
  const zoomRectRef = useRef(null);

  const currentData = history?.find((item) => item._time === data._time);
  const weightKG = currentData?.mass;
  const packmat = currentData?.packmat;

  const handleMouseOverContainerBox = () => {
    if (zoomButtonRef.current) {
      zoomButtonRef.current.opacity(1);
      zoomTextRef.current.opacity(1);
      zoomRectRef.current.opacity(0.2);
      document.body.style.cursor = "pointer";
    }
  };

  const handleMouseLeaveContainerBox = () => {
    if (zoomButtonRef.current) {
      zoomButtonRef.current.opacity(0);
      zoomTextRef.current.opacity(0);
      zoomRectRef.current.opacity(0);
      document.body.style.cursor = "default";
    }
  };

  const fetchWasteObjects = useCallback(() => {
    if (!anomaliesObjects || anomaliesObjects.length === 0) {
      if (data?.image_url) {
        getAzureBlob({
          blobUrl: formatAzureBlob(
            data?.image_url
              .replace("pictures", "output-surface")
              .replace(
                "-R.jpeg",
                "-" + container._measurement + "-surface.json",
              )
              .replace(
                "-L.jpeg",
                "-" + container._measurement + "-surface.json",
              )
              .replace(".jpeg", "-" + container._measurement + "-surface.json"),
          ),
        }).then((wasteObjects) => {
          if (wasteObjects.status === 200) {
            setWasteObjectsList(wasteObjects.data.object_segmentations);
          }
        });
      }
    } else {
      setWasteObjectsList(anomaliesObjects);
    }
  }, [container, data, anomaliesObjects]);

  useEffect(() => {
    fetchWasteObjects();
  }, [fetchWasteObjects]);

  if (data?.image_url) {
    getDimensions(
      azureSASTokenToUrl(data?.image_url),
      setImgWidth,
      setImgHeight,
    );
  }

  let local_w_box = data.w_box + 80;
  let local_h_box = data.h_box + 80;
  let local_x_box = data.x_box - 40;
  let local_y_box = data.y_box - 40;

  // if container keypoints are outside the image, adapt the dimensions to fit the image
  if (local_x_box < 0) {
    local_w_box -= Math.abs(local_x_box);
    local_x_box = 0;
  }
  if (local_y_box < 0) {
    local_h_box -= Math.abs(local_y_box);
    local_y_box = 0;
  }
  // if after that the width and height of the box is still larger than the image, crop as well
  local_w_box = Math.min(local_w_box, imgWidth - local_x_box);
  local_h_box = Math.min(local_h_box, imgHeight - local_y_box);

  if (!isTooltip) {
    data["volume"] = container.volume;
    if (container.contentCategory && container.contentCategory.density) {
      data["density"] = container.contentCategory.density;
    } else {
      data["density"] = container.density;
    }
  }

  const anomalyToSideCheck = () => {
    if (isCharacterization === true) {
      return false;
    }
    return (
      !isMap &&
      data.anomalies !== 0 &&
      data.typeAnomaly &&
      Object.keys(data.typeAnomaly).length <= 2
    );
  };

  const anomalyToBottomCheck = () => {
    if (isCharacterization === true) {
      return false;
    }
    return (
      (isLandscape && !isTooltip) ||
      (!isMap &&
        data.anomalies !== 0 &&
        data.typeAnomaly &&
        Object.keys(data.typeAnomaly).length > 2)
    );
  };

  const updateShowAnomaly = (type) => {
    let newShowItem = [...materialToDisplay];

    const findIndex = materialToDisplay.findIndex((item) => item === type);
    if (findIndex === -1) {
      newShowItem.push(type);
    } else {
      newShowItem.splice(findIndex, 1);
    }
    setMaterialToDisplay(newShowItem);
  };

  const scaleFactor = Math.min(
    imgDivWidth / local_w_box,
    imgDivHeight / local_h_box,
  );

  return (
    <section className="bg-white rounded-md ">
      <section className={`visual-card-landscape`}>
        <div className="flex flex-row">
          {/* Image */}
          <div
            className={`visual-card-box-image visual-card-data-landscape `}
            style={{ margin: "auto 0", position: "relative" }}
            onClick={() => !expendImage && !isActivity && tooglePicture()}
          >
            {showCheckbox && (
              <div
                style={{ position: "absolute", top: 10, left: -5, zIndex: 10 }}
              >
                <input
                  type="checkbox"
                  className={`w-4 h-4 cursor-pointer`}
                  onChange={(event) =>
                    handleSelectOne(
                      event,
                      data,
                      setContainersSelected,
                      containersSelected,
                    )
                  }
                  checked={data.checked}
                />
              </div>
            )}

            {data?.image_url ? (
              <Stage width={imgDivWidth} height={imgDivHeight}>
                <Layer>
                  <Group
                    clip={{
                      x: local_x_box,
                      y: local_y_box,
                      width: local_w_box,
                      height: local_h_box,
                    }}
                    x={
                      (imgDivWidth - local_w_box * scaleFactor) / 2 -
                      local_x_box * scaleFactor
                    }
                    y={
                      (imgDivHeight - local_h_box * scaleFactor) / 2 -
                      local_y_box * scaleFactor
                    }
                    scaleX={scaleFactor}
                    scaleY={scaleFactor}
                  >
                    {/* Get Image*/}
                    <ImageSrc src={azureSASTokenToUrl(data?.image_url)} />

                    {/* Draw Object */}
                    {Array.isArray(wasteObjectsList) &&
                      wasteObjectsList.length > 0 &&
                      wasteObjectsList.map((anomalie, index) => {
                        if (
                          "cls" in anomalie === false ||
                          "points_konva" in anomalie === false
                        ) {
                          return null;
                        }
                        let type = anomalie.cls.toLowerCase();

                        //If index ==! -1 then the material is in the list of material to display
                        const findAnomalyIndex = materialToDisplay.findIndex(
                          (item) => item === type,
                        );
                        const findMaterialDropdownIndex =
                          materialsDropdownToDisplay.findIndex(
                            (item) => item === type,
                          );

                        return (
                          <Group key={anomalie._id}>
                            {(findAnomalyIndex !== -1 ||
                              findMaterialDropdownIndex !== -1) && (
                              <Group key={`anomaly-${anomalie._id}`}>
                                <Line
                                  points={anomalie.points_konva}
                                  opacity={0.3}
                                  strokeWidth={0}
                                  closed={true}
                                  fill={formattedColorMaterial(anomalie.cls)}
                                />
                                <Line
                                  points={anomalie.points_konva}
                                  opacity={1}
                                  strokeWidth={5}
                                  closed={true}
                                  stroke={formattedColorMaterial(anomalie.cls)}
                                />
                              </Group>
                            )}
                          </Group>
                        );
                      })}
                  </Group>

                  {/* AFFICHAGE DU CADRE DE REFERENCE CARRE*/}
                  {showConstructionLinesForDebug && (
                    <Rect
                      width={imgDivWidth}
                      height={imgDivHeight}
                      stroke="black"
                      strokeWidth={4}
                    />
                  )}

                  <Group
                    onMouseOver={() => {
                      if (displayToBlock) {
                        handleMouseOverContainerBox();
                      }
                    }}
                    onMouseLeave={() => handleMouseLeaveContainerBox()}
                    onClick={() => {
                      if (!isActivity) {
                        onClickZoomButton();
                        setContainerActive(container);
                      }
                    }}
                  >
                    <Rect
                      ref={zoomRectRef}
                      width={imgDivWidth}
                      height={imgDivHeight}
                      opacity={0}
                      fill="white"
                    />

                    <ContainerZoomButton
                      buttonRef={zoomButtonRef}
                      textRef={zoomTextRef}
                      imgDivWidth={imgDivWidth}
                      imgDivHeight={imgDivHeight}
                    />
                  </Group>
                </Layer>
              </Stage>
            ) : (
              <div className="no-data">Aucune image.</div>
            )}
          </div>
          {(isActivity || containerSortingError) && (
            <div className="w-1/3 truncate">
              <div className="flex flex-col w-full">
                {!containerSortingError &&
                  container.activatedFillingLevelOption && (
                    <>
                      {/* Filling legend */}
                      <section className="flex flex-row">
                        <div
                          className={`${isTooltip ? "visual-level-tooltip" : "visual-level mx-1"} h-8 w-5 mt-2 mr-2`}
                        >
                          <div
                            className={`
                    ${formattedLevelColor(data.filling_level, data.limitFillingLevel)}`}
                            style={{
                              height: data.filling_level + "%",
                              borderRadius: "0 0 2px 2px",
                            }}
                          ></div>
                        </div>

                        <div className="flex flex-col w-2/3">
                          <span className="text-grey text-md truncate ...">
                            {t("Filling level")}
                          </span>
                          <span className="text-grey font-semibold">
                            {data.filling_level ? data.filling_level : 0}%
                          </span>
                        </div>
                      </section>

                      {/* Weight legend */}
                      {data.volume !== 0 && data.density ? (
                        <section className="flex flex-row mt-3">
                          <FontAwesomeIcon
                            icon={faWeightHanging}
                            className="mass"
                            style={{ marginTop: "10px" }}
                          />
                          <div className="flex flex-col w-2/3">
                            <span className="text-grey text-md truncate ...">
                              {t("Estimated weight")}
                            </span>
                            <span className="text-grey font-semibold">
                              {Math.round(weightKG)}kg
                            </span>
                          </div>
                        </section>
                      ) : (
                        ""
                      )}

                      {/* Packmat legend */}
                      {packmat === true && (
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 15,
                          }}
                        >
                          <img
                            src={myPackmatIcon}
                            alt="Packmat Icon"
                            style={{
                              height: 28,
                              verticalAlign: "middle",
                              marginRight: 5,
                            }}
                          />
                          <span className="text" style={{ marginLeft: 0 }}>
                            {t("Packmat")}
                          </span>
                        </span>
                      )}
                    </>
                  )}

                {/* Anomalies legend */}
                {container.activatedSortingErrorOption && (
                  <section
                    className={`flex flex-row ${data.anomalies !== 0 ? "text-grey" : "text-gainsboro-transparent"} mt-3`}
                  >
                    <i className="fas fa-exclamation-triangle data-icon text-xl mr-2"></i>
                    <div
                      className={`flex flex-col ${data.anomalies !== 0 ? "" : "visual-data-hidden"}`}
                    >
                      <span className="text-grey text-md truncate ...">
                        {t("Anomalies")}
                      </span>
                      <span className="text-grey font-semibold">
                        {data.anomalies !== 0 ? data.anomalies : 0}
                      </span>
                    </div>
                  </section>
                )}
              </div>
              {container.activatedSortingErrorOption && (
                <div className="mt-2 ml-1">
                  {anomalyList
                    .slice()
                    .sort((a, b) => a.value.localeCompare(b.value))
                    .map((anomaly, index) => (
                      <div key={anomaly._id} className="flex flex-row mb-1">
                        <div
                          id={`checkbox-${index}`}
                          className={`w-4 h-4 flex items-center justify-center border-solid border-2 rounded mr-1 cursor-pointer`}
                          style={{
                            borderColor: anomaly.color,
                            backgroundColor:
                              materialToDisplay.includes(anomaly.value) &&
                              anomaly.color,
                          }}
                          onClick={() => updateShowAnomaly(anomaly.value)}
                        >
                          {materialToDisplay.includes(anomaly.value) && (
                            <i className="fas fa-check text-xs text-white"></i>
                          )}
                        </div>

                        <label
                          for={`checkbox-${index}`}
                          className="text-sm font-medium text-gray-700 truncate"
                          style={{ maxWidth: "70%" }}
                        >
                          {anomaly.name}
                        </label>
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>

        {/* Legend */}
        {displayLegend && (
          <div
            className={`flex ${isLandscape ? "w-full flex-row" : "w-full flex-col"}`}
          >
            {/* Anomalies list legend if lenght <= 2*/}
            {anomalyToSideCheck() && displayAnomalyLegend && (
              <section
                className={`flex visual-data visual-data-anomaly-tooltip ${isTooltip ? "visual-data-tooltip " : ""} `}
              >
                {Object.keys(data.typeAnomaly).map((type, index) => {
                  return (
                    <div key={type._id} className="visual-data-anomaly-type">
                      <div
                        className={`circle-content`}
                        style={{
                          backgroundColor: formattedColorMaterial(type),
                        }}
                      ></div>
                      <div
                        className={` ${isTooltip ? "" : "visual-data-anomaly-text"}`}
                      >
                        <p>
                          {t(type)} ({data.typeAnomaly[type]})
                        </p>
                        <button
                          className="button-nothing"
                          onClick={() => {
                            updateShowAnomaly(type);
                          }}
                        >
                          {materialToDisplay.includes(type)
                            ? "Cacher"
                            : "Afficher"}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </section>
            )}
          </div>
        )}
      </section>

      {/* Anomaly legend for landscape or anomaly length > 2*/}
      {!anomalyToSideCheck() &&
        anomalyToBottomCheck() &&
        displayAnomalyLegend && (
          <article className="visual-type-anomaly m-2">
            {Object.keys(data.typeAnomaly).map((type, index) => {
              return (
                <div key={type._id} className="visual-data-anomaly-type">
                  <div
                    className={`circle-content`}
                    style={{ backgroundColor: formattedColorMaterial(type) }}
                  ></div>
                  <div
                    className={` ${isTooltip ? "" : "visual-data-anomaly-text"}`}
                  >
                    <p>
                      {t(type)} ({data.typeAnomaly[type]})
                    </p>
                    <button
                      className="button-nothing"
                      onClick={() => {
                        updateShowAnomaly(type);
                      }}
                    >
                      {materialToDisplay.includes(type) ? "Cacher" : "Afficher"}
                    </button>
                  </div>
                </div>
              );
            })}
          </article>
        )}
    </section>
  );
};
