import { CustomInput, FormGroup, Input, Label } from "reactstrap";
import { handleFormChange } from "../../../components/modals/EditableTable";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Loading from "../../../components/utils/Loading";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ContainerManagement = ({
  formData,
  setFormData,
  setErrorForm,
}) => {
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");
  const { t } = useTranslation();
  const [wasteNames, setWasteNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [helpPackmat, setHelpPackmat] = useState(false);
  const [helpFillingLevel, setHelpFillingLevel] = useState(false);
  const [helpShowDepotCycle, setHelpShowDepotCycle] = useState(false);
  const viewMode = Cookies.get("viewMode") || "client";
  const isAkanthasAccount = (Cookies.get("userEmail") || "").includes(
    "@akanthas.com",
  );
  const [adminUserConnected] = useState(
    isAkanthasAccount && viewMode === "admin",
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      await axios
        .all([
          axios.get(`${API}wastecategory/getWasteName`, {
            headers: { authorization: `Bearer ${token}` },
          }),
        ])
        .then(
          axios.spread((responseWasteName) => {
            if (responseWasteName.status === 200) {
              setWasteNames(responseWasteName.data.wasteCategoryName);
              setIsLoading(false);
            }
          }),
        );
    };
    fetchData();
  }, [API, token]);

  const isInputField = (event, fieldMessage) => {
    if (event.target.value) {
      setErrorForm("");
      return true;
    }
    setErrorForm(t("Please provide") + " " + fieldMessage);
    return false;
  };

  const weightUnity = ["kg", "t"];

  return isLoading ? (
    <div className="w-1/4">
      <Loading height={"45vh"} />
    </div>
  ) : (
    <section className="flex flex-col w-1/4 min-h-full">
      {/* Flux */}
      <FormGroup className="flex flex-col w-full mb-3">
        <Label>{t("Waste Category")}</Label>
        <div className="flex flex-col">
          <Input
            className="h-10 bg-white border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
            type="select"
            defaultValue={formData.contentCategory}
            onChange={(event) => handleFormChange(event, formData, setFormData)}
            name="contentCategory"
            secondfield="wasteName"
          >
            <option secondvalue="" value="">
              {t("Unspecified stream")}
            </option>
            {wasteNames.map((flux, index) => (
              <option
                value={flux._id}
                secondvalue={flux.label}
                key={`waste-name-${flux._id}`}
              >
                {flux.label}
              </option>
            ))}
          </Input>

          {formData.wasteName === "" || !formData.wasteName ? (
            <Link
              to={{
                pathname: "/waste",
                search: `?openCreate=true`,
              }}
              target="_blank"
              className="flex my-2 py-2.5 justify-center bg-cyan rounded text-white "
            >
              <div>{t("Add a new material stream")}</div>
            </Link>
          ) : (
            <Link
              to={{
                pathname: "/waste",
                search: `?wasteId=${formData.contentCategory}&openEdit=true`,
              }}
              target="_blank"
              className="flex my-2 py-2.5 justify-center bg-cyan rounded text-white "
            >
              <div>
                {t("Change the stream properties")} :
                <span className="mx-1">{formData.wasteName}</span>
              </div>
            </Link>
          )}
        </div>
      </FormGroup>

      {/*limitFillingLevel */}
      <FormGroup className="flex flex-col w-full mb-3">
        <label>{t("Critical")} (%) *</label>
        <Input
          className="bg-white border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2"
          type="number"
          defaultValue={formData.limitFillingLevel}
          placeholder="Seulement chiffres : 90​"
          onChange={(event) => {
            handleFormChange(event, formData, setFormData);
            isInputField(event, t("a critical fill level"));
          }}
          name="limitFillingLevel"
          max={100}
          min={0}
        />
      </FormGroup>

      {/* FullWeight */}
      <FormGroup className="flex flex-col w-full mb-3">
        <Label>{t("Target Weight")}</Label>
        <div className="flex w-full">
          <Input
            className="w-2/3 bg-white border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 "
            type="number"
            id="fullWeight"
            placeholder={`${t("Numbers only")} : 5.8`}
            defaultValue={formData.fullWeight ? formData.fullWeight : ""}
            name="fullWeight"
            onChange={(event) => handleFormChange(event, formData, setFormData)}
            step={0.01}
          />
          <Input
            className="w-1/3 ml-2 bg-white border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
            type="select"
            id="weightUnit"
            defaultValue={formData.weightUnit}
            onChange={(event) => handleFormChange(event, formData, setFormData)}
            name="weightUnit"
          >
            {weightUnity.map((unit, index) => {
              return (
                <option value={unit} key={`length-unit-${unit._id}`}>
                  {unit}
                </option>
              );
            })}
          </Input>
        </div>
      </FormGroup>

      {/* Packmat */}
      <FormGroup className="flex flex-col w-full mb-3">
        <div
          className="flex relative"
          onMouseEnter={() => setHelpPackmat(true)}
          onMouseLeave={() => setHelpPackmat(false)}
        >
          <Label>{t("Packmat tracking")}</Label>
          <i className="fas fa-info-circle text-grey text-sm mt-0 mx-2"></i>
          {helpPackmat && (
            <div className="absolute bottom-10 bg-yellow text-white p-2 rounded-lg shadow-lg z-10">
              {t("Activate the measurement and indicators of compaction")}
            </div>
          )}
        </div>
        <div className="flex justify-between w-1/3">
          <span className="text-sm">{t("no").toUpperCase()}</span>
          <CustomInput
            className="mx-2"
            type="switch"
            id="isPackmat"
            defaultChecked={formData.packmatActive}
            onClick={(event) => handleFormChange(event, formData, setFormData)}
            bsSize="lg"
            name="packmatActive"
          />
          <span className="text-sm">{t("yes").toUpperCase()}</span>
        </div>
      </FormGroup>

      {adminUserConnected && (
        <FormGroup className="flex flex-col w-full mb-3">
          <div className="flex relative">
            <Label>{t("Enable the characterizations option")}</Label>
          </div>
          <div className="flex justify-between w-1/3">
            <span className="text-sm">{t("no").toUpperCase()}</span>
            <CustomInput
              className="mx-2"
              type="switch"
              id="characterization"
              defaultChecked={formData.characterization}
              onClick={(event) =>
                handleFormChange(event, formData, setFormData)
              }
              bsSize="lg"
              name="characterization"
            />
            <span className="text-sm">{t("yes").toUpperCase()}</span>
          </div>
        </FormGroup>
      )}

      {/* Hide Filling Level */}
      {adminUserConnected && (
        <FormGroup className="flex flex-col w-full mb-3">
          <div
            className="flex relative"
            onMouseEnter={() => setHelpFillingLevel(true)}
            onMouseLeave={() => setHelpFillingLevel(false)}
          >
            <Label>{t("Show filling level")}</Label>
            <i className="fas fa-info-circle text-grey text-sm mt-0 mx-2"></i>
            {helpFillingLevel && (
              <div className="absolute bottom-10 bg-yellow text-white p-2 rounded-lg shadow-lg z-10">
                {t(
                  "Hide the filling level of the container throughout the client application",
                )}
              </div>
            )}
          </div>
          <div className="flex justify-between w-1/3">
            <span className="text-sm">{t("no").toUpperCase()}</span>
            <CustomInput
              className="mx-2"
              type="switch"
              id="isActivatedFillingLevelOption"
              defaultChecked={formData.activatedFillingLevelOption}
              onClick={(event) =>
                handleFormChange(event, formData, setFormData)
              }
              bsSize="lg"
              name="activatedFillingLevelOption"
            />
            <span className="text-sm">{t("yes").toUpperCase()}</span>
          </div>
        </FormGroup>
      )}

      {/* Show Depot Cycle and alert light */}
      {adminUserConnected && (
        <FormGroup className="flex flex-col w-full mb-3">
          <div
            className="flex relative"
            onMouseEnter={() => setHelpShowDepotCycle(true)}
            onMouseLeave={() => setHelpShowDepotCycle(false)}
          >
            <Label>{t("Use depot cycle")}</Label>
            <i className="fas fa-info-circle text-grey text-sm mt-0 mx-2"></i>
            {helpShowDepotCycle && (
              <div className="absolute bottom-10 bg-yellow text-white p-2 rounded-lg shadow-lg z-10">
                {t(
                  "Show a depot cycle with the first image in the history view and showing the images first to last",
                )}
              </div>
            )}
          </div>
          <div className="flex justify-between w-1/3">
            <span className="text-sm">{t("no").toUpperCase()}</span>
            <CustomInput
              className="mx-2"
              type="switch"
              id="isShowDepotCycle"
              defaultChecked={formData.showDepotCycle}
              onClick={(event) =>
                handleFormChange(event, formData, setFormData)
              }
              bsSize="lg"
              name="showDepotCycle"
            />
            <span className="text-sm">{t("yes").toUpperCase()}</span>
          </div>
        </FormGroup>
      )}
    </section>
  );
};
