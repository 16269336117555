import React, { useEffect, useState, useCallback } from "react";
import { CustomInput } from "reactstrap";
import Loading from "../../components/utils/Loading";
import HorizontalBarChart from "../../components/charts/HorizontalBarChart";
import { OutletPieChart } from "../../components/charts/OutletPieChart";
import { MaterialCountingPieChart } from "../../components/charts/MaterialCountingPieChart";
import axios from "axios";
import { formattedColorMaterial } from "../../components/formatted/FormattedText";
import { sortObjectsInArrayByKeyName } from "../../components/utils/Object";
import {
  formattedDate,
  formattedTime,
} from "../../components/formatted/FormattedDateAndTime";
import CharacterisationSvg from "../../components/utils/CharacterisationSvg";
import { toFrenchNumber } from "../../components/formatted/FormattedText";
import "react-toastify/dist/ReactToastify.css";
import { indexParentCategory, repDescriptions } from "../../shared/utils/utils";
import { ContainerViewMaterialForPDF } from "./ContainerViewMaterialForPDF";
import { getHistoryMaterial } from "../../services/container";
import { ImageCardComponent } from "../../components/imageCard/ImageCardComponent";
import { useTranslation } from "react-i18next";
import { PieChart } from "../../components/charts/PieChart";

const SuperContainerViewMaterial = ({
  data,
  expendImage,
  setExpendImage,
  setIsLoading,
  isLoading,
  multiPeriods,
  setMaterialDelivery,
  setHistoryMaterial,
  historyMaterial,
  setParentCategoriesList,
  parentCategoriesList,
  setCharacterizationInProgress,
  characterizationInProgress,
  setTotalWasteMass,
  totalWasteMass,
  setTotalWasteVolume,
  totalWasteVolume,
  setMaterialTotal,
  materialTotal,
  setTotalMassRecoverable,
  totalMassRecoverable,
  setTotalVolumeRecoverable,
  totalVolumeRecoverable,
  periodMaterialStart,
  periodMaterialStop,
  mergeMaterialFluxes,
  mergeSimilarMaterials,
  selectedNumberOfCycleMaterial,
  historyId,
  setHistoryId,
  setDisplayMassChart,
  displayMassChart,
  setDisplayVolumeChart,
  displayVolumeChart,
  displayOutletChart,
  setDisplayOutletChart,
  setDisplayNumberOfObjectChart,
  displayNumberOfObjectChart,
  setHelpVolumeRecoverable,
  helpVolumeRecoverable,
  totalWeight,
  tableRef,
  chartRef,
  volumeRef,
  materialRef,
  setHelpMassRecoverable,
  helpMassRecoverable,
  setToogleMassChart,
  toogleMassChart,
  toogleOutletChart,
  setToogleOutletChart,
  superContainer,
  containers,
  caracterisationData,
  setCaracterisationData,
  setLoadWeight,
  formatOutletData,
  outletData,
  setOutletData,
  outletRef,
  tableOutletRef,
  displayRepChart,
  setDisplayRepChart,
  toogleRepChart,
  setToogleRepChart,
  repData,
  setRepData,
  chartRepRef,
  tableRepRef,
  characterizationType,
  setShowData,
}) => {
  const { t, i18n } = useTranslation();

  const [labelMaterial, setLabelMaterial] = useState([]);
  const [labelMaterialSeparate, setLabelMaterialSeparate] = useState([]);
  const [colorMaterial, setColorMaterial] = useState([]);
  const [dataVolumeMaterial, setDataVolumeMaterial] = useState([]);
  const [dataNumberOfMaterial, setDataNumberOfMaterial] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const [dataMassMaterial, setDataMassMaterial] = useState(0);
  const [proportionSurface, setProportionSurface] = useState([]);

  const fetchCaracterisationData = useCallback(
    () => {
      setDisplayMassChart(true);
      setDisplayVolumeChart(false);
      setDisplayNumberOfObjectChart(false);
      setDisplayOutletChart(false);
      setDisplayRepChart(false);
      let selectedMultiPeriods = [];
      if (
        multiPeriods?.selectedList &&
        multiPeriods?.selectedList.length > 0 &&
        multiPeriods?.selectedList.some(
          (value) => value !== undefined && value !== null && value !== "",
        )
      ) {
        selectedMultiPeriods = multiPeriods.selectedList;
        const handler = setTimeout(() => {
          setDataIsReady(false);
          setIsLoading(true);
          setShowData(false);
          setLoadWeight(true);
          let datas = [];
          containers.forEach(async (container) => {
            let characterization;
            const requests = selectedMultiPeriods.map((selectedPeriod) => {
              return getHistoryMaterial(
                {
                  periodMaterialStart:
                    selectedPeriod.periodMaterialStartSubfilter !== 0
                      ? selectedPeriod.periodMaterialStartSubfilter
                      : periodMaterialStart,
                  periodMaterialStop:
                    selectedPeriod.periodMaterialStopSubfilter !== 0
                      ? selectedPeriod.periodMaterialStopSubfilter
                      : periodMaterialStop,
                  customDate:
                    selectedPeriod.periodMaterialStopSubfilter !== 0
                      ? true
                      : false,
                  language: i18n.language,
                },
                container._id,
              );
            });

            await axios.all(requests).then(
              axios.spread((...response) => {
                if (response[0].status === 200) {
                  const _ = require("lodash");

                  function multiPeriodCaracterisation(response) {
                    let materialDelivery = [];
                    for (let i = 0; i < selectedMultiPeriods.length; i++) {
                      for (
                        let j = 0;
                        j < response[i].data.materialDelivery.length;
                        j++
                      ) {
                        materialDelivery.push(
                          response[i].data.materialDelivery[j],
                        );
                      }
                    }
                    return [
                      ...materialDelivery
                        .reduce((r, o) => {
                          const key = o.cls;
                          const item =
                            r.get(key) ||
                            Object.assign({}, o, {
                              count: 0,
                              massObject: 0,
                              volumeObject: 0,
                              materialTotal: 0,
                              totalMassRecoverable: 0,
                              totalVolumeRecoverable: 0,
                              recoverable: false,
                            });
                          item.count += +o.count;
                          item.massObject += +o.massObject;
                          item.volumeObject += +o.volumeObject;
                          item.materialTotal += +o.materialTotal;
                          item.totalMassRecoverable += +o.totalMassRecoverable;
                          item.totalVolumeRecoverable +=
                            +o.totalVolumeRecoverable;
                          item.recoverable = o.recoverable;

                          return r.set(key, item);
                        }, new Map())
                        .values(),
                    ];
                  }

                  const mergeConfig = JSON.parse(
                    localStorage.getItem("materialsDescription"),
                  ).map((v) => ({
                    referenceName: v.referenceName,
                    possibleNames: v.possibleNames,
                  }));

                  const concatenatedMaterials =
                    multiPeriodCaracterisation(response);
                  const mergedFluxes = mergeMaterialFluxes(
                    concatenatedMaterials,
                    mergeConfig,
                  );
                  const sortedFluxes = sortObjectsInArrayByKeyName(
                    mergedFluxes,
                    "cls",
                  );

                  const parentCategories = mergeSimilarMaterials(
                    _.map(response, function (o) {
                      return o.data.parentCategoriesList;
                    }).flat(),
                  );

                  const newOutletData = _.map(response, function (o) {
                    return formatOutletData(o.data?.outletData, i18n.language);
                  });
                  const mergedOutletData = newOutletData.reduce(
                    (acc, current) => {
                      if (!acc.labels) {
                        acc.labels = [...current.labels];
                      }
                      if (!acc.data) {
                        acc.data = [...current.data];
                      } else {
                        acc.data = acc.data.map(
                          (value, index) => value + current.data[index],
                        );
                      }
                      acc.sum = (acc.sum || 0) + current.sum;

                      return acc;
                    },
                    {},
                  );

                  const newRepData = _.map(response, function (o) {
                    return o.data.repData;
                  }).flat();

                  const mergedRep = newRepData.reduce(
                    (acc, item) => {
                      acc.volumeTotal += item.volumeTotal;
                      acc.massTotal += item.massTotal;
                      Object.entries(item.data).forEach(([rep, values]) => {
                        const repKey = t(repDescriptions[rep]?.category) || rep;

                        if (!acc.data[repKey]) {
                          acc.data[repKey] = {
                            massObject: 0,
                            volumeObject: 0,
                            objects: [],
                          };
                        }

                        acc.data[repKey].massObject += values.massObject;
                        acc.data[repKey].volumeObject += values.volumeObject;
                        acc.data[repKey].objects = [
                          ...new Set([
                            ...acc.data[repKey].objects,
                            ...values.objects,
                          ]),
                        ];
                      });

                      return acc;
                    },
                    {
                      volumeTotal: 0,
                      massTotal: 0,
                      data: {
                        [t("PMCB Category 1")]: {
                          massObject: 0,
                          volumeObject: 0,
                          objects: [],
                        },
                        [t("PMCB Category 2")]: {
                          massObject: 0,
                          volumeObject: 0,
                          objects: [],
                        },
                      },
                    },
                  );

                  // HistoryMaterial
                  setHistoryMaterial(
                    sortObjectsInArrayByKeyName(
                      _.map(response, function (o) {
                        return o.data.historyMaterial;
                      }).flat(1),
                      "_time",
                      "descending",
                    ),
                  );

                  let booleanChecker = (arr) => arr.every(Boolean);
                  setCharacterizationInProgress(
                    booleanChecker(
                      _.map(response, function (o) {
                        return o.data.ongoingCharacterization;
                      }),
                    ),
                  );

                  const totalMass = _.sumBy(response, function (o) {
                    return o.data.totalWasteMass;
                  });
                  const totalVolume = _.sumBy(response, function (o) {
                    return o.data.totalWasteVolume;
                  });
                  const massMaterial = [
                    ...sortedFluxes.map((material) => {
                      return {
                        [material.cls]: (material.massObject / totalMass) * 100,
                      };
                    }),
                  ];
                  characterization = {
                    containerId: container._id,
                    materialDelivery: sortedFluxes,
                    parentCategoriesList: parentCategories.sort(
                      (a, b) =>
                        indexParentCategory[a.parentCategory] -
                        indexParentCategory[b.parentCategory],
                    ),
                    historyMaterial: sortObjectsInArrayByKeyName(
                      _.map(response, function (o) {
                        return o.data.historyMaterial;
                      }).flat(1),
                      "_time",
                      "descending",
                    ),
                    characterizationInProgress: booleanChecker(
                      _.map(response, function (o) {
                        return o.data.ongoingCharacterization;
                      }),
                    ),
                    totalWasteMass: totalMass,
                    totalWasteVolume: totalVolume,
                    dataMassMaterial: massMaterial,
                    materialTotal: _.sumBy(response, function (o) {
                      return o.data.materialTotal;
                    }),
                    totalMassRecoverable: _.sumBy(response, function (o) {
                      return o.data.totalMassRecoverable;
                    }),
                    totalVolumeRecoverable: _.sumBy(response, function (o) {
                      return o.data.totalVolumeRecoverable;
                    }),
                    outletData: mergedOutletData,
                    repData: mergedRep,
                  };
                  datas = [...datas, characterization];
                }
              }),
            );
            setCaracterisationData(datas);
          });
          return () => clearTimeout(handler);
        }, 2000);
      } else {
        setHistoryMaterial([]);
        setParentCategoriesList([]);
        setCharacterizationInProgress(false);
        setTotalWasteMass(0);
        setTotalWasteVolume(0);
        setMaterialTotal(0);
        setTotalMassRecoverable(0);
        setTotalVolumeRecoverable(0);
        setOutletData({});
        setCaracterisationData([]);
        setIsLoading(false);
        setShowData(true);
        setDataIsReady(false);
      }
    },
    // eslint-disable-next-line
    [multiPeriods, i18n.language],
  );

  // Fetch characterization data
  useEffect(
    () => {
      fetchCaracterisationData();
    },
    // eslint-disable-next-line
    [fetchCaracterisationData],
  );

  useEffect(
    () => {
      const compare = (a, b) => {
        const indexA = containers.findIndex((obj) => obj._id === a.containerId);
        const indexB = containers.findIndex((obj) => obj._id === b.containerId);
        return indexA - indexB;
      };
      // Sort caracterisationData to ensure the info is in the same order as containers
      caracterisationData.sort(compare);

      if (caracterisationData.length > 1) {
        const yellow_surface_to_reference_ratio0 =
          caracterisationData[0]?.historyMaterial[0]
            ?.yellow_surface_to_reference_ratio ?? 0;
        const yellow_surface_to_reference_ratio1 =
          caracterisationData[1]?.historyMaterial[0]
            ?.yellow_surface_to_reference_ratio ?? 0;

        const total =
          yellow_surface_to_reference_ratio0 +
          yellow_surface_to_reference_ratio1;

        const proportion =
          total > 0
            ? [
                yellow_surface_to_reference_ratio0 / total,
                yellow_surface_to_reference_ratio1 / total,
              ]
            : [0, 0];

        setProportionSurface(proportion);
        const mergedMaterial = [];
        caracterisationData.forEach((data, index) => {
          data.materialDelivery.forEach((item) => {
            mergedMaterial.push({
              ...item,
              id: index,
            });
          });
        });
        const groupedMaterial = mergedMaterial.reduce((acc, obj) => {
          const key = obj.cls;
          if (!acc[key]) {
            acc[key] = {
              surface: obj.surface * proportion[obj.id],
              count: obj.count,
              volumeObject: obj.volumeObject * proportion[obj.id],
              massObject: obj.massObject * proportion[obj.id],
              recoverable: obj.recoverable,
              cls: obj.cls,
            };
          } else {
            acc[key].surface += obj.surface * proportion[obj.id];
            acc[key].count += obj.count;
            acc[key].volumeObject += obj.volumeObject * proportion[obj.id];
            acc[key].massObject += obj.massObject * proportion[obj.id];
            acc[key].recoverable = acc[key].recoverable && obj.recoverable;
          }
          return acc;
        }, {});
        caracterisationData[0].parentCategoriesList.forEach((material) => {
          material.massObjects = material.massObjects * proportion[0];
        });
        caracterisationData[1].parentCategoriesList.forEach((material) => {
          material.massObjects = material.massObjects * proportion[1];
        });
        caracterisationData[0].parentCategoriesList.forEach((material) => {
          material.massInOutlet = material.massInOutlet * proportion[0];
        });
        caracterisationData[1].parentCategoriesList.forEach((material) => {
          material.massInOutlet = material.massInOutlet * proportion[1];
        });
        caracterisationData[0].parentCategoriesList.forEach((material) => {
          material.massNotInOutlet = material.massNotInOutlet * proportion[0];
        });
        caracterisationData[1].parentCategoriesList.forEach((material) => {
          material.massNotInOutlet = material.massNotInOutlet * proportion[1];
        });

        const mergedParentCategoriesList = [
          ...caracterisationData[0].parentCategoriesList,
          ...caracterisationData[1].parentCategoriesList,
        ];
        const groupedParentCategories = mergedParentCategoriesList.reduce(
          (acc, obj) => {
            const key = obj.parentCategory;
            const existingItem = acc.find((item) => item.key === key);
            if (!existingItem) {
              acc.push({
                key,
                ...obj,
                uniqueCategories: new Set(obj.categoryList),
              });
            } else {
              existingItem.massObjects += obj.massObjects;
              existingItem.massInOutlet += obj.massInOutlet;
              existingItem.massNotInOutlet += obj.massNotInOutlet;
              obj.categoryList.forEach((category) => {
                if (!existingItem.uniqueCategories.has(category)) {
                  existingItem.categoryList.push(category);
                  existingItem.uniqueCategories.add(category);
                }
              });
            }
            return acc;
          },
          [],
        );
        const totalMass =
          caracterisationData[0].totalWasteMass * proportion[0] +
          caracterisationData[1].totalWasteMass * proportion[1];
        const totalVolume =
          caracterisationData[0].totalWasteVolume * proportion[0] +
          caracterisationData[1].totalWasteVolume * proportion[1];
        const mergedTotalMassRecoverable =
          caracterisationData[0].totalMassRecoverable * proportion[0] +
          caracterisationData[1].totalMassRecoverable * proportion[1];
        const mergedTotalVolumeRecoverable =
          caracterisationData[0].totalVolumeRecoverable * proportion[0] +
          caracterisationData[1].totalVolumeRecoverable * proportion[1];

        const mergedOutletData = [
          caracterisationData[0].outletData,
          caracterisationData[1].outletData,
        ].reduce((acc, current) => {
          if (!acc.labels) {
            acc.labels = [...current.labels];
          }
          if (!acc.data) {
            acc.data = [...current.data];
          } else {
            acc.data = acc.data.map(
              (value, index) =>
                value * proportion[0] + current.data[index] * proportion[1],
            );
          }
          acc.sum =
            (acc.sum * proportion[0] || 0) + current.sum * proportion[1];

          return acc;
        }, {});
        setOutletData(mergedOutletData);

        const mergedRep = [
          caracterisationData[0].repData,
          caracterisationData[1].repData,
        ].reduce(
          (acc, item, index) => {
            acc.volumeTotal += item.volumeTotal * proportion[index];
            acc.massTotal += item.massTotal * proportion[index];

            Object.entries(item.data).forEach(([rep, values]) => {
              const key = rep;

              if (!acc.data[key]) {
                acc.data[key] = { massObject: 0, volumeObject: 0, objects: [] };
              }

              acc.data[key].massObject += values.massObject * proportion[index];
              acc.data[key].volumeObject +=
                values.volumeObject * proportion[index];

              acc.data[key].objects = [
                ...new Set([
                  ...acc.data[key].objects,
                  ...(Array.isArray(values.objects) ? values.objects : []),
                ]),
              ];
            });

            return acc;
          },
          {
            volumeTotal: 0,
            massTotal: 0,
            data: {
              [t("PMCB Category 1")]: {
                massObject: 0,
                volumeObject: 0,
                objects: [],
              },
              [t("PMCB Category 2")]: {
                massObject: 0,
                volumeObject: 0,
                objects: [],
              },
            },
          },
        );

        const repChartLabels = Object.keys(mergedRep.data).map((key) => key);

        const repChartData = Object.keys(mergedRep.data)
          .filter((key) => mergedRep.data[key].volumeObject > 0)
          .map((key) => ({
            name: key,
            value: (
              (mergedRep.data[key].volumeObject / mergedRep.volumeTotal) *
              100
            ).toFixed(1),
          }));
        setRepData({
          data: mergedRep.data,
          repChartData: repChartData,
          repChartLabels: repChartLabels,
          volumeTotal: mergedRep.volumeTotal,
        });

        const result = Object.values(groupedMaterial);
        const sortedFluxes = sortObjectsInArrayByKeyName(result, "cls");

        const mass = [
          ...sortedFluxes.map(
            (material) => (material.massObject / totalMass) * 100,
          ),
        ];
        const volume = [
          ...sortedFluxes.map(
            (material) => (material.volumeObject / totalVolume) * 100,
          ),
        ];
        const formattedLabelsSeparate = sortedFluxes.map((material) =>
          t(material.cls),
        );
        const materialCounts = sortedFluxes.map((material) => material.count);

        const formattedColors = sortedFluxes.map((material) =>
          formattedColorMaterial(material.cls),
        );
        const mergedMaterialTotal =
          caracterisationData[0].materialTotal +
          caracterisationData[1].materialTotal;

        setMaterialDelivery(sortedFluxes);
        setLabelMaterialSeparate(formattedLabelsSeparate);
        setLabelMaterial(formattedLabelsSeparate);
        setMaterialTotal(mergedMaterialTotal);
        setColorMaterial(formattedColors);
        setDataNumberOfMaterial(materialCounts);
        setDataMassMaterial(mass);
        setDataVolumeMaterial(volume);
        setParentCategoriesList(groupedParentCategories);
        setTotalWasteMass(totalMass);
        setTotalWasteVolume(totalVolume);
        setTotalMassRecoverable(mergedTotalMassRecoverable);
        setTotalVolumeRecoverable(mergedTotalVolumeRecoverable);
        setDataIsReady(true);
        setIsLoading(false);
        setShowData(true);
      }
    },
    // eslint-disable-next-line
    [
      caracterisationData,
      containers,
      setIsLoading,
      setMaterialTotal,
      setParentCategoriesList,
      setTotalMassRecoverable,
      setTotalVolumeRecoverable,
      setTotalWasteMass,
      setTotalWasteVolume,
      setMaterialDelivery,
      setOutletData,
      setRepData,
      i18n.language,
      setShowData,
    ],
  );

  return (
    <>
      {isLoading ? (
        <Loading color="#efefeffb" />
      ) : (
        <div className="flex flex-col">
          {/* Keys & Graphs & Image */}
          <section
            className={`flex flex-col xl:flex-row 2xl:flex-row bg-white rounded-md`}
            style={{ height: Object.keys(multiPeriods).length === 0 && 500 }}
          >
            {/* Image */}
            <section className="w-full flex flex-row">
              <section
                className={`${expendImage ? "hidden" : "absolute mt-3 ml-2 modal-item-key-value justify-initial"}`}
              >
                <CharacterisationSvg
                  width={53}
                  height={63}
                  widthViewBox={22}
                  heightViewBox={28}
                />
                <h5 className="font-size-50">
                  {selectedNumberOfCycleMaterial}
                </h5>
                <span className="flex flex-col m-auto text-blue text-base font-semibold ml-1">
                  {t("Characterization")}
                  {selectedNumberOfCycleMaterial > 1 ? "s" : ""}
                </span>
              </section>
              <div className="w-full">
                {historyMaterial.length > 0 && (
                  <ImageCardComponent
                    container={data}
                    displayLegend={false}
                    expendImage={expendImage}
                    setExpendImage={setExpendImage}
                    history={historyMaterial}
                    historyId={historyId}
                    setHistoryId={setHistoryId}
                    isCharacterization={true}
                    containers={containers}
                    superContainer={superContainer}
                    proportion={proportionSurface}
                    displayAllCountours={true}
                  />
                )}
              </div>
            </section>
          </section>
          {dataIsReady && (
            <>
              {/* Charts */}
              <section
                className={`${expendImage ? "hidden" : "flex flex-col w-full xl:w-1/2 2xl:w-1/2 bg-white px-2 my-3 relative"} rounded-md`}
              >
                {/* Navigation Charts */}
                <div className="mt-2 text-cyan">
                  <span
                    className={`mx-1 px-2 cursor-pointer ${displayMassChart ? "bg-inherit border-solid border-cyan border-b-2" : `bg-gainsboro rounded ${!characterizationInProgress ? "hover:bg-cyan hover:text-gainsboro" : "text-cyan-transparent bg-gainsboro-transparent"}`}`}
                    onClick={
                      !characterizationInProgress
                        ? () => {
                            setDisplayMassChart(true);
                            setDisplayVolumeChart(false);
                            setDisplayNumberOfObjectChart(false);
                            setDisplayOutletChart(false);
                            setDisplayRepChart(false);
                          }
                        : null
                    }
                  >
                    {t("Mass")}
                  </span>
                  <span
                    className={`mx-1 px-2 cursor-pointer ${displayOutletChart ? `bg-inherit border-solid border-cyan border-b-2 ` : `bg-gainsboro rounded ${!characterizationInProgress ? "hover:bg-cyan hover:text-gainsboro" : "text-cyan-transparent bg-gainsboro-transparent"}`} `}
                    onClick={() => {
                      setDisplayMassChart(false);
                      setDisplayVolumeChart(false);
                      setDisplayNumberOfObjectChart(false);
                      setDisplayOutletChart(true);
                      setDisplayRepChart(false);
                    }}
                  >
                    Filière{/* Filière / Exutoire */}
                  </span>
                  <span
                    className={`mx-1 px-2 cursor-pointer ${displayNumberOfObjectChart ? `bg-inherit border-solid border-cyan border-b-2 ${characterizationInProgress ? "text-cyan-transparent border-cyan-transparent " : null}` : `bg-gainsboro rounded ${!characterizationInProgress ? "hover:bg-cyan hover:text-gainsboro" : "text-cyan-transparent bg-gainsboro-transparent"}`}`}
                    onClick={
                      !characterizationInProgress
                        ? () => {
                            setDisplayMassChart(false);
                            setDisplayVolumeChart(false);
                            setDisplayNumberOfObjectChart(true);
                            setDisplayOutletChart(false);
                            setDisplayRepChart(false);
                          }
                        : null
                    }
                  >
                    Nb. {t("Materials")}
                  </span>
                  <span
                    className={`mx-1 px-2 cursor-pointer ${displayVolumeChart ? `bg-inherit border-solid border-cyan border-b-2 ` : `bg-gainsboro rounded ${!characterizationInProgress ? "hover:bg-cyan hover:text-gainsboro" : "text-cyan-transparent bg-gainsboro-transparent"}`} `}
                    onClick={() => {
                      setDisplayMassChart(false);
                      setDisplayVolumeChart(true);
                      setDisplayNumberOfObjectChart(false);
                      setDisplayOutletChart(false);
                      setDisplayRepChart(false);
                    }}
                  >
                    Volume
                  </span>
                  {characterizationType === "EPR" && (
                    <span
                      className={`mx-1 px-2 cursor-pointer ${displayRepChart ? `bg-inherit border-solid border-cyan border-b-2 ` : `bg-gainsboro rounded ${!characterizationInProgress ? "hover:bg-cyan hover:text-gainsboro" : "text-cyan-transparent bg-gainsboro-transparent"}`} `}
                      onClick={() => {
                        setDisplayMassChart(false);
                        setDisplayVolumeChart(false);
                        setDisplayNumberOfObjectChart(false);
                        setDisplayOutletChart(false);
                        setDisplayRepChart(true);
                      }}
                    >
                      {t("EPR")}
                    </span>
                  )}
                </div>
                {/* Charts */}
                <div className={`${characterizationInProgress && "blur"} `}>
                  {/* Keys */}
                  <div className={`modal-item-container-title `}>
                    {/* Volume Chart Key */}
                    {displayVolumeChart && (
                      <div className="flex justify-between w-full">
                        <article className="modal-item-key-value" />
                        <article className="modal-item-key-value relative">
                          <i className="fas fa-sync-alt font-size-35 text-gray-500 mr-3 mt-2"></i>
                          <h5 className="font-size-35">
                            {totalVolumeRecoverable > 0
                              ? toFrenchNumber(
                                  (totalVolumeRecoverable / totalWasteVolume) *
                                    100,
                                  1,
                                )
                              : "0,0"}
                            %
                          </h5>
                          <article className="flex flex-col text-blue font-semibold my-auto mx-2">
                            {i18n.language === "fr" ? (
                              <>
                                <span>de matières</span>
                                <span>valorisables</span>
                              </>
                            ) : (
                              <>
                                <span>of recoverable</span>
                                <span>materials</span>
                              </>
                            )}
                          </article>
                          <i
                            className="fas fa-info-circle"
                            onMouseOver={() => setHelpVolumeRecoverable(true)}
                            onMouseOut={() => setHelpVolumeRecoverable(false)}
                          ></i>
                          {helpVolumeRecoverable ? (
                            <article className="modal-item-key-help">
                              {t(
                                "Volumetric proportion of recoverable materials",
                              )}
                              .
                            </article>
                          ) : null}
                        </article>
                      </div>
                    )}

                    {/* Mass Chart Key */}
                    {displayMassChart && (
                      <div className="flex flex-end w-full">
                        <article className="flex flex-col">
                          <article className="modal-item-key-value">
                            <i
                              style={{ marginTop: 9 }}
                              className="fas fa-sync-alt font-size-35 text-gray-500 mr-3 mt-2"
                            ></i>
                            <h5 style={{ fontSize: 35 }}>
                              {totalMassRecoverable > 0
                                ? toFrenchNumber(
                                    (totalMassRecoverable / totalWasteMass) *
                                      100,
                                    1,
                                  )
                                : "0,0"}
                              %
                            </h5>
                            <article className="flex flex-col text-blue font-semibold my-auto mx-2">
                              {i18n.language === "fr" ? (
                                <>
                                  <span>de matières</span>
                                  <span>valorisables</span>
                                </>
                              ) : (
                                <>
                                  <span>of recoverable</span>
                                  <span>materials</span>
                                </>
                              )}
                            </article>
                            <i
                              className="fas fa-info-circle"
                              onMouseOver={() => setHelpMassRecoverable(true)}
                              onMouseOut={() => setHelpMassRecoverable(false)}
                            ></i>

                            {helpMassRecoverable && (
                              <article className="modal-item-key-help">
                                {t("Mass proportion of recoverable materials")}.
                              </article>
                            )}
                          </article>
                        </article>
                      </div>
                    )}
                  </div>

                  {/* Volume Chart */}
                  {displayVolumeChart && (
                    <div className="mt-16" style={{ height: "480px" }}>
                      <HorizontalBarChart
                        dataChart={dataVolumeMaterial}
                        labelsChart={labelMaterial}
                        unitValueChart="%"
                      />
                    </div>
                  )}

                  {/* Mass Chart */}
                  {displayMassChart && (
                    <div className="mt-4">
                      {characterizationType !== "RHW" && (
                        <div className="flex flex-row">
                          {/* Toogle Mass Chart */}
                          <div className="flex justify-end w-full px-2 mt-2">
                            <span className="text-sm">{t("Chart")}</span>
                            <CustomInput
                              className="mx-4"
                              type="switch"
                              id="toogleMassChart"
                              defaultChecked={toogleMassChart}
                              onClick={() =>
                                setToogleMassChart(!toogleMassChart)
                              }
                              bsSize="lg"
                              name="hazardous"
                            />
                            <span className="text-sm">{t("Table")}</span>
                          </div>
                        </div>
                      )}

                      {toogleMassChart && characterizationType !== "RHW" ? (
                        // Mass Chart Parent category
                        <section className="flexx flex-col my-2">
                          <div className="flex justify-end text-cyan">
                            <div className="w-1/5 px-2 mx-1 rounded-md text-center font-semibold">
                              {t("Mass")} (kg)
                            </div>
                            <div className="w-1/5 px-2 rounded-md text-center font-semibold">
                              % {t("mass")}
                            </div>
                          </div>

                          {parentCategoriesList.map(
                            (parentCategory, indexParentCategory) => {
                              return (
                                <div
                                  key={`parentcat-${indexParentCategory}`}
                                  className="flex justify-between text-grey"
                                >
                                  <div className="flex flex-col w-3/5 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                                    <div className="font-semibold">
                                      {parentCategory.parentCategory.toUpperCase()}
                                    </div>
                                    <div className="flex text-xs">
                                      <div>
                                        {parentCategory.categoryList
                                          .sort()
                                          .join(", ")}{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex font-semibold justify-center items-center w-1/5 bg-white-modal px-2 py-2.5 m-1 rounded-sm ">
                                    {totalWeight !== ""
                                      ? toFrenchNumber(
                                          (parentCategory.massObjects /
                                            totalWasteMass) *
                                            (totalWeight * 1000),
                                          1,
                                        )
                                      : "-"}
                                  </div>
                                  <div className="flex font-semibold justify-center items-center text-center w-1/5 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                                    {totalWasteMass > 0
                                      ? toFrenchNumber(
                                          (parentCategory.massObjects /
                                            totalWasteMass) *
                                            100,
                                          1,
                                        )
                                      : "0,0"}
                                    %
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </section>
                      ) : (
                        // Mass Chart Child category
                        <div className="my-3" style={{ height: "480px" }}>
                          <HorizontalBarChart
                            dataChart={dataMassMaterial}
                            labelsChart={labelMaterial}
                            unitValueChart="%"
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {/* Outlet Chart */}
                  {displayOutletChart && (
                    <div className="mt-4">
                      <div className="flex flex-row">
                        {/* Toogle Mass Chart */}
                        <div className="flex justify-end w-full px-2 mt-2">
                          <span className="text-sm">{t("Table")}</span>
                          <CustomInput
                            className="mx-4"
                            type="switch"
                            id="toogleOutletChart"
                            defaultChecked={toogleOutletChart}
                            onClick={() =>
                              setToogleOutletChart(!toogleOutletChart)
                            }
                            bsSize="lg"
                            name="hazardous"
                          />
                          <span className="text-sm">{t("Chart")}</span>
                        </div>
                      </div>

                      {!toogleOutletChart ? (
                        // Mass Chart Parent category
                        <section className="flexx flex-col my-2">
                          <div className="flex justify-end text-cyan">
                            <div className="w-1/3 px-2 mx-1 rounded-md text-center font-semibold">
                              Avec filière
                            </div>
                            <div className="w-1/3 px-2 rounded-md text-center font-semibold">
                              Sans filière
                            </div>
                          </div>

                          {parentCategoriesList.map(
                            (parentCategory, indexParentCategory) => {
                              return (
                                <div
                                  key={`parentcat-${indexParentCategory}`}
                                  className="flex justify-between text-grey"
                                >
                                  <div className="flex flex-col w-1/3 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                                    <div className="font-semibold">
                                      {parentCategory.parentCategory.toUpperCase()}
                                    </div>
                                    <div className="flex text-xs">
                                      <div>
                                        {parentCategory.categoryList
                                          .sort()
                                          .join(", ")}{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex font-semibold justify-center items-center w-1/3 bg-white-modal px-2 py-2.5 m-1 rounded-sm ">
                                    <span>
                                      {totalWeight !== ""
                                        ? toFrenchNumber(
                                            (parentCategory.massInOutlet /
                                              totalWasteMass) *
                                              (totalWeight * 1000),
                                            1,
                                          )
                                        : "-"}{" "}
                                      {totalWeight !== "" && "kg"}
                                    </span>
                                    <span className="mx-2">/</span>
                                    <span>
                                      {parentCategory.massInOutlet > 0
                                        ? toFrenchNumber(
                                            (parentCategory.massInOutlet /
                                              totalWasteMass) *
                                              100,
                                            1,
                                          )
                                        : "0,0"}
                                      %
                                    </span>
                                  </div>
                                  <div className="flex font-semibold justify-center items-center text-center w-1/3 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                                    <span>
                                      {totalWeight !== ""
                                        ? toFrenchNumber(
                                            (parentCategory.massNotInOutlet /
                                              totalWasteMass) *
                                              (totalWeight * 1000),
                                            1,
                                          )
                                        : "-"}{" "}
                                      {totalWeight !== "" && "kg"}
                                    </span>
                                    <span className="mx-2">/</span>
                                    <span>
                                      {parentCategory.massNotInOutlet > 0
                                        ? toFrenchNumber(
                                            (parentCategory.massNotInOutlet /
                                              totalWasteMass) *
                                              100,
                                            1,
                                          )
                                        : "0,0"}
                                      %
                                    </span>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </section>
                      ) : (
                        <div className="my-3" style={{ height: "480px" }}>
                          {outletData?.data && (
                            <OutletPieChart
                              dataMaterialChart={outletData.data}
                              labelChart={outletData.labels}
                              materialTotal={totalWasteMass}
                              colorChart={[
                                "#f58142",
                                "#42f55d",
                                "#bf42f5",
                                "#2ab5b8",
                              ]}
                              fontSizeLabel={"0.8rem"}
                              totalWeight={totalWeight}
                              size={"100%"}
                              padding={null}
                              marginLeft={-30}
                              childrenValueFontSize={"1.8rem"}
                              childrenTextFontSize={"1rem"}
                              radius={"55%"}
                              space={30}
                              pdf={false}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {/* REP Chart */}
                  {displayRepChart &&
                    characterizationType === "EPR" &&
                    repData?.data && (
                      <div className="mt-4">
                        <div
                          className={`flex ${toogleRepChart ? "justify-between" : "flex-row"}`}
                        >
                          {toogleRepChart && (
                            <div className="flex justify-end text-blue font-semibold">
                              <span className="mt-2">
                                {t("Volume distribution by EPR")}
                              </span>
                            </div>
                          )}
                          <div
                            className={`flex justify-end px-2 mt-2 ${!toogleRepChart ? "w-full" : ""}`}
                          >
                            <span className="text-sm">{t("Table")}</span>
                            <CustomInput
                              className="mx-4"
                              type="switch"
                              id="toogleRepChart"
                              defaultChecked={toogleRepChart}
                              onClick={() => setToogleRepChart(!toogleRepChart)}
                              bsSize="lg"
                              name="rep"
                            />
                            <span className="text-sm">{t("Chart")}</span>
                          </div>
                        </div>

                        {!toogleRepChart ? (
                          <section className="flexx flex-col my-2">
                            <div className="flex justify-end text-cyan">
                              <div className="w-3/5 px-2 mx-1 rounded-md text-center font-semibold">
                                {t("Associated EPR")}
                              </div>
                              <div className="w-1/5 px-2 mx-1 rounded-md text-center font-semibold">
                                % {t("mass")}
                              </div>
                              <div className="w-1/5 px-2 rounded-md text-center font-semibold">
                                {t("Mass")} (kg)
                              </div>
                            </div>
                            {Object.keys(repData.data)
                              .sort()
                              .map((key, index) => {
                                return (
                                  <div
                                    key={`parentcat-${index}`}
                                    className="flex justify-between text-grey gap-1"
                                  >
                                    <div className="flex flex-col w-3/5 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                                      <div className="font-semibold">
                                        {t(key)}
                                      </div>
                                      <div className="flex text-xs">
                                        <div>
                                          {repData.data[key]?.objects &&
                                          repData.data[key]?.objects.length
                                            ? repData.data[key].objects
                                                .map((material) => t(material))
                                                .sort()
                                                .join(", ")
                                            : ""}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex font-semibold justify-center items-center w-1/5 bg-white-modal px-2 py-2.5 m-1 rounded-sm ">
                                      <span>
                                        {repData.data[key]?.massObject
                                          ? toFrenchNumber(
                                              (repData.data[key].massObject /
                                                totalWasteMass) *
                                                100,
                                              1,
                                            )
                                          : 0}
                                        %
                                      </span>
                                    </div>
                                    <div className="flex font-semibold justify-center items-center w-1/5 bg-white-modal px-2 py-2.5 m-1 rounded-sm ">
                                      <span>
                                        {totalWeight !== "" &&
                                        repData.data[key]?.massObject
                                          ? toFrenchNumber(
                                              (repData.data[key].massObject /
                                                totalWasteMass) *
                                                (totalWeight * 1000),
                                              1,
                                            )
                                          : "-"}{" "}
                                        {totalWeight !== "" && "kg"}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                          </section>
                        ) : (
                          <div className="my-3" style={{ height: "480px" }}>
                            {repData?.repChartData && (
                              <PieChart
                                dataChart={repData.repChartData}
                                height={"100%"}
                                width={"100%"}
                                fontSizeLabel={"0.8rem"}
                                legend={false}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}

                  {/* Number of Objects Chart */}
                  {displayNumberOfObjectChart && (
                    <div
                      className="flex mx-auto justify-center relative w-full"
                      style={{ height: "80vh" }}
                    >
                      {materialTotal !== undefined && materialTotal !== 0 && (
                        <MaterialCountingPieChart
                          dataChart={dataNumberOfMaterial}
                          labelChart={labelMaterialSeparate}
                          materialTotal={materialTotal}
                          colorChart={colorMaterial}
                          showMaterialTotal={true}
                        />
                      )}
                    </div>
                  )}
                </div>

                {/* No Characterization */}
                {characterizationInProgress &&
                  selectedNumberOfCycleMaterial > 0 && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-2/3 bg-white px-4 py-2 shadow-sm-2 rounded-md">
                        {/* Progressive Bar */}
                        <div className="flex my-3 relative">
                          <div className="relative w-full border-solid border-gainsboro border-b-2 border-t-2 mr-4">
                            <i className="fas fa-check-circle absolute -top-3 -left-1 w-8 text-3xl text-cyan"></i>
                            <div className="bg-gainsboro py-1.5 w-4/5 rounded-md"></div>
                          </div>
                          <i className="absolute fas fa-history -top-2.5 -right-2 text-3xl text-cyan" />
                        </div>
                        {/* Image information */}
                        <div>
                          <h6 className="font-bold">
                            {t("Photo captured successfully.")}
                            <br />
                            {t("Check your photo before clearing the area.")}
                          </h6>
                          <p className="text-base">
                            {t("Characterization")}{" "}
                            {historyMaterial[historyId] !== undefined ? (
                              <>
                                {i18n.language === "fr" && "du"}{" "}
                                <span className="text-blush font-semibold">
                                  {historyMaterial[historyId]._time &&
                                    formattedDate(
                                      historyMaterial[historyId]._time,
                                      i18n.language,
                                      true,
                                    )}{" "}
                                  {t("to")}{" "}
                                  {historyMaterial[historyId]._time &&
                                    formattedTime(
                                      historyMaterial[historyId]._time,
                                    )}
                                </span>
                              </>
                            ) : null}{" "}
                            {t("processing")}.{" "}
                            {t("Your data will be displayed soon")}.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
              </section>
              {!expendImage && (
                <ContainerViewMaterialForPDF
                  tableRef={tableRef}
                  parentCategoriesList={parentCategoriesList}
                  totalWasteMass={totalWasteMass}
                  chartRef={chartRef}
                  dataMassMaterial={dataMassMaterial}
                  labelMaterial={labelMaterial}
                  dataMaterialChart={dataNumberOfMaterial}
                  labelChart={labelMaterialSeparate}
                  materialTotal={materialTotal}
                  colorChart={colorMaterial}
                  materialRef={materialRef}
                  dataVolumeMaterial={dataVolumeMaterial}
                  volumeRef={volumeRef}
                  totalWeight={totalWeight * 1000}
                  outletData={outletData}
                  outletRef={outletRef}
                  tableOutletRef={tableOutletRef}
                  repData={repData}
                  totalWasteVolume={totalWasteVolume}
                  chartRepRef={chartRepRef}
                  tableRepRef={tableRepRef}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SuperContainerViewMaterial;
