import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../../components/utils/Loading";
import { formattedDate } from "../../../components/formatted/FormattedDateAndTime";
import {
  // getContainerMaterialKey,
  getWasteStatistic,
} from "../../../services/container";
import { useTranslation } from "react-i18next";

const StatisticsKeyValuesComponent = ({
  periodHistoryStart,
  periodHistoryStop,
  periodHistoryStartPrevious,
  periodHistoryStopPrevious,
  keywordFilter,
  numberOfContainers,
  numberOfSite,
  handleValidate,
  setHandleValidate,
  periodName,
}) => {
  const { t, i18n } = useTranslation();
  const [totalSortingErrorCurrent, setTotalSortingErrorCurrent] = useState(0);
  const [totalSortingErrorPrevious, setTotalSortingErrorPrevious] = useState(0);
  const [percentageSortingError, setPercentageSortingError] = useState(0);
  const [isAnomaliesPreviousPeriod, setIsAnomaliesPreviousPeriod] = useState(0);
  const [helpSortingErrorCurrent, setHelpSortingErrorCurrent] = useState(false);
  const [helpSortingErrorPrevious, setHelpSortingErrorPrevious] =
    useState(false);
  const [helpSortingErrorComparison, setHelpSortingErrorComparison] =
    useState(false);

  const energyRecoverableWaste = 0;
  const wasteBury = 0;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      setHandleValidate(true);
    },
    // eslint-disable-next-line
    [],
  );

  const fetchData = () => {
    setIsLoading(true);
    axios
      .all([
        // not used yet
        // getContainerMaterialKey({
        //   periodStart: periodHistoryStart,
        //   periodStop: periodHistoryStop,
        //   keywordFilter: JSON.stringify(keywordFilter.value),
        // }),
        getWasteStatistic({
          periodStart: periodHistoryStart,
          periodStop: periodHistoryStop,
          keywordFilter: JSON.stringify(keywordFilter.value),
        }),
        getWasteStatistic({
          periodStart: periodHistoryStartPrevious,
          periodStop: periodHistoryStopPrevious,
          keywordFilter: JSON.stringify(keywordFilter.value),
        }),
      ])
      .then(
        axios.spread(
          (
            // responseMaterialContainer,
            responseStatistics,
            responsePreviousStatistics,
          ) => {
            if (
              // responseMaterialContainer.status === 200 &&
              responseStatistics.status === 200 &&
              responsePreviousStatistics.status === 200
            ) {
              //Response Anomaly Count
              setTotalSortingErrorCurrent(
                responseStatistics.data.statisticsData.countAnomalies,
              );
              setTotalSortingErrorPrevious(
                responsePreviousStatistics.data.statisticsData.countAnomalies,
              );

              setPercentageSortingError(
                Math.round(
                  ((responseStatistics.data.statisticsData.countAnomalies -
                    responsePreviousStatistics.data.statisticsData
                      .countAnomalies) /
                    responsePreviousStatistics.data.statisticsData
                      .countAnomalies) *
                    100,
                ),
              );

              setIsAnomaliesPreviousPeriod(
                responsePreviousStatistics.data.statisticsData.countAnomalies >
                  0,
              );

              //Response Material Container
              // setEnegyRecoverableWaste(
              //   responseMaterialContainer.data.energyRecoverableWaste,
              // );
              // setWasteBury(responseMaterialContainer.data.wasteBury);
              setHandleValidate(false);
              setIsLoading(false);
            }
          },
        ),
      );
  };

  useEffect(
    () => {
      if (handleValidate) {
        fetchData();
      }
    },

    // eslint-disable-next-line
    [handleValidate],
  );

  return (
    <div>
      {!isLoading ? (
        <>
          <section className="bg-white p-3 rounded-lg my-2">
            <h4 className="font-semibold">
              <span>
                {numberOfSite} site{numberOfSite > 1 ? "s " : " "}
              </span>
              <span>
                | {numberOfContainers}{" "}
                {t(
                  numberOfContainers === 1
                    ? "Analysed area/container"
                    : "Analysed areas/containers",
                )}
              </span>
            </h4>

            <article className="flex flex-row w-full p-4 gap-6">
              <div className="flex flex-col w-1/4 p-3 bg-[#FFE2E5] rounded-lg">
                <div className="grid justify-items-center rounded-full bg-[#FA5A7D] h-8 w-8">
                  <i className="fas fa-exclamation-circle text-white my-auto"></i>
                </div>
                <h3 className="font-bold mt-2">{totalSortingErrorCurrent}</h3>
                <div className="flex">
                  <span>Nb. {t("sorting errors")}</span>
                  <i
                    className="fas fa-info-circle text-gainsboro text-xs ml-1"
                    onMouseOver={() => setHelpSortingErrorCurrent(true)}
                    onMouseOut={() => setHelpSortingErrorCurrent(false)}
                  ></i>
                </div>
                {helpSortingErrorCurrent ? (
                  <div
                    className="absolute bg-yellow text-white top-96 text-xs p-2 rounded"
                    style={{ left: "18rem" }}
                  >
                    {i18n.language === "fr" && "Du "}
                    {formattedDate(periodHistoryStart, i18n.language)}{" "}
                    {i18n.language === "fr" ? "à" : "to"}{" "}
                    {formattedDate(periodHistoryStop, i18n.language)}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col w-1/4 p-3 bg-[#DCFCE7] rounded-lg">
                <div className="grid justify-items-center rounded-full bg-[#3CD856] h-8 w-8">
                  <i className="fas fa-exclamation-circle text-white my-auto"></i>
                </div>
                <h3 className="font-bold mt-2">{totalSortingErrorPrevious}</h3>
                <div className="flex">
                  <span>
                    Nb. {t("sorting errors from the previous period")}
                  </span>
                  <i
                    className="fas fa-info-circle text-gainsboro text-xs ml-1"
                    onMouseOver={() => setHelpSortingErrorPrevious(true)}
                    onMouseOut={() => setHelpSortingErrorPrevious(false)}
                  ></i>
                </div>
                {helpSortingErrorPrevious ? (
                  <div
                    className="absolute bg-yellow text-white top-96 text-xs p-2 rounded"
                    style={{ left: "35rem" }}
                  >
                    {i18n.language === "fr" && "Du "}
                    {formattedDate(
                      periodHistoryStartPrevious,
                      i18n.language,
                    )}{" "}
                    {i18n.language === "fr" ? "au" : "to"}{" "}
                    {formattedDate(periodHistoryStopPrevious, i18n.language)}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col w-1/4 p-3 bg-[#FFF4DE] rounded-lg">
                <div className="grid justify-items-center rounded-full bg-[#FF947A] h-8 w-8">
                  <i className="fas fa-recycle text-white my-auto"></i>
                </div>
                <h3 className="font-bold mt-2">
                  {Math.round(energyRecoverableWaste) === 0
                    ? "Soon!"
                    : Math.round(energyRecoverableWaste)}{" "}
                  m<sup>3</sup>
                </h3>
                <div className="flex">
                  <span>{t("Recovered waste")}</span>
                </div>
              </div>
              <div className="flex flex-col w-1/4 p-3 bg-[#FFD5A4] rounded-lg">
                <div className="grid justify-items-center rounded-full bg-[#FF8900] h-8 w-8">
                  <i className="far fa-window-close text-white my-auto"></i>
                </div>
                <h3 className="font-bold mt-2">
                  {Math.round(wasteBury) === 0
                    ? "Soon!"
                    : Math.round(wasteBury)}{" "}
                  m<sup>3</sup>
                </h3>
                <div className="flex">
                  <span>{t("Buried waste")}</span>
                </div>
              </div>
              {isAnomaliesPreviousPeriod ? (
                <div className="flex flex-col w-1/4 p-3 bg-[#F3E8FF] rounded-lg">
                  <div className="grid justify-items-center rounded-full bg-[#BF83FF] h-8 w-8">
                    <i className="fas fa-chart-bar text-white my-auto"></i>
                  </div>
                  <h3 className="font-bold mt-2">
                    {percentageSortingError > 0 ? "+" : null}
                    {percentageSortingError}%
                  </h3>
                  <div className="flex">
                    <span>{t("Sorting errors variation")}</span>
                    <i
                      className="fas fa-info-circle text-gainsboro text-xs ml-1"
                      onMouseOver={() => setHelpSortingErrorComparison(true)}
                      onMouseOut={() => setHelpSortingErrorComparison(false)}
                    ></i>
                    {helpSortingErrorComparison && (
                      <div
                        className="absolute bg-yellow text-white top-96 text-xs p-2 rounded"
                        style={{ right: "5rem" }}
                      >
                        {t("Comparison with the previous period")}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col w-1/4 p-3 text-center bg-[#F3E8FF] rounded-lg">
                  <i className="fas fa-exclamation-triangle text-xl mt-3"></i>
                  <span className="text-center mt-3">
                    {t("Not enough data on the chosen period")}.
                  </span>
                </div>
              )}
            </article>
          </section>
        </>
      ) : (
        <div className="my-2">
          <Loading height={"35vh"} />
        </div>
      )}
    </div>
  );
};

export default StatisticsKeyValuesComponent;
