import React, { useCallback, useEffect, useState } from "react";
import Loading from "../../../components/utils/Loading";
import {
  elapsedTimeContainer,
  formattedDate,
  formattedTime,
  formattedDuration,
  isMoreThanFiveMinutesAgo,
} from "../../../components/formatted/FormattedDateAndTime";
import { capitalizeFirstLetter } from "../../../components/utils/Date";
import { ImageCardComponent } from "../../../components/imageCard/ImageCardComponent";
import useWindowDimensions from "../../../components/formatted/UseWindowsDimensions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Cookies from "js-cookie";
import ContainerCompact from "../ContainerCompact";
import { formattedColorMaterial } from "../../../components/formatted/FormattedText";
import { MaterialCountingPieChart } from "../../../components/charts/MaterialCountingPieChart";
import {
  getHistoryCycleContainer,
  getHistoryFillingSpeed,
} from "../../../services/container";
import { useTranslation } from "react-i18next";
import ImageListAnomalies from "../../../components/ImageListAnomalies";

const ContainerViewActivity = ({ data, updateData }) => {
  const { t, i18n } = useTranslation();
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");
  const { id } = useParams();
  const { _time, container } = JSON.parse(
    sessionStorage.getItem("containerDetails"),
  );
  const [dateLastRotation, setDateLastRotation] = useState([]);
  const [durationFullFillingMs, setDurationFullFillingMs] = useState(0);
  const [reliablePrediction, setReliablePrediction] = useState(false);
  const [historyCurrent, setHistoryCurrent] = useState([]);
  const [dataSortedByAnomalies, setDataSortedByAnomalies] = useState([]);
  const [historyId, setHistoryId] = useState(0);
  const [helpCompacting, sethelpCompacting] = useState(false);
  const [helpCollect, setHelpCollect] = useState(false);
  const [helpQuality, setHelpQuality] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lastRotation, setLastRotation] = useState([]);
  const [levelLastRotation, setLevelLastRotation] = useState([]);
  const [lastPackmatEfficiency, setLastPackmatEfficiency] = useState([]);
  const [numberOfStars, setNumberOfStars] = useState(0);
  const [numberOfPackmat, setNumberOfPackmat] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [dataNumberOfAnomaly, setDataNumberOfAnomaly] = useState([]);
  const [labelMaterialSeparate, setLabelMaterialSeparate] = useState([]);
  const [colorMaterial, setColorMaterial] = useState([]);
  const [totalAnomaly, setTotalAnomaly] = useState(0);
  const [anomalyDelivery, setAnomalyDelivery] = useState({});
  const [totalVolumeSortingError, setTotalVolumeSortingError] = useState(0);
  const [totalWasteVolume, setTotalWasteVolume] = useState(0);
  const [activatedSortingErrorOption, setActivatedSortingErrorOption] =
    useState(false);
  const [numberOfObjects, setNumberOfObjects] = useState(0);
  const [objectDelivery, setObjectDelivery] = useState({});
  const [colorObject, setColorObject] = useState([]);
  const [labelObjectSeparate, setLabelObjectSeparate] = useState([]);
  const [dataNumberOfObject, setDataNumberOfObject] = useState([]);

  const currentDate = new Date();
  const { heightWindow, widthWindow } = useWindowDimensions();
  let imageWidth = widthWindow < 1320 ? 220 : 140;
  let imageHeight = widthWindow < 1320 ? 220 : 140;

  const handleAnomalyList = (typeAnomaly) => {
    if (typeAnomaly) {
      const newAnomalyList = Object.keys(typeAnomaly).map((type, index) => ({
        name: `${t(type)}${type === "sorting_errors" ? ` (${t("sum of the sorting errors chart salues")})` : ""}`,
        value: type,
        nameAndCount: `${t(type)} (${typeAnomaly[type]})`,
        color: formattedColorMaterial(type),
        count: typeAnomaly[type],
      }));
      return newAnomalyList;
    }
  };

  const findHistoryId = (targetTime) => {
    const index = historyCurrent.findIndex((item) => item._time === targetTime);
    setHistoryId(index);
  };

  const fetchData = useCallback(
    () => {
      axios
        .all([
          getHistoryCycleContainer(
            {
              currentCycle: true,
              getLevelLastRotation: true,
            },
            id,
          ),
          getHistoryFillingSpeed(id),
        ])
        .then(
          axios.spread((responseHistoryCurrent, responseSpeedFilling) => {
            if (
              responseHistoryCurrent.status === 200 ||
              responseSpeedFilling.status === 200
            ) {
              setFromDate(new Date(responseSpeedFilling.data.fromDate));
              setDurationFullFillingMs(
                responseSpeedFilling.data?.durationFullFillingMs[0]?.duration,
              );
              setReliablePrediction(
                responseSpeedFilling.data?.reliablePrediction[0],
              );

              setHistoryCurrent(responseHistoryCurrent.data.historyCycle);
              const filteredAndSortedData =
                responseHistoryCurrent.data.historyCycle
                  .filter((item) => item.anomaliesNewObject > 0)
                  .sort((a, b) => b.anomaliesNewObject - a.anomaliesNewObject)
                  .slice(0, 8)
                  .sort((a, b) => new Date(b._time) - new Date(a._time));
              setDataSortedByAnomalies(filteredAndSortedData);
              setLevelLastRotation(
                responseHistoryCurrent.data.levelLastRotation[0],
              );
              setDateLastRotation(
                responseHistoryCurrent.data.historyCycle[
                  responseHistoryCurrent.data.historyCycle.length - 1
                ],
              );
              setLastRotation(responseHistoryCurrent.data.lastRotation[0]);
              setNumberOfStars(responseHistoryCurrent.data.numberOfStars);
              setTotalVolumeSortingError(
                responseHistoryCurrent.data.totalVolumeSortingError,
              );
              setTotalWasteVolume(responseHistoryCurrent.data.totalWasteVolume);
              setNumberOfPackmat(responseHistoryCurrent.data.numberOfPackmat);
              setLastPackmatEfficiency(
                responseHistoryCurrent.data.lastPackmatEfficiency[0],
              );
              setAnomalyDelivery(responseHistoryCurrent.data.anomalyDelivery);

              setNumberOfObjects(responseHistoryCurrent.data.numberOfObjects);
              setObjectDelivery(responseHistoryCurrent.data.objectDelivery);

              const newData = {
                ...container,
                durationFullFillingMs:
                  responseSpeedFilling.data?.durationFullFillingMs,
                fromDate: responseSpeedFilling.data.fromDate,
                reliablePrediction:
                  responseSpeedFilling.data?.reliablePrediction,
                historyCurrent: responseHistoryCurrent.data.historyCycle,
                numberOfStars: responseHistoryCurrent.data.numberOfStars,
                numberOfAnomaly: responseHistoryCurrent.data.numberOfAnomaly,
                anomalyDelivery: responseHistoryCurrent.data.anomalyDelivery,
                numberOfPackmat: responseHistoryCurrent.data.numberOfPackmat,
                meanFillingLevelByOpenedDay:
                  responseSpeedFilling.data.fillingPerOpenedWeekday[0],
                meanFillingLevelByOpenedHour:
                  responseSpeedFilling.data.fillingPerOpenedHour[0],
                meanFillingLevelByDay:
                  responseSpeedFilling.data.fillingPerWeekday[0],
                levelLastRotation:
                  responseHistoryCurrent.data.levelLastRotation[0],
                dateLastRotation:
                  responseHistoryCurrent.data.historyCycle[
                    responseHistoryCurrent.data.historyCycle.length - 1
                  ],
                lastRotation: responseHistoryCurrent.data.lastRotation[0],
                lastPackmatEfficiency:
                  responseHistoryCurrent.data.lastPackmatEfficiency[0],
                numberOfObjects: responseHistoryCurrent.data.numberOfObjects,
                objectDelivery: responseHistoryCurrent.data.objectDelivery,
              };

              updateData(newData);
            }
          }),
        );
    },
    // eslint-disable-next-line
    [id, API, token],
  );

  useEffect(() => {
    const doAllVariablesExist = checkUndefined(
      data?.fromDate,
      data?.historyCurrent,
      data?.dateLastRotation,
      data?.numberOfStars,
    );
    setActivatedSortingErrorOption(data.activatedSortingErrorOption);
    if (isMoreThanFiveMinutesAgo(_time) || !doAllVariablesExist) {
      fetchData();
    } else {
      if (data?.historyCurrent) {
        setDurationFullFillingMs(data?.durationFullFillingMs[0]?.duration);
        setReliablePrediction(data?.reliablePrediction[0]);
        setFromDate(new Date(data?.fromDate));
        setHistoryCurrent(data?.historyCurrent);
        const filteredAndSortedData = data?.historyCurrent
          .filter((item) => item.anomaliesNewObject > 0)
          .sort((a, b) => b.anomaliesNewObject - a.anomaliesNewObject)
          .slice(0, 8)
          .sort((a, b) => new Date(b._time) - new Date(a._time));
        setDataSortedByAnomalies(filteredAndSortedData);
        setLevelLastRotation(data?.levelLastRotation);
        setDateLastRotation(data?.dateLastRotation);
        setLastRotation(data?.lastRotation);
        setNumberOfStars(data?.numberOfStars);
        setTotalVolumeSortingError(data?.totalVolumeSortingError);
        setTotalWasteVolume(data?.totalWasteVolume);
        setNumberOfPackmat(data?.numberOfPackmat);
        setLastPackmatEfficiency(data?.lastPackmatEfficiency);
        setAnomalyDelivery(data?.anomalyDelivery);
        setNumberOfObjects(data?.numberOfObjects);
        setObjectDelivery(data?.objectDelivery);
      }
    }
  }, [_time, data, fetchData]);

  const fetchTypeAnomaly = useCallback(
    () => {
      if (historyCurrent.length === 0) return;

      const anomalyList = handleAnomalyList(anomalyDelivery);

      const mergedMaterials = anomalyList.flat().reduce((merged, material) => {
        merged[material.name] = {
          ...(merged[material.name] || { ...material }),
          count: (merged[material.name]?.count || 0) + material.count,
        };
        return merged;
      }, {});

      const mergedArray = Object.values(mergedMaterials);
      const anomalyCount = mergedArray.map((anomaly) => anomaly.count);
      const anomalyNames = mergedArray.map((anomaly) => anomaly.name);
      const anomalyColors = mergedArray.map((anomaly) => anomaly.color);
      const total = anomalyCount.reduce((sum, count) => sum + count, 0);

      setDataNumberOfAnomaly(anomalyCount);
      setLabelMaterialSeparate(anomalyNames);
      setColorMaterial(anomalyColors);
      setTotalAnomaly(total);
      setIsLoading(false);
    },
    // eslint-disable-next-line
    [historyCurrent, anomalyDelivery, i18n.language],
  );

  const fetchObjectType = useCallback(
    () => {
      if (historyCurrent.length === 0) return;

      const objectList = handleAnomalyList(objectDelivery);

      const mergedMaterials = objectList.flat().reduce((merged, material) => {
        merged[material.name] = {
          ...(merged[material.name] || { ...material }),
          count: (merged[material.name]?.count || 0) + material.count,
        };
        return merged;
      }, {});

      const mergedArray = Object.values(mergedMaterials);
      const objectCount = mergedArray.map((material) => material.count);
      const objectNames = mergedArray.map((material) => material.name);
      const objectColors = mergedArray.map((material) => material.color);

      setColorObject(objectColors);
      setLabelObjectSeparate(objectNames);
      setDataNumberOfObject(objectCount);
      setIsLoading(false);
    },
    // eslint-disable-next-line
    [historyCurrent, objectDelivery, i18n.language],
  );

  useEffect(() => {
    fetchTypeAnomaly();
    fetchObjectType();
  }, [fetchTypeAnomaly, fetchObjectType]);

  // if one or more variables is set as undefined return false else return true
  function checkUndefined(...variables) {
    return variables.every((v) => v !== undefined);
  }

  return isLoading ? (
    <Loading color="#efefeffb" />
  ) : historyCurrent.length !== 0 ? (
    <div className="modal-nav-item">
      <section className="modal-item-key">
        {data.activatedFillingLevelOption && (
          <article className="modal-item-key-content">
            <div className="modal-item-key-title">
              <span>{t("Filling level")}</span>
            </div>
            {historyCurrent.length > 0 ? (
              <div className="modal-item-key-value">
                <h5 className="font-size-35">
                  {historyCurrent[0].filling_level}%
                </h5>
                <div>
                  <span>
                    {formattedDate(historyCurrent[0]._time, i18n.language)}
                  </span>
                  <span>{formattedTime(historyCurrent[0]._time)}</span>
                </div>
              </div>
            ) : null}
          </article>
        )}

        {data.packmatActive ? (
          <article className="modal-item-key-content">
            <div className="modal-item-key-title">
              <span>{t("Packmat")}</span>
              <i
                className="fa fa-info-circle"
                onMouseOver={() => sethelpCompacting(true)}
                onMouseOut={() => sethelpCompacting(false)}
              ></i>
            </div>
            <div className="modal-item-key-value">
              <h5 className="font-size-35">{numberOfPackmat}</h5>

              {lastPackmatEfficiency ? (
                <div>
                  <span>
                    {formattedDate(lastPackmatEfficiency._time, i18n.language)}{" "}
                    - {formattedTime(lastPackmatEfficiency._time)}
                  </span>

                  <span>
                    {t("Efficiency")} :{" "}
                    {lastPackmatEfficiency
                      ? lastPackmatEfficiency.efficiency
                      : "-"}
                    %
                  </span>
                </div>
              ) : null}
            </div>
            {helpCompacting ? (
              <article className="modal-item-key-help">
                {t(
                  "Number of compactions performed since the container arrived on-site as well as the efficiency resulting from the last compaction",
                )}
                .
              </article>
            ) : null}
          </article>
        ) : null}
        {data.activatedFillingLevelOption && (
          <article className="modal-item-key-content">
            <div className="modal-item-key-title">
              <span>{t("Maximum filling in")}</span>
              <i
                className="fas fa-info-circle"
                onMouseOver={() => setHelpCollect(true)}
                onMouseOut={() => setHelpCollect(false)}
              ></i>
            </div>
            <div className="modal-item-key-value">
              {historyCurrent.length > 0 &&
              historyCurrent[0].filling_level === 100 ? (
                <h5 className="font-size-35">{t("Maximum level reached")}</h5>
              ) : (
                <>
                  {durationFullFillingMs != null &&
                  reliablePrediction === true &&
                  (currentDate?.getTime() - fromDate?.getTime()) /
                    (1000 * 3600 * 24) >
                    60 ? (
                    <h5 className="font-size-35">
                      {capitalizeFirstLetter(
                        formattedDuration(durationFullFillingMs, i18n.language),
                      )}
                    </h5>
                  ) : (
                    <div className="flex flex-row items-center">
                      <span className="font-size-35 text-[#e83e8c]">-</span>
                      {durationFullFillingMs == null && (
                        <span>{`(${t("Please configure your opening hours in 'Address'")})`}</span>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>

            {helpCollect ? (
              <article className="modal-item-key-help">
                {t(
                  "Estimation of the container's maximum fill based on the average filling rate observed over the last 3 months",
                )}
                .
              </article>
            ) : null}
          </article>
        )}
        {activatedSortingErrorOption && (
          <article className="modal-item-key-content">
            <div className="modal-item-key-title">
              <span>{t("Waste Quality")}</span>
              <i
                className="fas fa-info-circle"
                onMouseOver={() => setHelpQuality(true)}
                onMouseOut={() => setHelpQuality(false)}
              ></i>
            </div>
            <div className="flex flex-row modal-item-key-value">
              <div className="flex flex-row w-full justify-between">
                <article className="modal-item-key-star">
                  {numberOfStars === 3 ? (
                    <>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </>
                  ) : null}
                  {numberOfStars === 2 ? (
                    <>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="far fa-star"></i>
                    </>
                  ) : null}
                  {numberOfStars === 1 ? (
                    <>
                      <i className="fas fa-star"></i>
                      <i className="far fa-star"></i>
                      <i className="far fa-star"></i>
                    </>
                  ) : null}
                  {numberOfStars === 0 ? (
                    <>
                      <i className="far fa-star"></i>
                      <i className="far fa-star"></i>
                      <i className="far fa-star"></i>
                    </>
                  ) : null}
                </article>
                <span className="mt-4">
                  {(totalVolumeSortingError * 100) / totalWasteVolume
                    ? (totalVolumeSortingError * 100) / totalWasteVolume < 1
                      ? `< 1% ${t("of contaminants")}`
                      : `${Math.round(
                          (totalVolumeSortingError * 100) / totalWasteVolume,
                        )}% ${t("of contaminants")}`
                    : "-"}
                </span>
              </div>
            </div>

            {helpQuality ? (
              <article className="modal-item-key-help">
                {t(
                  "Assessment of material quality based on the volumetric proportion of detected errors",
                )}
                .
                <p style={{ marginTop: 5, marginBottom: 0 }}>
                  <i style={{ fontSize: 15 }} className="far fa-star"></i>
                  <i style={{ fontSize: 15 }} className="far fa-star"></i>
                  <i style={{ fontSize: 15 }} className="far fa-star"></i>:{" "}
                  {t("More than 30% of contaminants")}
                </p>
                <p style={{ marginTop: 5, marginBottom: 0 }}>
                  <i style={{ fontSize: 15 }} className="fas fa-star"></i>
                  <i style={{ fontSize: 15 }} className="far fa-star"></i>
                  <i style={{ fontSize: 15 }} className="far fa-star"></i>:{" "}
                  {t("Between 15% and 30% of contaminants")}
                </p>
                <p style={{ marginTop: 5, marginBottom: 0 }}>
                  <i style={{ fontSize: 15 }} className="fas fa-star"></i>
                  <i style={{ fontSize: 15 }} className="fas fa-star"></i>
                  <i style={{ fontSize: 15 }} className="far fa-star"></i>:{" "}
                  {t("Between 5% and 15% of contaminants")}
                </p>
                <p style={{ marginTop: 5, marginBottom: 0 }}>
                  <i style={{ fontSize: 15 }} className="fas fa-star"></i>
                  <i style={{ fontSize: 15 }} className="fas fa-star"></i>
                  <i style={{ fontSize: 15 }} className="fas fa-star"></i>:{" "}
                  {t("Less than 5% of contaminants")}
                </p>
              </article>
            ) : null}
          </article>
        )}
      </section>
      {activatedSortingErrorOption && dataSortedByAnomalies.length > 0 && (
        <ImageListAnomalies
          container={data}
          dataSortedByAnomalies={dataSortedByAnomalies}
          findHistoryId={findHistoryId}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          historyCycle={historyCurrent}
        />
      )}
      <div className="grid grid-cols-2 gap-3 md:grid-cols-1 w-full">
        <article className="bg-white rounded-xl w-full truncate">
          {historyCurrent.length > 0 && (
            <ImageCardComponent
              API={API}
              container={data}
              displayLegend={true}
              expendImage={false}
              setExpendImage={null}
              history={historyCurrent}
              historyId={historyId}
              isCharacterization={false}
              setHistoryId={setHistoryId}
              displayAnomalyLegend={false}
              isActivity={true}
            />
          )}
        </article>
        {data.activatedFillingLevelOption && (
          <article className="bg-white rounded-xl w-full">
            <div className="modal-item-container-title-column">
              <h5 className="data-bold">
                {t("Current filling cycle")}{" "}
                {lastRotation
                  ? `(${elapsedTimeContainer(lastRotation._time, i18n.language, false)} ${t("on site")})`
                  : ""}
              </h5>
              {levelLastRotation && dateLastRotation ? (
                <div>
                  {t("Last rotation")}
                  <p className="data-bold">
                    : {levelLastRotation.filling_level}% |{" "}
                    {formattedDate(dateLastRotation._time, i18n.language)} -{" "}
                    {formattedTime(dateLastRotation._time)}
                  </p>
                </div>
              ) : null}
            </div>
            <ContainerCompact
              container={data}
              history={true}
              dataForCompactOverlay={historyCurrent}
              containerActive={data}
              findHistoryId={findHistoryId}
              showPackmat={true}
              showLimitFillingLevel={true}
              showToolbox={true}
            />
          </article>
        )}
        {activatedSortingErrorOption && (
          <>
            <article className="bg-white rounded-xl w-full">
              <div className="modal-item-container-title-column">
                <h5 className="data-bold">
                  {t("Anomalies in the waste category")} {data.wasteName}
                </h5>
              </div>
              <div
                className="modal-item-chart"
                style={{ height: heightWindow / 1.5 }}
              >
                <MaterialCountingPieChart
                  dataChart={dataNumberOfAnomaly}
                  labelChart={labelMaterialSeparate}
                  materialTotal={totalAnomaly}
                  colorChart={colorMaterial}
                  statistic={true}
                  showMaterialTotal={true}
                />
              </div>
            </article>
            <article className="bg-white rounded-xl w-full">
              <div className="modal-item-container-title-column">
                <h5 className="data-bold">{t("Material distribution")}</h5>
              </div>
              <div
                className="modal-item-chart"
                style={{ height: heightWindow / 1.5 }}
              >
                <MaterialCountingPieChart
                  dataChart={dataNumberOfObject}
                  labelChart={labelObjectSeparate}
                  materialTotal={numberOfObjects}
                  colorChart={colorObject}
                  statistic={true}
                  showMaterialTotal={true}
                />
              </div>
            </article>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="no-data">
      {t("No data available to display the container's activity")}.
    </div>
  );
};

export default ContainerViewActivity;
