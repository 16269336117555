import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LineChart } from "../../components/charts/LineChart";
import { useSelector } from "react-redux";
import Loading from "../../components/utils/Loading";
import { useTranslation } from "react-i18next";

const ContainerCompact = ({
  container,
  setIsCompactOverlayActive,
  setContainerActive,
  history,
  containerActive,
  dataForCompactOverlay,
  collection,
  findHistoryId,
  showPackmat,
  showLimitFillingLevel,
  showToolbox,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const containersData = useSelector((state) => state.containers.data);
  const [historyCurrent, setHistoryCurrent] = useState([]);

  useEffect(
    () => {
      if (history) {
        if (dataForCompactOverlay.length > 0) {
          setIsLoading(false);
        }
      } else {
        if (Object.keys(containersData).length !== 0) {
          if (containerActive._id in containersData) {
            const data = containersData[containerActive._id];
            setHistoryCurrent(data?.historyCurrent);
            setIsLoading(false);
          }
        }
      }
    },
    // eslint-disable-next-line
    [containersData, containerActive, dataForCompactOverlay],
  );

  function massMaximum(inputData) {
    return inputData.reduce((maxValue, currentValue) => {
      return Math.ceil(Math.max(maxValue, currentValue[1]));
    }, Number.NEGATIVE_INFINITY);
  }

  function transformDataMassForGraph(inputData) {
    let maxYAxis = 0;
    const massData = inputData.map((item) => {
      return [item._time, item.mass / 1000, item.image_url];
    });
    const max = massMaximum(massData);
    if (max < 12) {
      maxYAxis = 12;
    } else {
      maxYAxis = max + 1;
    }
    return { massData: massData, maxYAxis: maxYAxis };
  }

  function transformDataMassDefaultDensityForGraph(inputData) {
    return inputData.map((item) => {
      return [item._time, item.massDefaultDensity / 1000];
    });
  }

  function transformDataMassNoPackmatDefaultDensityForGraph(inputData) {
    return inputData.map((item) => {
      return [item._time, item.massNoPackmatDefaultDensity / 1000];
    });
  }

  function transformDataForGraph(inputData) {
    return inputData.map((item) => {
      return [item._time, item.filling_level, item.image_url];
    });
  }

  function transformDataForCompactGraph(inputData) {
    return inputData
      .map((item, idx) => {
        if (idx !== 0 && item.packmat) {
          return {
            name: "Packmat",
            value: `${item.diff_filling}%`,
            xAxis: new Date(inputData[idx]._time)?.getTime(),
            yAxis: 100,
            time: inputData[idx]._time,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null && item !== undefined);
  }

  return (
    <>
      {!history && (
        <div
          className="w-6 h-6 bg-gray-400 ml-auto flex items-center justify-center rounded-full"
          style={{
            marginRight: "12px",
            marginTop: "14px",
          }}
          onClick={() => {
            setIsCompactOverlayActive(false);
            setContainerActive({});
          }}
        >
          <CloseIcon
            style={{
              width: 22,
              cursor: "pointer",
              color: "#FFF",
            }}
          />
        </div>
      )}
      {isLoading ? (
        <Loading color="#FFF" />
      ) : (
        <div className="flex flex-col relative" style={{ marginTop: "20px" }}>
          <div
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <LineChart
              chartName="weight"
              dataChart={transformDataForGraph(
                history ? dataForCompactOverlay : historyCurrent,
              )}
              dataCompact={transformDataForCompactGraph(
                history ? dataForCompactOverlay : historyCurrent,
              )}
              unitValueChart="%"
              maxYAxis={100}
              messageLabelTooltip={t("Level")}
              stepYAxis={20}
              dataForCompactOverlay={
                history ? dataForCompactOverlay : historyCurrent
              }
              limitFillingLevel={container?.limitFillingLevel}
              container={container}
              historyCurrent={history ? dataForCompactOverlay : historyCurrent}
              collection={collection}
              findHistoryId={findHistoryId}
              showPackmat={showPackmat}
              showLimitFillingLevel={showLimitFillingLevel}
              showToolbox={showToolbox}
            />
          </div>
          <div style={{ marginLeft: "25px", marginRight: "25px" }}>
            <LineChart
              chartName="compact"
              dataChart={
                transformDataMassForGraph(
                  history ? dataForCompactOverlay : historyCurrent,
                ).massData
              }
              dataChart2={transformDataMassDefaultDensityForGraph(
                history ? dataForCompactOverlay : historyCurrent,
              )}
              dataChart3={transformDataMassNoPackmatDefaultDensityForGraph(
                history ? dataForCompactOverlay : historyCurrent,
              )}
              unitValueChart="ton"
              maxYAxis={
                transformDataMassForGraph(
                  history ? dataForCompactOverlay : historyCurrent,
                ).maxYAxis
              }
              messageLabelTooltip={t("Weight")}
              stepYAxis={2}
              dataForCompactOverlay={
                history ? dataForCompactOverlay : historyCurrent
              }
              container={container}
              historyCurrent={history ? dataForCompactOverlay : historyCurrent}
              collection={collection}
              findHistoryId={findHistoryId}
              showToolbox={showToolbox}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ContainerCompact;
