import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { withRouter, useLocation } from "react-router-dom";
import { MdLock } from "react-icons/md";
import Loading from "../../components/utils/Loading";
import ContainerEdit from "./ContainerEdit";
import ContainerFilter from "./ContainerFilter";
import ContainerRow from "./ContainerRow";
import ContainerHeader from "./ContainerHeader";
import ContainerSortingError from "./ContainerSortingError";
import ContainerCompact from "./ContainerCompact";
import ContainerImageZoom from "./ContainerImageZoom";
import { Card } from "reactstrap";
import Cookies from "js-cookie";
import MapDisplay from "../../components/utils/Map";
import TruckSvg from "../../components/utils/TruckSvg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  checkContainerContractExist,
  checkIfAllSelected,
  checkPlanningIsActive,
  handleDeselectAll,
  sendPlanningMail,
} from "./utilsTable";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import { ToastContainer } from "react-toastify";
import {
  formattedTypeContainer,
  formattedStatus,
} from "../../components/formatted/FormattedText";
import {
  getContainersList,
  getSuperContainerList,
} from "../../services/container";
import { getSettingsData } from "../../services/settings";
import { useTranslation } from "react-i18next";
import ForbiddenModule from "../../components/ForbiddenModule";
import excavator from "../../static/assets/excavator.png";
import excavatorRed from "../../static/assets/excavatorRed.png";
import excavatorGrey from "../../static/assets/excavatorGrey.png";
import useScrollToEnd from "../../hooks/useScrollToEnd";

const Container = () => {
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");
  const corporationModules = JSON.parse(
    localStorage.getItem("corporationModules"),
  );

  const [monitoring, setMonitoring] = useState(true);

  useEffect(() => {
    if (corporationModules !== null) {
      setMonitoring(corporationModules.showMonitoringModule > 0);
    }
  }, [corporationModules]);

  const isAkanthasAccount = (Cookies.get("userEmail") || "").includes(
    "@akanthas.com",
  );
  const viewMode = Cookies.get("viewMode") || "client";

  const { t } = useTranslation();

  const location = useLocation();
  const stateContainer = location.state || undefined;
  const limitFilling = stateContainer?.limitFilling ?? false;
  const keywordF = stateContainer?.keywordF ?? null;
  const typeFillingF = stateContainer?.typeFilling ?? null;
  const addressF = stateContainer?.addressF ?? null;

  const [addressKeysFiltered, setAddressKeysFiltered] = useState(
    addressF
      ? [
          {
            key: addressF,
            cat: "Adresse",
            type: "address",
          },
        ]
      : [],
  );
  const [arrayFilterFillingLevel, setArrayFilterFIllingLevel] = useState(
    limitFilling || [0, 100],
  );
  const [countData, setCountData] = useState(0);
  const [contentKeysFiltered, setContentKeysFiltered] = useState([]);
  const [hourKeysFiltered, setHourKeysFiltered] = useState([]);
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [displayToBlock, setDisplayToBlock] = useState(
    stateContainer !== undefined && stateContainer.displayTimeLine
      ? true
      : false,
  );
  const [fieldSort, setFieldSort] = useState("filling_level");
  const [fillingRateDeliveryMap, setFillingRateDeliveryMap] = useState([]);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [modalEdit, setModalEdit] = useState(false);
  const [keywordFilterOptions, setKeywordFilterOptions] = useState([]);
  const [keywordFilter, setKeywordFilter] = useState(
    keywordF ? [{ type: "id", key: keywordF.toString() }] : [],
  );
  const [typeFillingFilter, setTypeFillingFilter] = useState(
    typeFillingF ? typeFillingF : "all",
  );
  const [settings, setSettings] = useState([]);
  const [statusKeysFiltered, setStatusKeysFiltered] = useState([]);
  const [orderSort, setOrderSort] = useState("-1");
  const [typeKeysFiltered, setTypeKeysFiltered] = useState([]);
  const [volumeKeysFiltered, setVolumeKeysFiltered] = useState([]);
  const [positioningKeysFiltered, setPositioningKeysFiltered] = useState([]);
  const [containersSelected, setContainersSelected] = useState([]);
  const [confimationCollectModal, setConfirmationCollectModal] =
    useState(false);
  const [helpButton, setHelpButton] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [noActivePackmat, setNoActivePackmat] = useState(false);
  const [superContainers, setSuperContainers] = useState({});
  const [containerObjectIdList, setContainerObjectIdList] = useState([]);

  const [cleanAllFilters, setCleanAllFilters] = useState(false);

  const [displayMode, setDisplayMode] = useState(
    Cookies.get("containerDisplayMode") || "table",
  );

  const [isSortingErrorOverlayActive, setIsSortingErrorOverlayActive] =
    useState(false);
  const [dataForSortingErrorOverlay, setDataForSortingErrorOverlay] = useState(
    [],
  );
  const [isCompactOverlayActive, setIsCompactOverlayActive] = useState(false);
  const [dataForCompactOverlay, setDataForCompactOverlay] = useState([]);
  const [isImageZoomOverlayActive, setIsImageZoomOverlayActive] =
    useState(false);
  const [isMapOverlayActive, setIsMapZoomOverlayActive] = useState(false);
  const [dataForMapOverlay, setDataForMapOverlay] = useState([]);
  const [containerActive, setContainerActive] = useState({});
  const [itemsPerPage, setItemsPerPage] = useState(6); //Number of lines to consider for lazy loading
  const [containerInactive, setContainerInactive] = useState([]);
  const [showContainerInactive, setShowContainerInactive] = useState(false);

  const filterData = async (fullDataIn) => {
    let filteredData = fullDataIn;

    if (positioningKeysFiltered.length > 0) {
      filteredData = filteredData.filter((item) => {
        return positioningKeysFiltered.some(
          (positioning) => positioning.name === item.positioning,
        );
      });
    }

    if (addressKeysFiltered.length > 0) {
      filteredData = filteredData.filter((item) => {
        return addressKeysFiltered.some(
          (address) => address.key === item.addressName,
        );
      });
    }

    if (
      !arrayFilterFillingLevel.every((value, index) => {
        return value === [0, 100][index];
      })
    ) {
      filteredData = filteredData.filter((item) => {
        return (
          item.filling_level >= arrayFilterFillingLevel[0] &&
          item.filling_level <= arrayFilterFillingLevel[1]
        );
      });
    }

    if (statusKeysFiltered.length > 0) {
      filteredData = filteredData.filter((item) => {
        return statusKeysFiltered.some(
          (status) => status.key === formattedStatus(item.status),
        );
      });
    }

    if (contentKeysFiltered.length > 0) {
      filteredData = filteredData.filter((item) => {
        return contentKeysFiltered.some(
          (content) => content.key === item.contentCategoryJoin.label,
        );
      });
    }

    if (typeKeysFiltered.length > 0) {
      filteredData = filteredData.filter((item) => {
        return typeKeysFiltered.some(
          (content) => content.key === formattedTypeContainer(item.type),
        );
      });
    }

    if (typeFillingFilter === "inactive") {
      filteredData = filteredData.filter((item) => {
        return item.statusFilling === typeFillingFilter;
      });
    }

    if (typeFillingFilter === "in_progress") {
      filteredData = filteredData.filter((item) => {
        return (
          item.filling_level > 0 &&
          item.statusFilling !== "critical" &&
          item.statusFilling !== "critical_danger" &&
          item.statusFilling !== "inactive"
        );
      });
    }

    if (
      typeFillingFilter === "critical" ||
      typeFillingFilter === "critical_danger"
    ) {
      filteredData = filteredData.filter((item) => {
        return (
          (item.statusFilling === "critical" ||
            item.statusFilling === "critical_danger") &&
          item.statusFilling !== "inactive"
        );
      });
    }

    if (typeFillingFilter === "empty") {
      filteredData = filteredData.filter((item) => {
        return item.filling_level === 0 && item.statusFilling !== "inactive";
      });
    }

    const compareAscending = (a, b) => {
      if (a[fieldSort] < b[fieldSort]) return -1;
      if (a[fieldSort] > b[fieldSort]) return 1;
      return 0;
    };

    const compareDescending = (a, b) => {
      if (a[fieldSort] > b[fieldSort]) return -1;
      if (a[fieldSort] < b[fieldSort]) return 1;
      return 0;
    };

    let orderFunction;
    if (orderSort === "1") {
      orderFunction = compareAscending;
    } else {
      orderFunction = compareDescending;
    }

    filteredData = filteredData.sort(orderFunction);
    setCountData(filteredData.length);
    setData([...filteredData]);
  };

  const multiselectTypeRef = useRef();
  const multiselectContentRef = useRef();
  const multiselectAddressRef = useRef();
  const multiselectStatusRef = useRef();
  const multiselectVolumeRef = useRef();
  const multiselectPositioningRef = useRef();
  const multiselectHour = useRef();

  const cleanFilters = () => {
    setKeywordFilter("");
    setContentKeysFiltered([]);
    setAddressKeysFiltered([]);
    setStatusKeysFiltered([]);
    setTypeKeysFiltered([]);
    setVolumeKeysFiltered([]);
    setHourKeysFiltered([]);
    setPositioningKeysFiltered([]);
    setArrayFilterFIllingLevel([0, 100]);
    setTypeFillingFilter("all");
    setCleanAllFilters(true);

    multiselectTypeRef.current.resetSelectedValues();
    multiselectContentRef.current.resetSelectedValues();
    multiselectAddressRef.current.resetSelectedValues();
    multiselectStatusRef.current.resetSelectedValues();
    multiselectVolumeRef.current.resetSelectedValues();
    multiselectPositioningRef.current.resetSelectedValues();
    multiselectHour.current.resetSelectedValues();
  };

  const toggleSortingErrorOverlay = () => {
    setIsCompactOverlayActive(false);
    setIsImageZoomOverlayActive(false);
    setIsMapZoomOverlayActive(false);
    setIsSortingErrorOverlayActive(true);
  };

  const toggleCompactOverlay = () => {
    setIsImageZoomOverlayActive(false);
    setIsSortingErrorOverlayActive(false);
    setIsMapZoomOverlayActive(false);
    setIsCompactOverlayActive(true);
  };

  const toggleImageZoomOverlay = () => {
    setIsCompactOverlayActive(false);
    setIsSortingErrorOverlayActive(false);
    setIsMapZoomOverlayActive(false);
    setIsImageZoomOverlayActive(true);
  };

  const toggleMapOverlay = () => {
    setIsCompactOverlayActive(false);
    setIsSortingErrorOverlayActive(false);
    setIsImageZoomOverlayActive(false);
    setIsMapZoomOverlayActive(true);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const screenWidthToForceBlockDisplay = 1320;

  useEffect(
    () => {
      if (keywordF) {
        scrollToContainer(keywordF);
      }
    },
    // eslint-disable-next-line
    [keywordF, fullData],
  );

  useEffect(() => {
    getSuperContainerList()
      .then((response) => {
        if (response.data.superContainers.length > 0) {
          setSuperContainers(response.data.superContainers[0]);
          let containerIdsList = response.data.superContainers.flatMap(
            (superContainer) => superContainer.containerObjectIdList,
          );
          setContainerObjectIdList([...containerIdsList]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [API, token]);

  useScrollToEnd(() => {
    setCurrentPage((prevPage) => prevPage + 1);
  });

  const handleDisplayMode = () => {
    setDisplayToBlock(!displayToBlock);
    if (displayMode === "table") {
      setDisplayMode("map");
      Cookies.set("containerDisplayMode", "map", { secure: true });
    } else {
      setDisplayMode("table");
      Cookies.set("containerDisplayMode", "table", { secure: true });
    }
  };

  useEffect(
    () => {
      function handleResize() {
        setScreenWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    },
    // eslint-disable-next-line
    [window],
  );

  useEffect(
    () => {
      if (displayMode === "table") {
        if (screenWidth <= screenWidthToForceBlockDisplay) {
          setDisplayToBlock(true);
        } else {
          setDisplayToBlock(false);
        }
      } else if (displayMode === "map") {
        setDisplayToBlock(true);
      }
    },
    // eslint-disable-next-line
    [screenWidth],
  );

  useEffect(
    () => {
      setIsLoading(true);
      setCountData(0);
      const fetchData = async () => {
        setContainersSelected([]);
        const filters = [...[], ...[], ...[], ...[], ...[], ...[]];

        await axios
          .all([
            getContainersList({
              page: null,
              limit: null,
              fieldSort: "filling_level",
              orderSort: "-1",
              keywordFilter: JSON.stringify(filters),
              arrayFilterFillingLevel: JSON.stringify([0, 100]), // TO REMOVE
              typeFillingFilter: "all",
              stopTime:
                hourKeysFiltered.length > 0 ? hourKeysFiltered[0].value : "",
            }),
            getSettingsData(),
          ])
          .then(
            axios.spread((responseData, responseSettings) => {
              if (responseData.status === 200 || responseSettings === 200) {
                setCountData(responseData.data.data.length);
                setData(responseData.data.data);
                setFullData(responseData.data.data);
                setNoActivePackmat(
                  !responseData.data.data.some((item) => item.packmatActive),
                );
                setFillingRateDeliveryMap(
                  responseData.data.arrayFillingRateDelivery,
                );
                setKeywordFilterOptions(responseData.data.keywordFilterOptions);
                setSettings(responseSettings.data.settings);
                filterData(responseData.data.data);
                setIsLoading(false);
              }
            }),
          );
      };
      fetchData();
    },
    // eslint-disable-next-line
    [API, token, hourKeysFiltered],
  );

  useEffect(
    () => {
      filterData(fullData);
      if (cleanAllFilters === true) {
        setCleanAllFilters(false);
      }
    },
    // eslint-disable-next-line
    [
      fieldSort,
      orderSort,
      keywordFilter,
      arrayFilterFillingLevel,
      volumeKeysFiltered,
      contentKeysFiltered,
      addressKeysFiltered,
      statusKeysFiltered,
      typeKeysFiltered,
      positioningKeysFiltered,
      typeFillingFilter,
      cleanAllFilters,
    ],
  );

  const scrollToContainer = async (id) => {
    setItemsPerPage(100);
    const container = fullData.filter((item) => item._id === id);
    const element = document.getElementById(`${id}`);
    element?.scrollIntoView({
      behavior: "smooth",
    });
    setContainerActive(container[0]);
  };

  const displayContainer = () => {
    const leftIndex = data.findIndex((item) =>
      item.image_url.includes("-L.jpeg"),
    );
    const rightIndex = data.findIndex((item) =>
      item.image_url.includes("-R.jpeg"),
    );
    if (leftIndex !== -1) {
      return leftIndex;
    } else {
      if (rightIndex !== -1) {
        return rightIndex;
      } else {
        return null;
      }
    }
  };

  useEffect(() => {
    const inactiveItems = data.filter((item) => {
      let deltaAlert = Math.floor(
        (new Date().getTime() - new Date(item._time).getTime()) / 86400000,
      );
      return deltaAlert > settings.alertNumberOfDays;
    });

    setContainerInactive(inactiveItems);
  }, [data, settings.alertNumberOfDays]);

  if (token) {
    return (
      <div
        className="base"
        style={{
          minWidth: "850px",
          overflowX: screenWidth < 1080 ? "auto" : "visible",
          whiteSpace: monitoring && "nowrap",
        }}
      >
        <h1 className="flex flex-row">
          {!monitoring && (
            <MdLock style={{ marginRight: "5px", height: 25, marginTop: 10 }} />
          )}
          {t("My Monitoring")}
        </h1>
        {!monitoring &&
        !(isAkanthasAccount && viewMode === "admin") &&
        location.pathname.includes("containers") ? (
          <ForbiddenModule viewType={"monitoring"} />
        ) : (
          <>
            <ContainerFilter
              arrayFilterFillingLevel={arrayFilterFillingLevel}
              setArrayFilterFIllingLevel={setArrayFilterFIllingLevel}
              keywordFilterOptions={keywordFilterOptions}
              keywordFilter={keywordFilter}
              setKeywordFilter={setKeywordFilter}
              contentKeysFiltered={contentKeysFiltered}
              setContentKeysFiltered={setContentKeysFiltered}
              addressKeysFiltered={addressKeysFiltered}
              setAddressKeysFiltered={setAddressKeysFiltered}
              statusKeysFiltered={statusKeysFiltered}
              setStatusKeysFiltered={setStatusKeysFiltered}
              typeKeysFiltered={typeKeysFiltered}
              setTypeKeysFiltered={setTypeKeysFiltered}
              volumeKeysFiltered={volumeKeysFiltered}
              setVolumeKeysFiltered={setVolumeKeysFiltered}
              positioningKeysFiltered={positioningKeysFiltered}
              setPositioningKeysFiltered={setPositioningKeysFiltered}
              cleanFilters={cleanFilters}
              cleanAllFilters={cleanAllFilters}
              setCleanAllFilters={setCleanAllFilters}
              setTypeFillingFilter={setTypeFillingFilter}
              multiselectTypeRef={multiselectTypeRef}
              multiselectContentRef={multiselectContentRef}
              multiselectAddressRef={multiselectAddressRef}
              multiselectStatusRef={multiselectStatusRef}
              multiselectVolumeRef={multiselectVolumeRef}
              multiselectPositioningRef={multiselectPositioningRef}
              hourKeysFiltered={hourKeysFiltered}
              setHourKeysFiltered={setHourKeysFiltered}
              multiselectHour={multiselectHour}
            />

            <section className="top-table">
              <section className="flex justify-between w-full">
                <div className="flex space-x-4">
                  {/* Sorting by field */}
                  <div className="operation-data justify">
                    <i className="fas fa-sort-amount-up ico-action "></i>

                    <select
                      className="sort-select"
                      name="sortByField"
                      onChange={(event) => {
                        setFieldSort(event.target.value);
                        setIsCompactOverlayActive(false);
                        setIsSortingErrorOverlayActive(false);
                        setIsImageZoomOverlayActive(false);
                        setIsMapZoomOverlayActive(false);
                      }}
                    >
                      <option value="filling_level">{t("Level")}</option>
                      <option value="status">Status</option>
                      <option value="wasteName">{t("Waste Category")}</option>
                      <option value="addressId">{t("Address")}</option>
                    </select>

                    <select
                      className="sort-select"
                      name="orderSortByField"
                      onChange={(event) => setOrderSort(event.target.value)}
                    >
                      <option value="1">{t("Ascending")}</option>
                      <option value="-1">{t("Descending")}</option>
                    </select>
                  </div>

                  {containersSelected.length > 0 && (
                    <button
                      className={`relative flex bg-cyan rounded-md text-white  my-2 
                        ${containersSelected.length > 0 ? "transition-opacity hover:opacity-100" : ""}
                        ${checkPlanningIsActive(data, containersSelected) ? "opacity-100 hover:bg-yellow" : "opacity-20 cursor-not-allowed hover:bg-gainsboro-transparent"}
                      `}
                      onMouseOver={() => setHelpButton(true)}
                      onMouseOut={() => setHelpButton(false)}
                      disabled={
                        !checkPlanningIsActive(data, containersSelected)
                      }
                      onClick={() => setConfirmationCollectModal(true)}
                    >
                      <TruckSvg
                        color={"#FFF"}
                        width={25}
                        height={25}
                        className={"mx-1 my-auto"}
                      />
                      <span className="font-semibold text-sm my-auto mx-1">
                        {t("Schedule")}
                      </span>

                      {helpButton &&
                        !checkContainerContractExist(
                          data,
                          containersSelected,
                        ) && (
                          <div className="absolute bg-yellow rounded-md w-96 -top-8 left-20">
                            {t(
                              "Activate by entering the email in your contracts",
                            )}
                          </div>
                        )}
                    </button>
                  )}

                  {containersSelected.length > 0 && (
                    <div className="flex px-2 border-solid border-light-grey rounded-full border-1 mx-2 my-2">
                      <FontAwesomeIcon
                        icon="times"
                        className="close-modal text-grey mx-1"
                        onClick={() => {
                          handleDeselectAll(
                            data,
                            setData,
                            setContainersSelected,
                          );
                        }}
                      />
                      <p className="my-auto mx-1 text-sm text-grey">
                        {containersSelected.length}{" "}
                        {t("Selection").toLowerCase()}
                        {containersSelected.length > 1 && "s"}
                      </p>
                    </div>
                  )}
                </div>

                <div className="table-bottom-wrapper justify-end flex">
                  <p
                    style={{
                      fontSize: 14,
                      color: "grey",
                      marginTop: "20px",
                      marginRight: "10px",
                    }}
                  >
                    {Object.keys(superContainers).length > 0 &&
                    location.pathname.includes("characterization")
                      ? 1
                      : countData}{" "}
                    {t(
                      `Area-Container${Object.keys(superContainers).length === 0 && countData > 1 ? "_plural" : ""}`,
                    )}{" "}
                    / &nbsp;
                    <span
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={cleanFilters}
                    >
                      {Object.keys(superContainers).length > 0 &&
                      location.pathname.includes("characterization")
                        ? 1
                        : fullData.length}
                    </span>
                  </p>
                </div>
              </section>
            </section>

            {isLoading ? (
              <Loading />
            ) : (
              <div className="w-full">
                <ContainerHeader
                  classColumnTable={`flex w-full ${displayToBlock ? "" : "justify-between"} bg-white rounded h-14 mx-0 mt-2`}
                  data={data}
                  setData={setData}
                  setContainersSelected={setContainersSelected}
                  selectedAll={checkIfAllSelected(data, containersSelected)}
                  screenWidth={screenWidth}
                  displayToBlock={displayToBlock}
                  noActivePackmat={noActivePackmat}
                />

                <section
                  className={`content-card ${displayToBlock ? "table-display-block" : ""}`}
                >
                  <article
                    className={`content-table ${displayToBlock ? "content-table-block" : ""}`}
                    style={{
                      width:
                        screenWidth < 1080 ||
                        (screenWidth >= 1080 &&
                          screenWidth <= screenWidthToForceBlockDisplay)
                          ? "100%"
                          : displayToBlock &&
                              screenWidth > screenWidthToForceBlockDisplay
                            ? "60%"
                            : "",
                    }}
                  >
                    {data.length > 0 ? (
                      <>
                        {data
                          .slice(0, currentPage * itemsPerPage)
                          .map((container, index) => {
                            let deltaAlert = Math.floor(
                              (new Date()?.getTime() -
                                new Date(container._time)?.getTime()) /
                                86400000,
                            );
                            if (deltaAlert <= settings.alertNumberOfDays) {
                              return (
                                <div key={container._id}>
                                  {modalEdit &&
                                    formData._id === container._measurement && (
                                      <ContainerEdit
                                        tableData={data}
                                        setTableData={setData}
                                        editFormData={formData}
                                        setEditFormData={setFormData}
                                        modalEdit={modalEdit}
                                        setModalEdit={setModalEdit}
                                      />
                                    )}

                                  <div
                                    style={{
                                      display:
                                        Object.keys(superContainers).length >
                                          0 &&
                                        displayContainer() !== index &&
                                        location.pathname.includes(
                                          "characterization",
                                        ) &&
                                        "none",
                                    }}
                                  >
                                    <ContainerRow
                                      containersSelected={containersSelected}
                                      setContainersSelected={
                                        setContainersSelected
                                      }
                                      setConfirmationCollectModal={
                                        setConfirmationCollectModal
                                      }
                                      data={container}
                                      displayToBlock={displayToBlock}
                                      formData={formData}
                                      setFormData={setFormData}
                                      setModalEdit={setModalEdit}
                                      fullData={fullData}
                                      setFullData={setFullData}
                                      tableData={data}
                                      setTableData={setData}
                                      settings={settings}
                                      screenWidth={screenWidth}
                                      noActivePackmat={noActivePackmat}
                                      toggleSortingErrorOverlay={
                                        toggleSortingErrorOverlay
                                      }
                                      setDataForSortingErrorOverlay={
                                        setDataForSortingErrorOverlay
                                      }
                                      toggleCompactOverlay={
                                        toggleCompactOverlay
                                      }
                                      setDataForCompactOverlay={
                                        setDataForCompactOverlay
                                      }
                                      toggleImageZoomOverlay={
                                        toggleImageZoomOverlay
                                      }
                                      toggleMapOverlay={toggleMapOverlay}
                                      setDataForMapOverlay={
                                        setDataForMapOverlay
                                      }
                                      containerActive={containerActive}
                                      setContainerActive={setContainerActive}
                                      superContainer={
                                        location.pathname.includes(
                                          "characterization",
                                        ) &&
                                        containerObjectIdList.includes(
                                          container._id,
                                        )
                                          ? superContainers
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            } else {
                              return <React.Fragment></React.Fragment>;
                            }
                          })}
                        {containerInactive.length > 0 && (
                          <div
                            className="w-full flex items-center justify-center h-10 bg-white mt-2 mb-2 cursor-pointer text-[#0F265C] rounded-lg"
                            onClick={() =>
                              setShowContainerInactive(!showContainerInactive)
                            }
                          >
                            <span>{`${t("Other containers")} (${containerInactive.length} ${t(containerInactive.length > 1 ? "inactive_plural" : "inactive_singular")})`}</span>
                            {showContainerInactive ? (
                              <i className="fas fa-chevron-down mt-1 ml-2"></i>
                            ) : (
                              <i className="fas fa-plus mt-1 ml-2"></i>
                            )}
                          </div>
                        )}
                        {showContainerInactive &&
                          containerInactive.length > 0 &&
                          containerInactive.map((container, index) => {
                            return (
                              <div key={container._id}>
                                {modalEdit &&
                                  formData._id === container._measurement && (
                                    <ContainerEdit
                                      tableData={data}
                                      setTableData={setData}
                                      editFormData={formData}
                                      setEditFormData={setFormData}
                                      modalEdit={modalEdit}
                                      setModalEdit={setModalEdit}
                                    />
                                  )}

                                <div
                                  style={{
                                    display:
                                      Object.keys(superContainers).length > 0 &&
                                      displayContainer() !== index &&
                                      location.pathname.includes(
                                        "characterization",
                                      ) &&
                                      "none",
                                  }}
                                >
                                  <ContainerRow
                                    containersSelected={containersSelected}
                                    setContainersSelected={
                                      setContainersSelected
                                    }
                                    setConfirmationCollectModal={
                                      setConfirmationCollectModal
                                    }
                                    data={container}
                                    displayToBlock={displayToBlock}
                                    formData={formData}
                                    setFormData={setFormData}
                                    setModalEdit={setModalEdit}
                                    fullData={fullData}
                                    setFullData={setFullData}
                                    tableData={data}
                                    setTableData={setData}
                                    settings={settings}
                                    screenWidth={screenWidth}
                                    noActivePackmat={noActivePackmat}
                                    toggleSortingErrorOverlay={
                                      toggleSortingErrorOverlay
                                    }
                                    setDataForSortingErrorOverlay={
                                      setDataForSortingErrorOverlay
                                    }
                                    toggleCompactOverlay={toggleCompactOverlay}
                                    setDataForCompactOverlay={
                                      setDataForCompactOverlay
                                    }
                                    toggleImageZoomOverlay={
                                      toggleImageZoomOverlay
                                    }
                                    toggleMapOverlay={toggleMapOverlay}
                                    setDataForMapOverlay={setDataForMapOverlay}
                                    containerActive={containerActive}
                                    setContainerActive={setContainerActive}
                                    superContainer={
                                      location.pathname.includes(
                                        "characterization",
                                      ) &&
                                      containerObjectIdList.includes(
                                        container._id,
                                      )
                                        ? superContainers
                                        : false
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </>
                    ) : (
                      <section className={`error-form display-error`}>
                        <p>{t("No container")}</p>
                      </section>
                    )}
                  </article>

                  {displayToBlock &&
                    screenWidth > screenWidthToForceBlockDisplay && (
                      <section
                        className="card-block-display ml-2"
                        style={{ marginTop: "8px", border: "none" }}
                      >
                        <section className="card-block">
                          <Card className="content-map">
                            {isSortingErrorOverlayActive ? (
                              <div style={{ height: "99vh" }}>
                                {dataForSortingErrorOverlay.length > 0 && (
                                  <ContainerSortingError
                                    dataForSortingErrorOverlay={
                                      dataForSortingErrorOverlay
                                    }
                                    containerData={data.find(
                                      (objet) =>
                                        objet._id ===
                                        dataForSortingErrorOverlay[0]
                                          ._measurement,
                                    )}
                                    setIsSortingErrorOverlayActive={
                                      setIsSortingErrorOverlayActive
                                    }
                                    setContainerActive={setContainerActive}
                                  />
                                )}
                              </div>
                            ) : isCompactOverlayActive ? (
                              <div style={{ height: "99vh" }}>
                                <ContainerCompact
                                  dataForCompactOverlay={
                                    dataForCompactOverlay.historyCurrent
                                  }
                                  container={dataForCompactOverlay.container}
                                  setIsCompactOverlayActive={
                                    setIsCompactOverlayActive
                                  }
                                  setContainerActive={setContainerActive}
                                  containerActive={containerActive}
                                  showPackmat={true}
                                  showLimitFillingLevel={true}
                                  showToolbox={true}
                                />
                                {containerActive.packmatActive &&
                                (containerActive.fillingAlert?.rotationAlert ||
                                  containerActive.fillingAlert?.packmatAlert ||
                                  containerActive.fillingAlert
                                    ?.packmatPossibility ||
                                  containerActive.fillingAlert?.packmatAlert !==
                                    undefined) ? (
                                  <div className="w-full px-5">
                                    <div className="data-row mt-5 border-1 border-gray-300 rounded-md p-2">
                                      {containerActive.fillingAlert
                                        ?.rotationAlert && (
                                        <div className="flex flex-row">
                                          <TruckSvg
                                            color={"#990000"}
                                            width={40}
                                            height={40}
                                            className={"mx-1 my-auto mr-3"}
                                          />
                                          <span className="mt-2">
                                            {t("Pickup to be scheduled")}
                                          </span>
                                        </div>
                                      )}
                                      {containerActive.fillingAlert
                                        ?.packmatAlert && (
                                        <div className="flex flex-row">
                                          <img
                                            src={excavatorRed}
                                            alt="Packmat Icon"
                                            style={{ height: 40, zIndex: 10 }}
                                          />
                                          <div className="flex flex-col ml-3">
                                            <span>{t("Packmat expected")}</span>
                                            <span>
                                              {t(
                                                `${t("Last compaction/rotation at level")}: ${containerActive.fillingAlert?.lastPackmatLevel}%`,
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      {!containerActive.fillingAlert
                                        ?.packmatAlert &&
                                        containerActive.fillingAlert
                                          ?.packmatPossibility && (
                                          <div className="flex flex-row">
                                            <img
                                              src={excavator}
                                              alt="Packmat Icon"
                                              style={{ height: 40, zIndex: 10 }}
                                            />
                                            <div className="flex flex-col ml-3">
                                              <span>
                                                {t("Possibility of packmat")}
                                              </span>
                                              <span>
                                                {`${t("Last compaction/rotation at level")}: ${containerActive.fillingAlert?.lastPackmatLevel}%`}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                      {!containerActive.fillingAlert
                                        ?.packmatPossibility &&
                                        !containerActive.fillingAlert
                                          ?.packmatAlert &&
                                        !containerActive.fillingAlert
                                          ?.rotationAlert && (
                                          <div className="flex flex-row">
                                            <img
                                              src={excavatorGrey}
                                              alt="Packmat Icon"
                                              style={{ height: 40, zIndex: 10 }}
                                            />
                                            <div className="flex flex-col ml-3">
                                              <span>
                                                {t("No packmat expected")}
                                              </span>
                                              <span>
                                                {`${t("Last compaction at level")}: ${containerActive.fillingAlert?.lastPackmatLevel}%`}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : isImageZoomOverlayActive ? (
                              <div style={{ height: "99vh" }}>
                                <ContainerImageZoom
                                  setIsImageZoomOverlayActive={
                                    setIsImageZoomOverlayActive
                                  }
                                  setContainerActive={setContainerActive}
                                  containerActive={containerActive}
                                />
                              </div>
                            ) : isMapOverlayActive ? (
                              <MapDisplay
                                fillingRateDelivery={fillingRateDeliveryMap}
                                markers={dataForMapOverlay}
                                mapHeight={"99vh"}
                                isMapOverlayActive={isMapOverlayActive}
                                setIsMapZoomOverlayActive={
                                  setIsMapZoomOverlayActive
                                }
                                setContainerActive={setContainerActive}
                                scrollToContainer={scrollToContainer}
                                cleanFilters={cleanFilters}
                                isContainer={true}
                                fullData={fullData}
                                orderSort={orderSort}
                                setCountData={setCountData}
                                setData={setData}
                              />
                            ) : (
                              <MapDisplay
                                fillingRateDelivery={fillingRateDeliveryMap}
                                markers={data}
                                mapHeight={"99vh"}
                                setContainerActive={setContainerActive}
                                scrollToContainer={scrollToContainer}
                                cleanFilters={cleanFilters}
                                isContainer={true}
                                fullData={fullData}
                                orderSort={orderSort}
                                setCountData={setCountData}
                                setData={setData}
                              />
                            )}
                          </Card>
                        </section>
                      </section>
                    )}
                </section>

                {screenWidth > screenWidthToForceBlockDisplay && (
                  <button
                    className="button-map-list"
                    onClick={() => {
                      handleDisplayMode();
                    }}
                  >
                    {displayToBlock
                      ? `${t("Show list")} `
                      : `${t("Show map")} `}
                    <i
                      style={{ marginLeft: "10px", marginTop: "4px" }}
                      className={` ${!displayToBlock && displayMode === "table" ? "fas fa-map-marked" : "fas fa-list"}`}
                    ></i>
                  </button>
                )}
              </div>
            )}

            {confimationCollectModal && (
              <ConfirmationModal
                open={confimationCollectModal}
                setOpen={setConfirmationCollectModal}
                title={t("Schedule a rotation")}
                message={`${t("Are you sure you want to schedule the rotation")} ?`}
                submitText={t("Schedule a rotation")}
                handleSubmit={() => {
                  sendPlanningMail(containersSelected, "rotation");
                  const newData = data.forEach((elt) => {
                    containersSelected.forEach((container) => {
                      if (elt._id === container._id) {
                        elt.status = "remove_driver_exchange";
                      }
                    });
                  });
                  setData(newData);
                  handleDeselectAll(data, setData, setContainersSelected);
                }}
                close={() =>
                  handleDeselectAll(data, setData, setContainersSelected)
                }
              />
            )}
            <ToastContainer autoClose={3000} style={{ marginTop: "60px" }} />
          </>
        )}
      </div>
    );
  }
};

export default withRouter(Container);
