import React, { useEffect, useState } from "react";
import { MdNotificationsActive } from "react-icons/md";
import {
  elapsedTimeContainer,
  formattedDate,
  formattedTime,
  formattedDuration,
} from "../../components/formatted/FormattedDateAndTime";
import {
  formattedLevelColor,
  formattedStatus,
  formattedTypeContainer,
  toFrenchNumber,
} from "../../components/formatted/FormattedText";
import CharacterisationSvg from "../../components/utils/CharacterisationSvg";
import { setStatusColor } from "../../shared/utils/utils";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Tooltip, Typography } from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import CustomPopover from "../../components/popover/CustomPopover";
import { CropImage } from "../../components/utils/ImageTreatment";
import { StarRatingIcon } from "./ContainerIcons";
import { truncateText } from "../../components/formatted/FormattedText";
import myPackmatIcon from "../../static/assets/CompactingIcon.jpg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TruckSvg from "../../components/utils/TruckSvg";
import excavator from "../../static/assets/excavator.png";
import excavatorRed from "../../static/assets/excavatorRed.png";
import excavatorGrey from "../../static/assets/excavatorGrey.png";

const ContainerTableView = ({
  data,
  deltaAlert,
  settings,
  setFillingWeightColor,
  weightKG,
  weightToTonnes,
  imageWidth,
  imageHeight,
  numberOfStars,
  numberOfAnomaly,
  numberOfPackmat,
  durationFullFillingMs,
  fromDate,
  isLoadingHistoryCycleContainer,
  currentDate,
  noActivePackmat,
  fillingAlert,
  totalVolumeSortingError,
  totalWasteVolume,
}) => {
  const { t, i18n } = useTranslation();
  const [characterizationInfo, setCharacterizationInfo] = useState(false);
  const [popoverContent, setPopoverContent] = useState(null);
  const [popoverLine, setPopoverLine] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const containersData = useSelector((state) => state.containers.data);
  const [dataContainer, setDataContainer] = useState({});

  useEffect(() => {
    if (Object.keys(containersData).length !== 0) {
      if (data._id in containersData) {
        const containerActive = containersData[data._id];
        setDataContainer(containerActive);
      }
    }
  }, [containersData, data]);

  const handlePopoverOpen = (event, content, content2) => {
    setPopoverContent(content);
    setPopoverLine(content2);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columns={{ xs: 10 }}>
        <Grid item xs={1.3}>
          <div className="w-full">
            {data.image_url && (
              <CropImage
                container={data}
                data={data}
                imgDivWidth={imageWidth}
                imgDivHeight={imageHeight}
                isTooltip={true}
                isMap={false}
                isCharacterization={false}
                displayLegend={false}
                materialsDropdownToDisplay={[]}
                showCheckbox={false}
              />
            )}
          </div>
        </Grid>

        <Grid item xs={1} sx={{ marginTop: 5 }}>
          {deltaAlert > settings.alertNumberOfDays ? (
            <Grid container alignItems="center">
              <Grid item>
                <MdNotificationsActive
                  className="notification-bell"
                  size={15}
                  style={{ color: "#DA0000" }}
                />
              </Grid>

              <Grid item>
                <Typography
                  variant="caption"
                  gutterBottom
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#DA0000",
                    letterSpacing: "-0.4px",
                  }}
                >
                  {elapsedTimeContainer(data._time, i18n.language, true)}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography
              variant="caption"
              gutterBottom
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                letterSpacing: "-0.4px",
              }}
            >
              {elapsedTimeContainer(data._time, i18n.language, true)}
            </Typography>
          )}

          <Typography
            className="text-grey"
            variant="caption"
            display="block"
            gutterBottom
            style={{
              fontSize: "12px",
              letterSpacing: "-0.4px",
              marginTop: 1,
            }}
          >
            {formattedDate(data._time, i18n.language)},{" "}
            {formattedTime(data._time)}
          </Typography>
        </Grid>

        <Grid item xs={1.2} marginTop={5.5}>
          {data?.positioning && data.positioning !== "" && (
            <Typography
              variant="caption"
              gutterBottom
              component="div"
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                letterSpacing: "-0.4px",
                lineHeight: "1.4",
                maxHeight: "2.6em",
                overflow: "hidden",
                whiteSpace: "pre-line",
              }}
            >
              {data.positioning}
            </Typography>
          )}
          <Typography
            variant="caption"
            gutterBottom
            component="div"
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              letterSpacing: "-0.4px",
              lineHeight: "1.4",
              maxHeight: "2.6em",
              overflow: "hidden",
              whiteSpace: "pre-line",
            }}
            onMouseEnter={(event) =>
              handlePopoverOpen(
                event,
                data.addressName ? data.addressName : "-",
              )
            }
            onMouseLeave={handlePopoverClose}
          >
            {truncateText(data.addressName, 20) || "-"}
          </Typography>
        </Grid>

        <Grid
          item
          xs={1}
          marginTop={5}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginLeft: 2,
          }}
        >
          <Typography
            variant="caption"
            gutterBottom
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              whiteSpace: "nowrap",
              letterSpacing: "-0.4px",
            }}
            onMouseEnter={(event) =>
              handlePopoverOpen(event, data.wasteName || "-")
            }
            onMouseLeave={handlePopoverClose}
          >
            {data.wasteName || "-"}
          </Typography>

          {data.activatedFillingLevelOption ? (
            <div className="data-row">
              <Typography
                variant="caption"
                gutterBottom
                style={{ fontSize: "11px", marginRight: 2 }}
              >
                {data.filling_level + "%"}
              </Typography>
              <div className="level-content" style={{ marginTop: 5 }}>
                <div
                  className={`level-padding ${formattedLevelColor(data.filling_level, data.limitFillingLevel)}`}
                  style={{ width: data.filling_level + "%" }}
                />
              </div>
            </div>
          ) : (
            <div className="data-row">
              <Tooltip title={t("filling_level_config_required")}>
                <InfoIcon style={{ color: "#888", cursor: "pointer" }} />
              </Tooltip>
            </div>
          )}
        </Grid>

        <Grid item xs={0.7} marginTop={5.6} style={{ letterSpacing: "-0.4px" }}>
          <div className="flex flex-col">
            <div className="flex whitespace-nowrap overflow-hidden text-ellipsis">
              <span
                className={`mr-1 text-xs font-semibold ${setFillingWeightColor(weightKG, data.fullWeight, data.weightUnit)}`}
              >
                {weightKG &&
                  toFrenchNumber(weightToTonnes(weightKG, data.weightUnit), 1)}
              </span>
              <span className="text-grey text-xs font-semibold">
                / {weightKG && data.fullWeight > 0 ? data.fullWeight : "-"}
              </span>
            </div>
            <span className="text-grey text-xs" style={{ marginTop: 3.5 }}>
              {data.weightUnit === "kg" ? t("Kilogram") : t("ton")}
              {weightToTonnes(weightKG, data.weightUnit) > 1 && "s"}
            </span>
          </div>
        </Grid>

        {!noActivePackmat && (
          <Grid
            item
            xs={0.5}
            marginTop={5.6}
            style={{ letterSpacing: "-0.4px" }}
          >
            {data.packmatActive &&
              (!isLoadingHistoryCycleContainer ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={myPackmatIcon}
                    alt="Packmat Icon"
                    style={{
                      height: 18,
                      verticalAlign: "middle",
                      marginRight: 5,
                    }}
                  />
                  <span
                    className="text"
                    style={{ marginLeft: 5, fontSize: 12 }}
                  >
                    {numberOfPackmat}
                  </span>
                </span>
              ) : (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={myPackmatIcon}
                    alt="Packmat Icon"
                    style={{
                      height: 18,
                      verticalAlign: "middle",
                      marginRight: 5,
                    }}
                  />
                  <span
                    className="text"
                    style={{
                      marginLeft: 5,
                      fontSize: 12,
                      color: "var(--gainsboro)",
                    }}
                  >
                    .
                  </span>
                </span>
              ))}
          </Grid>
        )}

        <Grid item xs={0.9} marginTop={4.95}>
          <div className="ml-3">
            {!isLoadingHistoryCycleContainer &&
            data.activatedSortingErrorOption ? (
              <StarRatingIcon
                numberOfStars={numberOfStars}
                text={
                  numberOfAnomaly +
                  " " +
                  (numberOfAnomaly > 1
                    ? t("sorting_errors")
                    : t("sorting_error"))
                }
                alignTextAboveStars={true}
                color={"grey"}
                textSize={12}
                iconSize={18}
                percentage={(totalVolumeSortingError * 100) / totalWasteVolume}
              />
            ) : (
              <StarRatingIcon
                numberOfStars={
                  data.activatedSortingErrorOption ? numberOfStars : 0
                }
                text={"."}
                alignTextAboveStars={true}
                color={"var(--gainsboro)"}
                textSize={12}
                iconSize={18}
              />
            )}
          </div>
        </Grid>

        <Grid item xs={0.8} marginTop={4.95}>
          <div>
            <Grid container>
              <Grid item>
                <Typography
                  variant="caption"
                  gutterBottom
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    letterSpacing: "-0.4px",
                  }}
                >
                  {formattedTypeContainer(data.type)}
                </Typography>
              </Grid>
              <Grid item>
                {data.characterization && (
                  <div
                    className="mx-2 relative"
                    onMouseOver={() => setCharacterizationInfo(true)}
                    onMouseOut={() => setCharacterizationInfo(false)}
                  >
                    <CharacterisationSvg
                      width={24}
                      height={32}
                      widthViewBox={24}
                      heightViewBox={32}
                    />
                    {characterizationInfo && (
                      <span className="absolute bg-yellow px-2 text-white -top-5 text-center rounded -right-36 ">
                        {t("Characterization activated")}
                      </span>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
            <span className="data-column text-xs">
              {data.volume > 0
                ? data.volume + "" + (data.volumeUnit || "m3")
                : "-"}
            </span>
          </div>
        </Grid>

        <Grid item xs={0.8} marginTop={5.6} style={{ letterSpacing: "-0.4px" }}>
          {data.filling_level === 100 ? (
            <div className="data-prevision text-xs font-bold whitespace-normal overflow-hidden">
              Max atteint
            </div>
          ) : dataContainer?.reliablePrediction === true &&
            durationFullFillingMs != null &&
            (currentDate?.getTime() - fromDate?.getTime()) /
              (1000 * 3600 * 24) >
              60 ? (
            <div
              className="data-prevision text-xs font-bold whitespace-normal overflow-hidden"
              style={{ whiteSpace: "pre-line" }}
            >
              {formattedDuration(durationFullFillingMs[0].duration)
                .split(",")
                .map((part, index, array) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {part}
                    {index === 0 && array.length > 1 && ","}
                  </React.Fragment>
                ))}
            </div>
          ) : (
            <div className="data-prevision text-xs font-bold whitespace-normal overflow-hidden">
              -
            </div>
          )}
        </Grid>

        <Grid item xs={0.5} marginTop={5.6}>
          {data.packmatActive &&
          (fillingAlert?.rotationAlert ||
            fillingAlert?.packmatAlert ||
            fillingAlert?.packmatPossibility ||
            fillingAlert?.packmatAlert !== undefined) ? (
            <div className="data-row">
              {fillingAlert?.rotationAlert && (
                <div
                  style={{ zIndex: 10 }}
                  onMouseEnter={(event) =>
                    handlePopoverOpen(event, t("Pickup to be scheduled"))
                  }
                  onMouseLeave={handlePopoverClose}
                >
                  <TruckSvg
                    color={"#990000"}
                    width={25}
                    height={25}
                    className={"mx-1 my-auto"}
                  />
                </div>
              )}
              {fillingAlert?.packmatAlert && (
                <img
                  src={excavatorRed}
                  alt="Packmat Icon"
                  style={{ height: 25, zIndex: 10 }}
                  onMouseEnter={(event) =>
                    handlePopoverOpen(
                      event,
                      t("Packmat expected"),
                      `${t("Last compaction/rotation at level")}: ${fillingAlert?.lastPackmatLevel}%`,
                    )
                  }
                  onMouseLeave={handlePopoverClose}
                />
              )}
              {!fillingAlert?.packmatAlert &&
                fillingAlert?.packmatPossibility && (
                  <img
                    src={excavator}
                    alt="Packmat Icon"
                    style={{ height: 25, zIndex: 10 }}
                    onMouseEnter={(event) =>
                      handlePopoverOpen(
                        event,
                        t("Possibility of packmat"),
                        `${t("Last compaction/rotation at level")}: ${fillingAlert?.lastPackmatLevel}%`,
                      )
                    }
                    onMouseLeave={handlePopoverClose}
                  />
                )}
              {!fillingAlert?.packmatPossibility &&
                !fillingAlert?.packmatAlert &&
                !fillingAlert?.rotationAlert && (
                  <img
                    src={excavatorGrey}
                    alt="Packmat Icon"
                    style={{ height: 25, zIndex: 10 }}
                    onMouseEnter={(event) =>
                      handlePopoverOpen(
                        event,
                        t("No packmat expected"),
                        `${t("Last compaction at level")}: ${fillingAlert?.lastPackmatLevel}%`,
                      )
                    }
                    onMouseLeave={handlePopoverClose}
                  />
                )}
            </div>
          ) : (
            <span className="data-column text-xs">-</span>
          )}
        </Grid>

        <Grid item xs={1} marginTop={5.35}>
          <div className="data-row ml-1">
            <span
              className={`w-3 h-3 rounded-full ${setStatusColor(data.status)} mr-1`}
            />
            <Typography
              className="text-grey whitespace-nowrap ml-1 overflow-hidden text-ellipsis"
              variant="caption"
              maxWidth={"70%"}
              gutterBottom
              style={{ fontSize: "12px" }}
              zIndex={1}
              onMouseEnter={(event) =>
                handlePopoverOpen(event, formattedStatus(data.status))
              }
              onMouseLeave={handlePopoverClose}
            >
              {formattedStatus(data.status)}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <CustomPopover
        open={open}
        anchorEl={anchorEl}
        content={popoverContent}
        content2={popoverLine}
        onClose={handlePopoverClose}
      />
    </Box>
  );
};

export default ContainerTableView;
