import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";
import {
  monthNamesEn,
  monthNamesEs,
  monthNamesFr,
} from "../../../shared/utils/utils";

// Enregistrer les composants nécessaires de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const WasteHistogram = ({ data, selectedMaterial, selectedFlux }) => {
  const { t, i18n } = useTranslation();
  const filteredData = useMemo(() => {
    return data
      .filter((entry) => entry.wasteCategoryId === selectedFlux)
      .map((entry) => {
        const totalWaste = Object.values(entry.wasteData).reduce(
          (sum, val) => sum + val,
          0,
        );
        const materialValue = entry.wasteData[selectedMaterial] || 0;

        return {
          month: `${entry.month}/${entry.year}`,
          value: (materialValue / totalWaste) * 100,
        };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));
  }, [data, selectedMaterial, selectedFlux]);

  // Configuration des données du graphique
  const chartData = {
    labels: filteredData.map((item) => item.month),
    datasets: [
      {
        label: `${t(selectedMaterial)} (%)`,
        data: filteredData.map((item) => item.value),
        backgroundColor: "rgba(59, 130, 246, 0.6)",
        borderColor: "rgba(59, 130, 246, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Options du graphique
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: {
        display: false,
        text: `${t("Evolution of")} ${t(selectedMaterial)} (${selectedFlux})`,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          callback: function (value, index, values) {
            const dateStr = filteredData[index]?.month; // "10/2024"
            if (!dateStr) return "";
            const [month, year] = dateStr.split("/");
            const monthNames =
              i18n.language === "en"
                ? monthNamesEn
                : i18n.language === "fr"
                  ? monthNamesFr
                  : monthNamesEs;
            return `${monthNames[parseInt(month, 10) - 1]} ${year}`;
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `${value.toFixed(1)}%`; // Afficher 1 décimale avec le %
          },
        },
        title: { display: false, text: "Proportion" },
      },
    },
  };

  return (
    <div className="p-6 " style={{ height: "400px" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default WasteHistogram;
