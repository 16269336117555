import { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { capitalizeFirstLetter } from "../utils/Date";
import {
  getMonthLabel,
  getWeekNumber,
} from "../formatted/FormattedDateAndTime";
import { useTranslation } from "react-i18next";

export const HeatMapChart = ({
  anomalies,
  viewMode = "day",
  currentPage,
  weeksPerPage,
  setTotalPages,
  setWeeksPageLabel,
}) => {
  const { t, i18n } = useTranslation();
  const chartRef = useRef(null);

  useEffect(
    () => {
      if (
        !chartRef.current ||
        !anomalies ||
        Object.keys(anomalies).length === 0
      )
        return;
      const anomaliesChart = echarts.init(chartRef.current);

      const daysOfWeek = [
        t("Monday"),
        t("Tuesday"),
        t("Wednesday"),
        t("Thursday"),
        t("Friday"),
        t("Saturday"),
        t("Sunday"),
      ];

      const getCorrectDay = (datetime) => {
        const date = new Date(datetime);
        return daysOfWeek[(date.getDay() + 6) % 7];
      };

      const getLabels = (anomalies, mode) => {
        const labelsSet = new Set();
        Object.values(anomalies).forEach((entries) => {
          entries.forEach(({ datetime }) => {
            const date = new Date(datetime);
            if (mode === "day") labelsSet.add(getCorrectDay(date));
            if (mode === "week")
              labelsSet.add(getWeekNumber(date, i18n.language));
            if (mode === "month")
              labelsSet.add(
                capitalizeFirstLetter(getMonthLabel(date, i18n.language)),
              );
          });
        });

        let labels = Array.from(labelsSet);

        return labels.sort((a, b) => {
          const getDateFromLabel = (label) => {
            const parts = label.split(" ");
            const year = parseInt(parts[1], 10);
            const week = parts[0].startsWith(
              `${i18n.language === "en" ? "W" : i18n.language === "es" ? "S" : "S"}-`,
            )
              ? parseInt(parts[0].slice(2), 10)
              : 0;
            return new Date(year, 0, (week - 1) * 7 + 1);
          };
          return getDateFromLabel(a) - getDateFromLabel(b);
        });
      };

      const processAnomalies = (anomalies, mode) => {
        const wasteCategories = Object.keys(anomalies);
        let timeLabels =
          mode === "day" ? daysOfWeek : getLabels(anomalies, mode);

        if (mode === "week") {
          setTotalPages(Math.ceil(timeLabels.length / weeksPerPage));
          const startIndex = currentPage * weeksPerPage;
          const endIndex = startIndex + weeksPerPage;
          timeLabels = timeLabels.slice(startIndex, endIndex);

          const [startWeek, startYear] = timeLabels[0].split("-")[1].split(" ");
          const [endWeek, endYear] = timeLabels[timeLabels.length - 1]
            .split("-")[1]
            .split(" ");

          setWeeksPageLabel(
            `${capitalizeFirstLetter(t("week"))} ${startWeek} (${startYear}) ${i18n.language === "fr" ? "au" : t("to")} ${capitalizeFirstLetter(t("week"))} ${endWeek} (${endYear})`,
          );
        }

        const result = [];
        wasteCategories.forEach((category, yIndex) => {
          timeLabels.forEach((label, xIndex) => {
            const filteredData = anomalies[category].filter((item) => {
              const date = new Date(item.datetime);
              if (mode === "day") return getCorrectDay(date) === label;
              if (mode === "week")
                return getWeekNumber(date, i18n.language) === label;
              if (mode === "month")
                return (
                  capitalizeFirstLetter(getMonthLabel(date, i18n.language)) ===
                  capitalizeFirstLetter(label)
                );
              return false;
            });

            const total = filteredData.reduce(
              (sum, entry) =>
                sum + Object.values(entry.total).reduce((a, b) => a + b, 0),
              0,
            );

            const count = filteredData.length;
            let value = 0;
            if (viewMode === "day") {
              value = count > 0 ? Math.round(total / count) : 0;
            } else {
              value = total;
            }
            result.push([xIndex, yIndex, value]);
          });
        });

        return { wasteCategories, timeLabels, result };
      };

      const { wasteCategories, timeLabels, result } = processAnomalies(
        anomalies,
        viewMode,
      );

      const option = {
        tooltip: { position: "top" },
        grid: { height: "70%", top: "10%", left: "15%" },
        xAxis: {
          type: "category",
          data: timeLabels,
          splitArea: { show: true },
        },
        yAxis: {
          type: "category",
          data: wasteCategories,
          splitArea: { show: true },
          axisLabel: {
            show: true,
            interval: 0,
            formatter: function (value) {
              return value.length > 10 ? value.substring(0, 10) + "..." : value;
            },
            width: 80,
            overflow: "truncate",
          },
        },
        visualMap: {
          show: false,
          min: 0,
          max: Math.max(...result.map((d) => d[2]), 1),
          calculable: true,
          orient: "horizontal",
          left: "center",
          top: "0%",
        },
        series: [
          {
            type: "heatmap",
            data: result,
            label: { show: true },
            emphasis: {
              itemStyle: { shadowBlur: 10, shadowColor: "rgba(0, 0, 0, 0.5)" },
            },
          },
        ],
      };

      anomaliesChart.setOption(option);
      window.addEventListener("resize", () => anomaliesChart.resize());

      return () => {
        anomaliesChart.dispose();
        window.removeEventListener("resize", () => anomaliesChart.resize());
      };
    },
    // eslint-disable-next-line
    [anomalies, viewMode, currentPage, i18n.language],
  );

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
      }}
    />
  );
};
