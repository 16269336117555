import React from "react";
import {
  capitalizeFirstLetter,
  currentMonthName,
  previousMonthName,
} from "../../components/utils/Date";
import Multiselect from "multiselect-react-dropdown";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calendar } from "react-calendar";
import { formattedDate } from "../../components/formatted/FormattedDateAndTime";

const StatisticsFilter = ({
  labeledkeywordFilterOptions,
  multiselectKeywordRef,
  keywordFilter,
  setKeywordFilter,
  onChangeCalendar,
  displayCalendar,
  setDisplayCalendar,
  periodHistoryStart,
  periodHistoryStop,
  selectPeriodFilter,
  selectedPeriod,
  filterRef,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full flex flex justify-between gap-2" ref={filterRef}>
      <div className="w-7/12 flex flex-row gap-2">
        <div
          className={`rounded-sm ${selectedPeriod === "0" ? "bg-[#00a7a6] text-white" : "bg-white"} py-1.5 px-1.5 cursor-pointer`}
          onClick={() => selectPeriodFilter("0")}
        >
          <span className="text-sm mx-2">
            {t("Current month")} :{" "}
            {capitalizeFirstLetter(currentMonthName(i18n.language))}
          </span>
        </div>
        <div
          className={`rounded-sm ${selectedPeriod === "1" ? "bg-[#00a7a6] text-white" : "bg-white"} py-1.5 px-1.5 cursor-pointer`}
          onClick={() => selectPeriodFilter("1")}
        >
          <span className="text-sm mx-2">
            {t("Last month")} :{" "}
            {capitalizeFirstLetter(previousMonthName(i18n.language))}
          </span>
        </div>
        <div
          className={`rounded-sm ${selectedPeriod === "-3mo" ? "bg-[#00a7a6] text-white" : "bg-white"} py-1.5 px-1.5 cursor-pointer`}
          onClick={() => selectPeriodFilter("-3mo")}
        >
          <span className="text-sm mx-2">{t("Last 3 months")}</span>
        </div>
        <div
          className={`rounded-sm ${selectedPeriod === "-6mo" ? "bg-[#00a7a6] text-white" : "bg-white"} py-1.5 px-1.5 cursor-pointer`}
          onClick={() => selectPeriodFilter("-6mo")}
        >
          <span className="text-sm mx-2">{t("Last 6 months")}</span>
        </div>
        <div
          className={`rounded-sm ${selectedPeriod === "-12mo" ? "bg-[#00a7a6] text-white" : "bg-white"} py-1.5 px-1.5 cursor-pointer`}
          onClick={() => selectPeriodFilter("-12mo")}
        >
          <span className="text-sm mx-2">{t("Last 12 months")}</span>
        </div>
      </div>
      <div className="w-2.5/12 flex flex-row items-center mr-3">
        <div className="flex w-full flex flex-row items-center gap-1">
          <Input
            onClick={() => {
              setDisplayCalendar(true);
            }}
            value={`${formattedDate(periodHistoryStart, i18n.language)} ${i18n.language === "fr" ? (new Date().toDateString() === new Date(periodHistoryStop).toDateString() ? "à" : "au") : t("to")} ${formattedDate(periodHistoryStop, i18n.language)}`}
            type="text"
            onChange={(event) => {}}
            className={`text-sm ${selectedPeriod === "calendar" ? "bg-[#00a7a6] text-white" : "bg-white"}`}
          />
          <i
            onClick={() => {
              setDisplayCalendar(true);
            }}
            className={`fas fa-calendar-alt text-3xl hover:text-[#00a7a6] cursor-pointer ${selectedPeriod === "calendar" ? "text-[#00a7a6]" : "text-gray-500"}`}
          ></i>
        </div>
        <div
          className="z-10 shadow-xl	absolute top-64 right-64 mt-1 right-6 rounded bg-white px-3"
          style={displayCalendar ? { display: "block" } : { display: "none" }}
        >
          <div
            onClick={() => {
              setDisplayCalendar(false);
            }}
            className="form-calendar-close"
          >
            <span className="text-sm">Fermer</span>
            <FontAwesomeIcon icon="times" />
          </div>
          <Calendar
            onChange={(event) => {
              onChangeCalendar(event);
              selectPeriodFilter("calendar");
            }}
            selectRange={true}
            maxDate={null}
            closeCalendar={true}
            defaultValue={[
              new Date(periodHistoryStart),
              new Date(periodHistoryStop),
            ]}
          />
        </div>
      </div>
      <div className="w-3/12 flex items-center">
        <div className="flex flex-col w-full">
          <Multiselect
            options={labeledkeywordFilterOptions}
            groupBy="cat"
            displayValue="name"
            onSelect={(value) => setKeywordFilter({ value })}
            onRemove={(value) => setKeywordFilter({ value })}
            hidePlaceholder={true}
            emptyRecordMsg={t("No available option")}
            placeholder={t("Advanced filter")}
            closeIcon="circle"
            selectedValues={keywordFilter.value}
          />
          {keywordFilter.length > 0 ? (
            <i
              className="fas fa-trash absolute top-3 right-2"
              onClick={() => {
                multiselectKeywordRef.current.resetSelectedValues();
                setKeywordFilter([]);
              }}
            ></i>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StatisticsFilter;
