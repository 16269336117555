import { useEffect } from "react";
import * as echarts from "echarts";

export const PieChart = ({
  chartRef,
  dataChart,
  height,
  width,
  fontSizeLabel,
  legend,
  radius,
}) => {
  useEffect(
    () => {
      const pieChart = document.getElementById("pie-chart");
      const chart = echarts.init(pieChart);

      let option;
      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: legend,
          orient: "horizontal",
          bottom: "bottom",
        },
        series: [
          {
            type: "pie",
            radius: radius ? radius : "80%",
            label: {
              formatter: "{b} ({c}%)",
              fontSize: fontSizeLabel,
              fontWeight: 400,
              textAlign: "left",
            },
            data: dataChart,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      option && chart.setOption(option);
    },
    // eslint-disable-next-line
    [dataChart],
  );

  return (
    <div
      id="pie-chart"
      ref={chartRef}
      style={{
        height: height,
        width: width,
      }}
    ></div>
  );
};
