import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../../components/utils/Loading";
import {
  formattedColorMaterial,
  formattedWasteColor,
} from "../../../components/formatted/FormattedText";
import MultipleLineAndBarChart from "../../../components/charts/MultipleLineAndBarChart";
import { getWasteStatistic } from "../../../services/container";
import { useTranslation } from "react-i18next";
import WasteHistogram from "./StatisticsMonthlyDistribution";
import { HeatMapChart } from "../../../components/charts/HeatMapChart";
import { MaterialCountingPieChart } from "../../../components/charts/MaterialCountingPieChart";
import { capitalizeFirstLetter } from "../../../components/utils/Date";

function mergeFluxes(initialObj, mergeConfig) {
  const newObj = {};

  // Copy initialObj to newObj
  Object.keys(initialObj).forEach((key) => {
    newObj[key] = initialObj[key];
  });

  // Merge the specified objects
  mergeConfig.forEach((merge) => {
    const { referenceName, possibleNames } = merge;

    // Initialize the merged object if it does not exist
    if (newObj[referenceName] === undefined) {
      newObj[referenceName] = [];
    }

    // Remove referenceName from possibleNames if it exists
    const referenceIndex = possibleNames.indexOf(referenceName);
    if (referenceIndex > -1) {
      possibleNames.splice(referenceIndex, 1);
    }

    // Loop through each object to be merged
    possibleNames.forEach((name) => {
      const obj = newObj[name];
      if (!obj) return;

      // Loop through each element in the object
      obj.forEach((elem) => {
        const existingElemIndex = newObj[referenceName].findIndex(
          (x) => x.flux === elem.flux,
        );
        if (existingElemIndex === -1) {
          // If element doesn't exist in the merged object, add it
          newObj[referenceName].push({ ...elem });
        } else {
          // Otherwise, merge the countAnomaly property
          newObj[referenceName][existingElemIndex].countAnomaly +=
            elem.countAnomaly;
        }
      });

      if (referenceName !== name) {
        // Remove the merged object from newObj
        delete newObj[name];
      }
    });

    if (newObj[referenceName].length === 0) {
      delete newObj[referenceName];
    }
  });

  return newObj;
}

const StatisticsChartComponent = ({
  periodHistoryStart,
  periodHistoryStop,
  keywordFilter,
  handleValidate,
  setHandleValidate,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [totalSortingErrorCurrent, setTotalSortingErrorCurrent] = useState(0);
  const [dataSortingError, setDataSortingError] = useState({});
  const [monthlyCaracterisation, setMonthlyCaracterisation] = useState([]);

  const [materialsList, setMaterialsList] = useState([]);
  const [fluxesList, setFluxesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tooManyReviews, setTooManyReviews] = useState({
    numberOfReviews: 0,
    maxNumberOfReviews: 100,
  });

  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [selectedFlux, setSelectedFlux] = useState("");
  const [selectedFluxSortingErrorPie, setSelectedFluxSortingErrorPie] =
    useState("");

  const [totalAnomalies, setTotalAnomalies] = useState({});

  const [currentPage, setCurrentPage] = useState(0);
  const weeksPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);
  const [weeksPageLabel, setWeeksPageLabel] = useState("");

  //Chart variable construction
  const dataSetsSortingErrorByFluxChart = [];
  const labelsByTimeChart = [];
  const dataSetsDistributionSortingError = [];

  useEffect(
    () => {
      setHandleValidate(true);
    },
    // eslint-disable-next-line
    [],
  );

  const fetchData = () => {
    setIsLoading(true);
    axios
      .all([
        getWasteStatistic({
          periodStart: periodHistoryStart,
          periodStop: periodHistoryStop,
          keywordFilter: JSON.stringify(keywordFilter.value),
        }),
      ])
      .then(
        axios.spread((responseWasteStatistics) => {
          if (responseWasteStatistics.status === 200) {
            setTotalAnomalies(
              responseWasteStatistics.data.statisticsData.totalAnomalies,
            );
            setData(responseWasteStatistics.data.statisticsData.dataResult);

            setMonthlyCaracterisation(
              responseWasteStatistics.data.statisticsData
                .monthlyCaracterisation,
            );

            // Extraction des matières uniques
            setMaterialsList([
              ...new Set(
                responseWasteStatistics.data.statisticsData.monthlyCaracterisation.flatMap(
                  (entry) => Object.keys(entry.wasteData),
                ),
              ),
            ]);

            // Extraction des labels de flux uniques
            setFluxesList([
              ...new Map(
                responseWasteStatistics.data.statisticsData.monthlyCaracterisation.map(
                  (entry) => [
                    entry.wasteCategoryId,
                    { label: entry.label, id: entry.wasteCategoryId },
                  ],
                ),
              ).values(),
            ]);

            setSelectedFlux(
              responseWasteStatistics.data.statisticsData
                .monthlyCaracterisation[0].wasteCategoryId,
            );

            setSelectedFluxSortingErrorPie(
              responseWasteStatistics.data.statisticsData
                .monthlyCaracterisation[0].label,
            );

            setSelectedMaterial(
              Object.keys(
                responseWasteStatistics.data.statisticsData
                  .monthlyCaracterisation[0].wasteData,
              )[0],
            );

            const mergeConfig = JSON.parse(
              localStorage.getItem("materialsDescription"),
            ).map((v) => ({
              referenceName: v.referenceName,
              possibleNames: v.possibleNames,
            }));
            setDataSortingError(
              mergeFluxes(
                responseWasteStatistics.data.statisticsData.dataSortingError,
                mergeConfig,
              ),
            );

            setTotalSortingErrorCurrent(
              responseWasteStatistics.data.statisticsData.countAnomalies,
            );
            setHandleValidate(false);
            setIsLoading(false);
          }
        }),
      )
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 413) {
            setIsLoading(false);
            setTooManyReviews({
              numberOfReviews: error.response.data.numberOfReviews,
              maxNumberOfReviews: error.response.data.maxNumberOfReviews,
            });
          }
        }
        console.error("Error occurred:", error);
      });
  };

  useEffect(
    () => {
      if (handleValidate) {
        fetchData();
      }
    },
    // eslint-disable-next-line
    [handleValidate],
  );

  //loop data to get dataSetsSortingErrorByFluxChart and labelsByTimeChart
  Object.keys(data).forEach((flux) => {
    dataSetsSortingErrorByFluxChart.push({
      label: flux,
      data: data[flux].monthlyDistribution.map((item) => ({
        x: item.time,
        y: item.countAnomalies,
        z: flux,
      })),
      backgroundColor: formattedWasteColor(flux),
      type: "bar",
    });

    for (let i = 0; i < data[flux].monthlyDistribution.length; i++) {
      if (!labelsByTimeChart.includes(data[flux].monthlyDistribution[i].time)) {
        labelsByTimeChart.push(data[flux].monthlyDistribution[i].time);
      }
    }
    return null;
  });

  const extractFluxData = (data, targetFlux) => {
    const labels = [];
    const values = [];
    const colorSet = [];
    let total = 0;

    Object.entries(data).forEach(([material, anomalies]) => {
      const anomaly = anomalies.find((a) => a.flux === targetFlux);
      if (anomaly) {
        labels.push(t(material));
        values.push(anomaly.countAnomaly);
        colorSet.push(formattedColorMaterial(material));
        total += anomaly.countAnomaly;
      }
    });

    return { labels, values, colorSet, total };
  };

  const { labels, values, colorSet, total } = extractFluxData(
    dataSortingError,
    selectedFluxSortingErrorPie,
  );

  //loop dataSortingError to get dataSetsSortingErrorByTypeChart and materialSortingErrorByTypeChart
  Object.keys(dataSortingError).forEach((material) => {
    dataSetsDistributionSortingError.push({
      label: t(material),
      data: dataSortingError[material].map((flux) => ({
        x: flux.flux,
        y: ((flux.countAnomaly / totalSortingErrorCurrent) * 100).toFixed(1),
        z: t(material),
        count: flux.countAnomaly,
      })),
      backgroundColor: formattedColorMaterial(material),
      type: "bar",
    });

    return null;
  });

  return (
    <div>
      {!isLoading ? (
        tooManyReviews.numberOfReviews > 0 ? (
          <section className="flex flex-col bg-white rounded-lg my-2 py-48 text-center text-red-500">
            <span className="fas fa-exclamation-circle text-xl"></span>
            <span>
              {t("Too many reviews", {
                numberOfReviews: tooManyReviews.numberOfReviews,
                maxNumberOfReviews: tooManyReviews.maxNumberOfReviews,
              })}
            </span>
          </section>
        ) : Object.keys(data).length > 0 &&
          Object.keys(dataSortingError).length > 0 ? (
          <>
            <section className="flex flex-col min-lg:flex-row">
              <article className="min-lg:w-1/2 bg-white p-3 rounded-lg min-lg:mr-2 my-2">
                <h5 className="font-semibold">
                  {t("Sorting error by waste category")}
                </h5>
                <div
                  className="modal-item-chart grid justify-items-center"
                  style={{ marginTop: "40px" }}
                >
                  <MultipleLineAndBarChart
                    stackedChart={true}
                    type="bar"
                    borderWidthChart={1}
                    labelsChart={labelsByTimeChart}
                    aspectRatioChart={1}
                    datasetsChart={dataSetsSortingErrorByFluxChart}
                    indexAxisChart="x"
                    maintainAspectRatioChart={false}
                    displayGrid={true}
                    displayTicks={true}
                    xAxisType={"time"}
                    reverseChart={false}
                    displayLegend={true}
                    displayTooltip={true}
                    messageLabelTooltip={t("sorting errors")}
                    unitValueChart=""
                    maxYAxis={null}
                  />
                </div>
              </article>

              <article className="min-lg:w-1/2 bg-white p-3 rounded-lg min-lg:ml-2 my-2">
                <div className="flex flex-row justify-between">
                  <h5 className="font-semibold">
                    {t(
                      "Sorting error characterization and objects tracked by waste category",
                    )}
                  </h5>
                </div>

                <div className="flex flex-wrap items-center">
                  <div className="flex items-center space-x-2">
                    <label
                      className="whitespace-nowrap"
                      style={{ marginTop: "10px" }}
                    >
                      Flux :
                    </label>
                    <select
                      className="border p-2 rounded"
                      value={selectedFluxSortingErrorPie}
                      onChange={(e) =>
                        setSelectedFluxSortingErrorPie(e.target.value)
                      }
                    >
                      {fluxesList.map((flux) => (
                        <option key={flux.id} value={flux.label}>
                          {flux.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="modal-item-chart grid justify-items-center">
                  <MaterialCountingPieChart
                    dataChart={values}
                    labelChart={labels}
                    materialTotal={total}
                    colorChart={colorSet}
                    statistic={true}
                    showMaterialTotal={true}
                  />
                </div>
              </article>
            </section>

            <section className="flex flex-col min-lg:flex-row">
              <article className="min-lg:w-1/2 bg-white p-3 rounded-lg min-lg:mr-2 my-2">
                <div className="flex flex-row justify-between">
                  <h5 className="font-semibold">
                    {t(
                      "Trend of tracked objects in proportion to the overall flux",
                    )}
                  </h5>
                </div>

                <div className="flex flex-wrap items-center gap-4 mb-4">
                  {/* Sélection du flux */}
                  <div className="flex items-center space-x-2">
                    <label
                      className="whitespace-nowrap"
                      style={{ marginTop: "10px" }}
                    >
                      Flux :
                    </label>
                    <select
                      className="border p-2 rounded"
                      value={selectedFlux}
                      onChange={(e) => setSelectedFlux(e.target.value)}
                    >
                      {fluxesList.map((flux) => (
                        <option key={flux.id} value={flux.id}>
                          {flux.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Sélection du matériau */}
                  <div className="flex items-center space-x-2">
                    <label
                      className="whitespace-nowrap"
                      style={{ marginTop: "10px" }}
                    >
                      {capitalizeFirstLetter(t("tracked object"))} :
                    </label>
                    <select
                      className="border p-2 rounded"
                      value={selectedMaterial}
                      onChange={(e) => setSelectedMaterial(e.target.value)}
                    >
                      {materialsList.map((material) => (
                        <option key={material} value={material}>
                          {t(material)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <WasteHistogram
                  data={monthlyCaracterisation}
                  selectedMaterial={selectedMaterial}
                  selectedFlux={selectedFlux}
                />
              </article>

              <article className="min-lg:w-1/2 bg-white px-1 py-4 rounded-lg min-lg:ml-2 my-2">
                <h5 className="font-semibold ml-2.5">
                  {t("Average sorting errors over 7 days")}
                </h5>
                <div className="modal-item-chart mt-12">
                  <HeatMapChart anomalies={totalAnomalies} viewMode={"day"} />
                </div>
              </article>
            </section>

            <section className="flex flex-col min-lg:flex-row">
              <article className="min-lg:w-1/2 bg-white px-1 py-3 rounded-lg min-lg:mr-2 my-2">
                <h5 className="font-semibold ml-2.5">
                  {t("Sorting errors per week")}
                </h5>
                <div className="wfull flex justify-center items-center gap-4 h-12">
                  <button
                    className="px-3 py-1 bg-gray-200 rounded"
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 0))
                    }
                    disabled={currentPage === 0}
                  >
                    <i
                      className={`fas fa-chevron-left ${currentPage === 0 ? "text-gray-300" : ""}`}
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 0))
                      }
                      disabled={currentPage === 0}
                    ></i>
                  </button>
                  <span>{weeksPageLabel}</span>
                  <button
                    className="px-3 py-1 bg-gray-200 rounded"
                    onClick={() =>
                      setCurrentPage((prev) =>
                        Math.min(prev + 1, totalPages - 1),
                      )
                    }
                    disabled={currentPage >= totalPages - 1}
                  >
                    <i
                      className={`fas fa-chevron-right ${currentPage >= totalPages - 1 ? "text-gray-300" : ""}`}
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(prev + 1, totalPages - 1),
                        )
                      }
                      disabled={currentPage >= totalPages - 1}
                    ></i>
                  </button>
                </div>
                <div className="modal-item-chart">
                  <HeatMapChart
                    anomalies={totalAnomalies}
                    viewMode={"week"}
                    currentPage={currentPage}
                    weeksPerPage={weeksPerPage}
                    setTotalPages={setTotalPages}
                    setWeeksPageLabel={setWeeksPageLabel}
                  />
                </div>
              </article>

              <article className="min-lg:w-1/2 bg-white px-1 py-4 rounded-lg min-lg:ml-2 my-2">
                <h5 className="font-semibold ml-2.5">
                  {t("Sorting errors per month")}
                </h5>
                <div className="modal-item-chart mt-12">
                  <HeatMapChart anomalies={totalAnomalies} viewMode={"month"} />
                </div>
              </article>
            </section>
          </>
        ) : (
          <section className="flex flex-col bg-white rounded-lg my-2 py-48 text-center text-cyan">
            <span className="fas fa-exclamation-triangle text-xl"></span>
            <span>{t("No anomalies for the current period")}</span>
          </section>
        )
      ) : (
        <div className="my-2">
          <Loading height={"55vh"} />
        </div>
      )}
    </div>
  );
};

export default StatisticsChartComponent;
