import React, { useEffect, useState } from "react";
import {
  elapsedTimeContainer,
  formattedDate,
  formattedDuration,
  formattedTime,
} from "../../components/formatted/FormattedDateAndTime";
import { formattedStatus } from "../../components/formatted/FormattedText";
import { setStatusColor } from "../../shared/utils/utils";
import { FaStackOverflow } from "react-icons/fa";
import { StarRatingIcon, MassIcon, LevelBarIcon } from "./ContainerIcons";
import myPackmatIcon from "../../static/assets/CompactingIcon.jpg";
import excavator from "../../static/assets/excavator.png";
import { truncateText } from "../../components/formatted/FormattedText";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TruckSvg from "../../components/utils/TruckSvg";
import excavatorRed from "../../static/assets/excavatorRed.png";
import CustomPopover from "../../components/popover/CustomPopover";
import excavatorGrey from "../../static/assets/excavatorGrey.png";

const ContainerThumbnailView = ({
  data,
  weightKG,
  numberOfStars,
  numberOfAnomaly,
  numberOfPackmat,
  currentDate,
  isLoadingHistoryCycleContainer,
  toggleSortingErrorOverlay,
  setDataForSortingErrorOverlay,
  historyCurrent,
  toggleCompactOverlay,
  setDataForCompactOverlay,
  toggleMapOverlay,
  setDataForMapOverlay,
  setContainerActive,
  superContainer,
  fillingAlert,
  totalVolumeSortingError,
  totalWasteVolume,
}) => {
  const { t, i18n } = useTranslation();
  const containersData = useSelector((state) => state.containers.data);
  const [dataContainer, setDataContainer] = useState({});
  const [popoverContent, setPopoverContent] = useState(null);
  const [popoverLine, setPopoverLine] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (Object.keys(containersData).length !== 0) {
      if (data._id in containersData) {
        const containerActive = containersData[data._id];
        setDataContainer(containerActive);
      }
    }
  }, [containersData, data]);

  const handlePopoverOpen = (event, content, content2) => {
    setPopoverContent(content);
    setPopoverLine(content2);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ height: 265 }}>
      <div style={{ marginTop: `${superContainer ? "50px" : "10px"}` }}>
        <span className="wasteBox">
          {data.wasteName ? truncateText(data.wasteName, 25) : "Non défini"}
        </span>
      </div>

      <div
        style={{
          marginTop: `${superContainer ? "50px" : "10px"}`,
          marginLeft: "-12px",
        }}
      >
        {!superContainer && (
          <>
            {(data.positioning || data.positioning !== "") && (
              <span
                className="addressBox cursor-pointer"
                onClick={() => {
                  toggleMapOverlay();
                  setDataForMapOverlay([data]);
                  setContainerActive(data);
                }}
              >
                {truncateText(data.positioning, 15)}
              </span>
            )}
            <span
              className="addressBox cursor-pointer"
              onClick={() => {
                toggleMapOverlay();
                setDataForMapOverlay([data]);
                setContainerActive(data);
              }}
            >
              {truncateText(data.addressName, 25)}
            </span>
          </>
        )}
      </div>

      {!superContainer && (
        <>
          <div className="flex text-sm" style={{ marginTop: "18px" }}>
            <span style={{ fontSize: 15, marginTop: "-1px" }}>
              {t("Taken") +
                " " +
                elapsedTimeContainer(
                  data._time,
                  i18n.language,
                  true,
                ).toLowerCase() +
                " "}
              (
              {formattedDate(
                data._time,
                i18n.language,
                false,
                true,
              ).toLowerCase()}
              {formattedDate(data._time, i18n.language, false, true).length !==
                0 && " "}
              à {formattedTime(data._time)})
            </span>
          </div>
          <div className="flex text-sm" style={{ marginTop: "18px" }}>
            <span
              className={`w-4 h-4 rounded-full ${setStatusColor(data.status)} mt-0.5`}
            />

            <span style={{ fontSize: 15, marginTop: "-1px", marginLeft: 10 }}>
              {formattedStatus(data.status)}
            </span>
          </div>
        </>
      )}

      <div
        className="highlight-container cursor-pointer"
        style={{ marginTop: "20px" }}
        onClick={() => {
          data.fillingAlert = fillingAlert;
          setContainerActive(data);
          setDataForCompactOverlay({
            container: data,
            historyCurrent: dataContainer?.historyCurrent,
          });
          toggleCompactOverlay();
        }}
      >
        {data.activatedFillingLevelOption && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <LevelBarIcon fillingLevel={data.filling_level} />
            <span className="text">{data.filling_level}%</span>
          </div>
        )}

        {data.packmatActive &&
          (!isLoadingHistoryCycleContainer ? (
            <div>
              <span style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={myPackmatIcon}
                  alt="Packmat Icon"
                  style={{
                    height: 23,
                    verticalAlign: "middle",
                    marginRight: 5,
                  }}
                />
                <span className="text" style={{ marginLeft: 5 }}>
                  {numberOfPackmat}
                </span>
              </span>
            </div>
          ) : (
            <div style={{ marginLeft: "10px" }}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={myPackmatIcon}
                  alt="Packmat"
                  style={{
                    height: 23,
                    verticalAlign: "middle",
                    marginRight: 5,
                  }}
                />
                <span
                  className="text"
                  style={{ marginLeft: 5, color: "var(--gainsboro)" }}
                >
                  .
                </span>
              </span>
            </div>
          ))}
        {data.packmatActive &&
          (fillingAlert?.rotationAlert ||
            fillingAlert?.packmatAlert ||
            fillingAlert?.packmatPossibility ||
            fillingAlert?.packmatAlert !== undefined) && (
            <div style={{ marginLeft: "20px" }}>
              <span style={{ display: "flex", alignItems: "center" }}>
                {fillingAlert?.rotationAlert && (
                  <div
                    style={{ zIndex: 10 }}
                    onMouseEnter={(event) =>
                      handlePopoverOpen(event, t("Pickup to be scheduled"))
                    }
                    onMouseLeave={handlePopoverClose}
                  >
                    <TruckSvg
                      color={"#990000"}
                      width={25}
                      height={25}
                      className={"mx-1 my-auto"}
                    />
                  </div>
                )}
                {fillingAlert?.packmatAlert && (
                  <img
                    src={excavatorRed}
                    alt="Packmat Icon"
                    style={{
                      height: 25,
                      verticalAlign: "middle",
                      marginRight: 5,
                      zIndex: 10,
                    }}
                    onMouseEnter={(event) =>
                      handlePopoverOpen(
                        event,
                        t("Packmat expected"),
                        `${t("Last compaction/rotation at level")}: ${fillingAlert?.lastPackmatLevel}%`,
                      )
                    }
                    onMouseLeave={handlePopoverClose}
                  />
                )}
                {!fillingAlert?.packmatAlert &&
                  fillingAlert?.packmatPossibility && (
                    <img
                      src={excavator}
                      alt="Packmat Icon"
                      style={{
                        height: 25,
                        zIndex: 10,
                        verticalAlign: "middle",
                        marginRight: 5,
                      }}
                      onMouseEnter={(event) =>
                        handlePopoverOpen(
                          event,
                          t("Possibility of packmat"),
                          `${t("Last compaction/rotation at level")}: ${fillingAlert?.lastPackmatLevel}%`,
                        )
                      }
                      onMouseLeave={handlePopoverClose}
                    />
                  )}
                {!fillingAlert?.packmatPossibility &&
                  !fillingAlert?.packmatAlert &&
                  !fillingAlert?.rotationAlert && (
                    <img
                      src={excavatorGrey}
                      alt="Packmat Icon"
                      style={{
                        height: 25,
                        zIndex: 10,
                        verticalAlign: "middle",
                        marginRight: 5,
                      }}
                      onMouseEnter={(event) =>
                        handlePopoverOpen(
                          event,
                          t("No packmat expected"),
                          `${t("Last compaction at level")}: ${fillingAlert?.lastPackmatLevel}%`,
                        )
                      }
                      onMouseLeave={handlePopoverClose}
                    />
                  )}
              </span>
            </div>
          )}

        <MassIcon
          currentValue={weightKG}
          targetValue={data.fullWeight}
          weightUnit={data.weightUnit}
        />

        {dataContainer?.reliablePrediction === true &&
          (currentDate?.getTime() -
            new Date(dataContainer?.fromDate)?.getTime()) /
            (1000 * 3600 * 24) >
            60 && (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "25px",
              }}
            >
              <FaStackOverflow
                style={{ fontSize: 25, verticalAlign: "middle", color: "grey" }}
              />
              <span className="text" style={{ marginLeft: 5 }}>
                {formattedDuration(dataContainer?.durationFullFillingMs)}
              </span>
            </span>
          )}
      </div>
      <div style={{ marginTop: "20px", display: "inline-block" }}>
        {!isLoadingHistoryCycleContainer && data.activatedSortingErrorOption ? (
          <StarRatingIcon
            numberOfStars={numberOfStars}
            text={
              numberOfAnomaly +
              " " +
              (numberOfAnomaly > 1 ? t("sorting_errors") : t("sorting_error"))
            }
            alignTextAboveStars={false}
            color={"grey"}
            textSize={15}
            iconSize={22}
            percentage={(totalVolumeSortingError * 100) / totalWasteVolume}
          />
        ) : (
          <StarRatingIcon
            numberOfStars={data.activatedSortingErrorOption ? numberOfStars : 0}
            text={"."}
            alignTextAboveStars={false}
            color={"var(--gainsboro)"}
            textSize={15}
            iconSize={22}
          />
        )}
      </div>
      <CustomPopover
        open={open}
        anchorEl={anchorEl}
        content={popoverContent}
        content2={popoverLine}
        onClose={handlePopoverClose}
      />
    </div>
  );
};

export default ContainerThumbnailView;
