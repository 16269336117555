import { CustomInput, FormGroup, Input, Label } from "reactstrap";
import { handleFormChange } from "../../../components/modals/EditableTable";
import ContainerVisual from "../../../components/formatted/LoadImages";
import { formattedContainerId } from "../../../components/formatted/FormattedText";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import PhoneInput from "react-phone-number-input";
import { FaLock } from "react-icons/fa";

export const ContainerPreview = ({ contracts, formData, setFormData }) => {
  const containerStatus = [
    { label: "Dépôt", value: "drop_driver", color: "akanthas-green" },
    {
      label: "Enlèvement/Echange",
      value: "remove_driver_exchange",
      color: "grey",
    },
    { label: "Collecte confirmé", value: "collect", color: "akanthas-yellow" },
  ];

  const containersTypes = [
    { label: "Benne Ampliroll", value: "ampliroll_bin" },
    { label: "Benne à chaîne", value: "chain_bin" },
    { label: "Compacteur", value: "compactor" },
    { label: "Geobox", value: "geobox" },
    { label: "Alvéole", value: "lego" },
    { label: "Fut plastique", value: "plastic_drum" },
    { label: "Fut métallique", value: "metallic_drum" },
    { label: "Zone de stockage", value: "storage_area" },
    {
      label: "Zone de caractérisation OMR",
      value: "housewaste_characterisation_zone",
    },
  ];

  const { t } = useTranslation();
  const [helpAlertForAnomaly, setHelpAlertForAnomaly] = useState(false);
  const [helpAlertForFilling, setHelpAlertForFilling] = useState(false);
  const viewMode = Cookies.get("viewMode") || "client";
  const isAkanthasAccount = (Cookies.get("userEmail") || "").includes(
    "@akanthas.com",
  );

  const [adminUserConnected] = useState(
    isAkanthasAccount && viewMode === "admin",
  );
  const [alertLightActive, setAlertLightActive] = useState(
    formData.alertLight?.alertLightActive || false,
  );

  const handleAlertLightToggle = () => {
    setAlertLightActive(!alertLightActive);
    setFormData({
      ...formData,
      alertLight: {
        ...formData.alertLight,
        alertLightActive: !alertLightActive,
      },
    });
  };

  const handlePhoneNumberChange = (number) => {
    setFormData({
      ...formData,
      alertLight: {
        ...formData.alertLight,
        alertLightPhoneNumber: number,
      },
    });
  };

  return (
    <section className="flex w-3/4">
      <div className="flex w-2/3 justify-center min-h-full">
        <ContainerVisual type={formData.type} height="50vh" width="550px" />
      </div>
      <div className="w-1/3 min-h-full">
        {/* Identifiant */}
        <FormGroup className="flex flex-col w-full 3">
          <Label>{t("ID")}</Label>
          <Input
            className="w-full mx-1 bg-gainsboro-transparent border border-gray-300 text-gray-600 rounded-lg block p-2 "
            type="text"
            name="id"
            value={`${formattedContainerId(formData.name)}${formData.name}`}
            disabled
          />
        </FormGroup>

        {/* Type */}
        <FormGroup className="flex flex-col w-full 3">
          <Label>{t("Container type")}</Label>
          <Input
            className="w-full h-10 mx-1 bg-white border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
            type="select"
            value={formData.type}
            onChange={(event) => handleFormChange(event, formData, setFormData)}
            name="type"
          >
            <option value="unknown">{t("Not provided")}</option>
            {containersTypes.map((type, index) => (
              <option value={type.value} key={`volume-unit-${type._id}`}>
                {type.label}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* Status */}
        <FormGroup className="flex flex-col w-full mb-4">
          <Label>Status</Label>
          <Input
            className="w-full h-10 mx-1 bg-white border border-gray-300 text-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
            type="select"
            value={formData.status}
            name="status"
            onChange={(event) => handleFormChange(event, formData, setFormData)}
          >
            <option value="unknown">{t("Not provided")}</option>
            {containerStatus.map((status, index) => (
              <option value={status.value} key={`volume-unit-${status._id}`}>
                {status.label}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* Sorting errors alerts */}
        <FormGroup className="flex flex-col w-full mb-4">
          <div
            className="flex relative"
            onMouseEnter={() => setHelpAlertForAnomaly(true)}
            onMouseLeave={() => setHelpAlertForAnomaly(false)}
          >
            <Label>{t("Sorting error alerts")}</Label>
            <i className="fas fa-info-circle text-grey text-sm mt-0 mx-2"></i>
            {helpAlertForAnomaly && (
              <div className="absolute bottom-10 bg-yellow text-white p-2 rounded-lg shadow-lg z-10">
                {t("Enable email and SMS notifications")} <br />{" "}
                {t("in case of sorting errors")}.
              </div>
            )}
          </div>
          <div className="flex justify-between w-1/3">
            <span className="text-sm">{t("no").toUpperCase()}</span>
            <CustomInput
              className="mx-2"
              type="switch"
              id="alertForAnomaly"
              defaultChecked={formData.alertForAnomaly}
              onClick={(event) =>
                handleFormChange(event, formData, setFormData)
              }
              bsSize="lg"
              name="alertForAnomaly"
            />
            <span className="text-sm">{t("yes").toUpperCase()}</span>
          </div>
        </FormGroup>

        {/* Filling level alerts */}
        <FormGroup className="flex flex-col mb-3">
          <div
            className="flex relative"
            onMouseEnter={() => setHelpAlertForFilling(true)}
            onMouseLeave={() => setHelpAlertForFilling(false)}
          >
            <Label>{t("Fill level threshold alerts")}</Label>
            <i className="fas fa-info-circle text-grey text-sm mt-0 mx-2"></i>
            {helpAlertForFilling && (
              <div className="absolute bottom-10 bg-yellow text-white p-2 rounded-lg shadow-lg z-10">
                {t("Enable email and SMS notifications")} <br />{" "}
                {t("from the defined fill level threshold")} (
                {formData.limitFillingLevel}%).
              </div>
            )}
          </div>
          <div className="flex justify-between w-1/3">
            <span className="text-sm">{t("no").toUpperCase()}</span>
            <CustomInput
              className="mx-2"
              type="switch"
              id="alertForFilling"
              defaultChecked={formData.alertForFilling}
              onClick={(event) =>
                handleFormChange(event, formData, setFormData)
              }
              bsSize="lg"
              name="alertForFilling"
            />
            <span className="text-sm">{t("yes").toUpperCase()}</span>
          </div>
          {formData.contractJoin !== undefined &&
            formData.contract_id !== "unknown" &&
            formData.contractJoin.collectorEmail !== "" && (
              <div className="italic text-red-500">
                {t("This container is linked to a contract with")}{" "}
                {formData.contractJoin.collector}.
                <br /> {t("A rotation request will be sent to")}{" "}
                {formData.contractJoin.collectorEmail} {t("in addition")}.
              </div>
            )}
          {formData.contractJoin === undefined &&
            formData.contract_id !== null &&
            contracts.find(
              (contract) => contract._id === formData.contract_id,
            ) !== undefined &&
            contracts.find((contract) => contract._id === formData.contract_id)
              .collectorEmail !== "" && (
              <div className="italic text-red-500">
                {t("This container is linked to a contract with")}{" "}
                {
                  contracts.find(
                    (contract) => contract._id === formData.contract_id,
                  ).collector
                }
                .
                <br /> {t("A rotation request will be sent to")}{" "}
                {
                  contracts.find(
                    (contract) => contract._id === formData.contract_id,
                  ).collectorEmail
                }{" "}
                {t("in addition")}.
              </div>
            )}
        </FormGroup>

        {adminUserConnected && (
          <FormGroup className="flex flex-col w-full mb-3">
            <div className="flex items-center">
              <Label>{t("Alert Light")}</Label>
              <FaLock className="text-gray-500 ml-2" />
            </div>

            <div className="flex justify-between w-1/3 items-center mt-2">
              <span className="text-sm">{t("no").toUpperCase()}</span>
              <CustomInput
                className="mx-2"
                type="switch"
                id="alertLightActive"
                checked={alertLightActive}
                onClick={handleAlertLightToggle}
                bsSize="lg"
                name="alertLightActive"
              />
              <span className="text-sm">{t("yes").toUpperCase()}</span>
            </div>

            {alertLightActive && (
              <div className="mt-3">
                <Label>{t("Alert Light Phone Number")}</Label>
                <PhoneInput
                  placeholder={t("Enter phone number")}
                  value={formData.alertLight?.alertLightPhoneNumber || ""}
                  onChange={handlePhoneNumberChange}
                  defaultCountry="FR"
                />
              </div>
            )}
          </FormGroup>
        )}
      </div>
    </section>
  );
};
