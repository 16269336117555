import React, { useEffect, useState } from "react";
import { ModalTitle } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import ContainerViewActivity from "./containerView/ContainerViewActivity";
import ContainerViewFilling from "./containerView/ContainerViewFilling";
import ContainerViewQuality from "./containerView/ContainerViewQuality";
import ContainerViewDetails from "./containerView/ContainerViewDetails";
import ContainerToggleLockButton from "./ContainerToggleLockButton";
import { FaLock } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getContainerFillingAlert,
  getContainersList,
  getHistoryCycleContainer,
  getHistoryFillingSpeed,
  updateContainer,
} from "../../services/container";
import Loading from "../../components/utils/Loading";

const ContainerDetailsView = () => {
  const viewMode = Cookies.get("viewMode") || "client";

  const token = Cookies.get("userToken");
  const { t } = useTranslation();
  const { id } = useParams();
  const [container, setContainer] = useState(null);
  const [containers, setContainers] = useState(null);
  const [countContentCategory, setCountContentCategory] = useState(null);
  const max_session_size = process.env.REACT_APP_MAX_SESSION_SIZE;
  const [tableData, setTableData] = useState([]);
  const [showActivity, setShowActivity] = useState(false);
  const [showFilling, setShowFilling] = useState(false);
  const [showQuality, setShowQuality] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isAkanthasAccount, setIsAkanthasAccount] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const updateData = (newData) => {
    const historyCurrentSize = new Blob([
      JSON.stringify(newData.historyCurrent),
    ]).size;
    if (historyCurrentSize < max_session_size) {
      const containerDetails = {
        _id: container._id,
        _time: new Date().toISOString(),
        container: newData,
        containers: containers,
      };
      sessionStorage.setItem(
        "containerDetails",
        JSON.stringify(containerDetails),
      );
    }
    setContainer(newData);
  };

  useEffect(() => {
    const email = Cookies.get("userEmail") || "";
    setIsAkanthasAccount(email.includes("@akanthas.com"));
  }, [isAkanthasAccount]);

  const fetchHistoryCycle = async () => {
    try {
      const [
        responseHistoryCurrent,
        responseSpeedFilling,
        responseFillingAlert,
      ] = await axios.all([
        getHistoryCycleContainer(
          {
            currentCycle: true,
            getLevelLastRotation: true,
          },
          id,
        ),
        getHistoryFillingSpeed(id),
        getContainerFillingAlert(id),
      ]);

      if (
        responseHistoryCurrent.status === 200 ||
        responseSpeedFilling.status === 200 ||
        responseFillingAlert.status === 200
      ) {
        const result = {
          historyCurrent: responseHistoryCurrent.data.historyCycle,
          numberOfStars: responseHistoryCurrent.data.numberOfStars,
          numberOfAnomaly: responseHistoryCurrent.data.numberOfAnomaly,
          anomalyDelivery: responseHistoryCurrent.data.anomalyDelivery,
          numberOfPackmat: responseHistoryCurrent.data.numberOfPackmat,
          totalVolumeSortingError:
            responseHistoryCurrent.data.totalVolumeSortingError,
          totalWasteVolume: responseHistoryCurrent.data.totalWasteVolume,
          meanFillingLevelByOpenedDay:
            responseSpeedFilling.data.fillingPerOpenedWeekday[0],
          meanFillingLevelByOpenedHour:
            responseSpeedFilling.data.fillingPerOpenedHour[0],
          meanFillingLevelByDay: responseSpeedFilling.data.fillingPerWeekday[0],
          levelLastRotation: responseHistoryCurrent.data.levelLastRotation[0],
          dateLastRotation:
            responseHistoryCurrent.data.historyCycle[
              responseHistoryCurrent.data.historyCycle.length - 1
            ],
          lastRotation: responseHistoryCurrent.data.lastRotation[0],
          lastPackmatEfficiency:
            responseHistoryCurrent.data.lastPackmatEfficiency[0],
        };
        return result;
      } else {
        throw new Error("One or more API calls did not return status 200.");
      }
    } catch (error) {
      console.error("Error fetching history cycle data:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        let containerData = {};
        let allContainers = [];
        let contentCategoryCount = 0;

        const storedData = sessionStorage.getItem("containerDetails");
        if (storedData) {
          const { container, containers, countContentCategory } =
            JSON.parse(storedData);
          containerData = container;
          allContainers = containers;
          setCountContentCategory(countContentCategory);
        } else {
          const response = await getContainersList({
            page: null,
            limit: null,
            fieldSort: "filling_level",
            orderSort: "-1",
            typeFillingFilter: "all",
          });

          if (response.status === 200) {
            allContainers = response.data.data;

            const containerFind = allContainers.find((item) => item._id === id);

            const historyCycleData = await fetchHistoryCycle();

            containerData = {
              ...containerFind,
              ...historyCycleData,
            };

            const contentCategoryContainers = allContainers.filter(
              (item) =>
                item.contentCategoryJoin.label ===
                containerData.contentCategoryJoin.label,
            );

            contentCategoryCount = contentCategoryContainers.length;
            setCountContentCategory(contentCategoryCount);

            const containerDetails = {
              _id: containerData._id,
              _time: containerData._time,
              container: containerData,
              containers: allContainers,
              countContentCategory: contentCategoryCount,
              viewType: "monitoring",
            };
            sessionStorage.setItem(
              "containerDetails",
              JSON.stringify(containerDetails),
            );
          }
        }

        // update stats
        setContainer(containerData);
        setTableData(allContainers);
        setContainers(allContainers);
        setShowActivity(!!containerData.activatedActivityOption);
        setShowFilling(
          !containerData.activatedActivityOption &&
            !!containerData.activatedFillingLevelOption,
        );
        setShowQuality(
          !containerData.activatedActivityOption &&
            !containerData.activatedFillingLevelOption &&
            !!containerData.activatedSortingErrorOption,
        );
        setShowDetails(
          !containerData.activatedActivityOption &&
            !containerData.activatedFillingLevelOption &&
            !containerData.activatedSortingErrorOption &&
            !containerData.characterization,
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const handleToggle = async (key) => {
    const newContainer = { ...container, [key]: !container[key] };
    updateData(newContainer);

    const params = { ...newContainer };
    delete params.historyCurrent;

    await updateContainer(params, params._id);
  };
  if (token) {
    return isLoading ? (
      <Loading color="#efefeffb" />
    ) : (
      <div className="base" style={{ minWidth: "850px" }}>
        <div>
          <div className="container-modal-header">
            <ModalTitle>
              <div className="data-bold">
                <span>{container?.addressName} | </span>
                <span style={{ color: "var(--blush)" }}>
                  {(container?.contentCategory &&
                    container?.contentCategory.label) ||
                    container?.wasteName ||
                    "-"}{" "}
                </span>
                <span>
                  |{" "}
                  {((container?.volume > 0 && container.volume) ||
                    container.volumeToCubicMeter) +
                    "" +
                    (container.volumeUnit || "m3") || "-"}
                </span>
                {container?.positioning && container.positioning !== "" && (
                  <>
                    <span> | </span>
                    <span style={{ color: "var(--gray)" }}>
                      {container.positioning}
                    </span>
                  </>
                )}
              </div>
            </ModalTitle>
          </div>

          <nav className="container-modal-nav">
            <div>
              {container.activatedActivityOption ||
              (isAkanthasAccount && viewMode === "admin") ? (
                <span
                  onClick={() => {
                    setShowActivity(true);
                    setShowFilling(false);
                    setShowQuality(false);
                    setShowDetails(false);
                  }}
                  className={`${showActivity ? "container-modal-select" : ""}`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {container.activatedActivityOption === false && (
                    <FaLock style={{ marginRight: "5px" }} />
                  )}
                  {t("Activity")}
                </span>
              ) : null}
              {container.activatedFillingLevelOption ||
              (isAkanthasAccount && viewMode === "admin") ? (
                <span
                  onClick={() => {
                    setShowActivity(false);
                    setShowFilling(true);
                    setShowQuality(false);
                    setShowDetails(false);
                  }}
                  className={`${showFilling ? "container-modal-select" : ""}`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {container.activatedFillingLevelOption === false && (
                    <FaLock style={{ marginRight: "5px" }} />
                  )}
                  {t("Filling level")}
                </span>
              ) : null}
              {container.activatedSortingErrorOption ||
              (isAkanthasAccount && viewMode === "admin") ? (
                <span
                  onClick={() => {
                    setShowActivity(false);
                    setShowFilling(false);
                    setShowQuality(true);
                    setShowDetails(false);
                  }}
                  className={`${showQuality ? "container-modal-select" : ""}`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {container.activatedSortingErrorOption === false && (
                    <FaLock style={{ marginRight: "5px" }} />
                  )}
                  {t("Classification/Quality")}
                </span>
              ) : null}
              <span
                onClick={() => {
                  setShowActivity(false);
                  setShowFilling(false);
                  setShowQuality(false);
                  setShowDetails(true);
                }}
                className={`${showDetails ? "container-modal-select" : ""}`}
              >
                {t("Characteristics")}
              </span>
            </div>

            {/* Activity Lock Button */}
            <ContainerToggleLockButton
              isActive={container.activatedActivityOption}
              onToggle={() => handleToggle("activatedActivityOption")}
              isVisible={
                showActivity && isAkanthasAccount && viewMode === "admin"
              }
            />

            {/* Filling Lock Button */}
            <ContainerToggleLockButton
              isActive={container.activatedFillingLevelOption}
              onToggle={() => handleToggle("activatedFillingLevelOption")}
              isVisible={
                showFilling && isAkanthasAccount && viewMode === "admin"
              }
            />

            {/* Quality Lock Button */}
            <ContainerToggleLockButton
              isActive={container.activatedSortingErrorOption}
              onToggle={() => handleToggle("activatedSortingErrorOption")}
              isVisible={
                showQuality && isAkanthasAccount && viewMode === "admin"
              }
            />
          </nav>
        </div>
        {showActivity && (
          <ContainerViewActivity data={container} updateData={updateData} />
        )}

        {showFilling && (
          <ContainerViewFilling
            data={container}
            tableData={tableData}
            setTableData={setTableData}
            updateData={updateData}
          />
        )}

        {showQuality && (
          <ContainerViewQuality data={container} updateData={updateData} />
        )}

        {showDetails && (
          <ContainerViewDetails
            data={container}
            tableData={tableData}
            setTableData={setTableData}
            countContentCategory={countContentCategory}
          />
        )}
      </div>
    );
  }
};

export default ContainerDetailsView;
