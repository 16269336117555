import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Logo from "../../static/assets/logo_rvg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginFooter from "./LoginFooter.js";
import { useTranslation } from "react-i18next";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ResetPasswordByMail = ({ API }) => {
  const { t } = useTranslation();
  const { token } = useParams();
  const history = useHistory();
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isSecondPasswordVisible, setIsSecondPasswordVisible] = useState(false);
  const [errorForm, setErrorForm] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const themeColor = document.querySelector('meta[name="theme-color"]');
    themeColor.setAttribute("content", "#f5f5f5"); // Couleur spécifique pour la page login
    // Nettoyage : remettre la couleur par défaut quand on quitte la page
    return () => {
      themeColor.setAttribute("content", "#00A7A6"); // Couleur par défaut quand on quitte
    };
  }, []); // Le tableau vide garantit que le code s'exécute uniquement au montage et démontage de la page

  useEffect(() => {
    if (message === "password_changed") {
      const timer = setTimeout(() => {
        history.push("/login");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, history]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password.length < 8) {
        setErrorForm("invalid_password_too_short");
      } else if (password !== confirmPassword) {
        setErrorForm("invalid_password_no_match");
      } else {
        await axios
          .post(`${API}password/updatebymail/${token}`, {
            password: password,
            confirmPassword: confirmPassword,
          })
          .then((response) => {
            if (response.status === 200) {
              setMessage(response.data.message);
            }
          });
      }
    } catch (error) {
      setErrorForm(error.response.data.error);
    }
  };

  return (
    <div className="login-page">
      <div className="header">
        <img src={Logo} alt="Logo Akanthas" className="logo" />
      </div>
      <div className="login-container">
        <div className="login-box">
          <h1 className="sign-in-message">{t("Reset Your Password")}</h1>
          <p className="tagline">
            {t("Secure your account by choosing a new password below.")}
          </p>

          {message === "message_sent" && (
            <p className="success-message">
              {t("Password successfully reset!")}
            </p>
          )}

          <form className="login-form" onSubmit={handleSubmit}>
            <div className="password-field">
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder={t("Enter new password")}
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setMessage("");
                  setErrorForm("");
                }}
                required
              />
              <button
                type="button"
                className="toggle-password-btn"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEye : faEyeSlash}
                />
              </button>
            </div>
            {errorForm === "invalid_password_too_short" && (
              <p className="error-message">
                {t(
                  "Your password is too short. It must be at least 8 characters long to ensure better security.",
                )}
              </p>
            )}
            <div className="password-field">
              <input
                type={isSecondPasswordVisible ? "text" : "password"}
                placeholder={t("Confirm new password")}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                required
              />
              <button
                type="button"
                className="toggle-password-btn"
                onClick={() =>
                  setIsSecondPasswordVisible(!isSecondPasswordVisible)
                }
              >
                <FontAwesomeIcon
                  icon={isSecondPasswordVisible ? faEye : faEyeSlash}
                />
              </button>
            </div>
            {errorForm === "invalid_password_no_match" && (
              <p className="error-message">
                {t(
                  "Passwords do not match. Please ensure both fields have the same password.",
                )}
              </p>
            )}
            {errorForm === "invalid_link" && (
              <p className="error-message">
                {t(
                  "This link is invalid. Please request a new one or contact us at contact@akanthas.com.",
                )}
              </p>
            )}
            {errorForm === "expired_link" && (
              <p className="error-message">
                {t(
                  "This link has expired. Please request a new link or contact us at contact@akanthas.com.",
                )}
              </p>
            )}
            {message === "password_changed" && (
              <p className="success-message">
                {t(
                  "Password changed successfully. You will be redirected to the login page shortly.",
                )}
              </p>
            )}
            {message !== "password_changed" && (
              <button
                type="submit"
                className={`login-button ${errorForm !== "" ? "button-disabled" : ""}`}
                style={{ marginTop: "10px" }}
                disabled={errorForm !== ""}
              >
                {t("Reset Password")}
              </button>
            )}
            <button
              onClick={() => history.push("/login")}
              className="back-link"
              style={{ marginTop: "20px" }}
            >
              {t("Back to sign in")}
            </button>
          </form>
        </div>
      </div>
      <LoginFooter />
    </div>
  );
};

export default ResetPasswordByMail;
