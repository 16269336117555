import StatisticsChartComponent from "./StatisticsChartComponent";
import StatisticsKeyValuesComponent from "./StatisticsKeysValuesComponent";

const StatisticsQuality = ({
  API,
  token,
  periodHistoryStart,
  periodHistoryStop,
  periodHistoryStartPrevious,
  periodHistoryStopPrevious,
  keywordFilter,
  numberOfContainers,
  numberOfSite,
  handleValidate,
  setHandleValidate,
  periodName,
}) => {
  return (
    <div className="text-blue w-full">
      <StatisticsKeyValuesComponent
        periodHistoryStart={periodHistoryStart}
        periodHistoryStop={periodHistoryStop}
        periodHistoryStartPrevious={periodHistoryStartPrevious}
        periodHistoryStopPrevious={periodHistoryStopPrevious}
        keywordFilter={keywordFilter}
        numberOfContainers={numberOfContainers}
        numberOfSite={numberOfSite}
        handleValidate={handleValidate}
        setHandleValidate={setHandleValidate}
        periodName={periodName}
      />

      <StatisticsChartComponent
        periodHistoryStart={periodHistoryStart}
        periodHistoryStop={periodHistoryStop}
        keywordFilter={keywordFilter}
        handleValidate={handleValidate}
        setHandleValidate={setHandleValidate}
      />
    </div>
  );
};

export default StatisticsQuality;
